/**
 * Created by henian.xu on 2018/6/6.
 *
 */

import { Sp } from '@/api/buyer/Sp/Sp';

class ShopFreightTemplate extends Sp {
    constructor() {
        super();
        this.baseUrl += '/shopFreightTemplate';
    }

    /**
     * 获取订单运费信息
     * @param uuid
     * @param deliveryAddressId
     * @returns {AxiosPromise}
     */
    getOrderFreightInfo({ uuid, deliveryRegionId } = {}) {
        return super.get(`/getOrderFreightInfo`, {
            uuid,
            deliveryRegionId,
        });
    }
    getGoodsFreightInfo({ goodsId, deliveryAddressId } = {}) {
        return super.get(`/getGoodsFreightInfo`, {
            goodsId,
            deliveryAddressId,
        });
    }
}

export default new ShopFreightTemplate();
export { ShopFreightTemplate };
