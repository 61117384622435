/**
 * Created by henian.xu on 2018/4/19.
 *
 */

import { DataMap, Comm } from '@/utils';
import { Mb } from '@/api/buyer/Mb/Mb';

class Distributor extends Mb {
    constructor() {
        super();
        this.baseUrl += '/distributor';
    }

    /**
     * 商品列表
     * @param ids
     * @param catId
     * @returns {Promise<any>}
     */
    distributorGoodsList({ catId, pageSize, currentPage } = {}) {
        return super
            .get(`/distributorGoodsList`, {
                shopGoodsCatId: catId,
                currentPage,
                pageSize,
                _isRepeat: true,
            })
            .then(json => {
                const res = json.data.data;
                Comm.makeDataMap(res, DataMap.goods);
                return Promise.resolve(json);
            });
    }

    distributorGoodsDetail({ id }) {
        return super
            .get(`/distributorGoodsDetail`, {
                id,
            })
            .then(json => {
                const res = json.data.data;
                Comm.makeDataMap(res.goods, DataMap.goods);
                return Promise.resolve(json);
            })
            .catch(_err => {
                return Promise.reject(_err);
            });
    }

    distributorDetail() {
        return super.get(`/distributorDetail`);
    }
    createDistributorQrcode() {
        return super.post(`/createDistributorQrcode`);
    }

    /**
     * 获取分销商统计
     * @returns {AxiosPromise<any>}
     */
    distributorDistributionDetail() {
        return super.get(`/distributorDistributionDetail`);
    }

    distributorMonthBillList() {
        return super.get(`/distributorMonthBillList`);
    }

    distributorOrderList({ settlementStatus, statBeginDate, statEndDate } = {}) {
        return super.get(`/distributorOrderList`, {
            settlementStatus,
            statBeginDate,
            statEndDate,
        });
    }

    distributorLeaderboardsList({ leaderboardsType } = {}) {
        return super.get(`/distributorLeaderboardsList`, {
            leaderboardsType,
        });
    }

    distributorInfoDetail() {
        return super.get(`/distributorInfoDetail`);
    }

    distributorSubordinateList({ distributorLevelId, pageSize, currentPage } = {}) {
        return super.get(`/distributorSubordinateList`, {
            distributorLevelId,
            pageSize,
            currentPage,
        });
    }
    distributorSubordinateInfo() {
        return super.get(`/distributorSubordinateInfo`);
    }

    distributorLevelList() {
        return super.get(`/distributorLevelList`);
    }

    saveDistributorInfoDetail(data) {
        return super.post(`/saveDistributorInfoDetail`, data);
    }

    distributorLevelAgentList() {
        return super.get(`/distributorLevelAgentList`);
    }

    distributorLevelDetail(id) {
        return super.get(`/distributorLevelDetail`, { id });
    }

    getBuyerAgentFeeOrderDetail(distributorLevelId) {
        return super.get(`/getBuyerAgentFeeOrderDetail`, { distributorLevelId });
    }

    cancelBuyerAgentFeeOrder(buyerAgentFeeOrderId) {
        return super.post(`/cancelBuyerAgentFeeOrder`, { buyerAgentFeeOrderId });
    }

    createBuyerAgentFeeOrder(data) {
        return super.post(`/createBuyerAgentFeeOrder`, data);
    }

    buyerAgentFeeOrderDetail({ orderIds } = {}) {
        return super.get(`/buyerAgentFeeOrderDetail`, { buyerAgentFeeOrderId: orderIds });
    }

    /**
     * 我的提成
     * @param statMonth
     * @returns {AxiosPromise<any>}
     */
    percentageStatDetail({ statMonth } = {}) {
        return super.get(`/percentageStatDetail`, { statMonth });
    }

    /**
     * 月销售提成
     * @distributionIncomeMode 分销提成类型 1:分享;2:顶级;4:层级;
     * @param pageSize
     * @param currentPage
     * @returns {AxiosPromise<any>}
     */
    orderDistributionMonthList({ distributionIncomeMode, pageSize, currentPage } = {}) {
        return super.get(`/orderDistributionMonthList`, { distributionIncomeMode, pageSize, currentPage });
    }

    /**
     * 月推荐提成
     * @param pageSize
     * @param currentPage
     * @returns {AxiosPromise<any>}
     */
    recommendIncomeMonthList({ pageSize, currentPage } = {}) {
        return super.get(`/recommendIncomeMonthList`, { pageSize, currentPage });
    }

    /**
     * 提现
     * @param withdrawAmount    提现金额
     * @param withdrawType      1:零钱;2:支付宝
     * @param withdrawSource    1:分销;2:店铺;3:合并
     * @param accountName       支付宝姓名
     * @param accountNum        支付宝账号
     * @return {AxiosPromise<any>}
     */
    distributorWithdraw({ withdrawAmount, withdrawType, withdrawSource, accountName, accountNum }) {
        return super.post('/distributorWithdraw', {
            withdrawAmount,
            withdrawType,
            withdrawSource,
            accountName,
            accountNum,

            // _isHandleError: true,
        });
    }

    /**
     * 提现记录
     * @param withdrawSource    1:分销;2:店铺;3:合并
     * @param pageSize
     * @param currentPage
     * @returns {AxiosPromise<any>}
     */
    distributorWithdrawList({ withdrawSource, pageSize, currentPage } = {}) {
        return super.get(`/distributorWithdrawList`, {
            withdrawSource,
            pageSize,
            currentPage,
        });
    }

    /**
     * 优惠码列表
     * @param pageSize
     * @param currentPage
     * @returns {AxiosPromise<any>}
     */
    distributorBestowList({ pageSize, currentPage } = {}) {
        return super.get(`/distributorBestowList`, {
            pageSize,
            currentPage,
        });
    }

    /**
     * 业务员销售
     * @param referenceId           '':个人全部; 0:团队1级;
     * @returns {AxiosPromise<any>}
     */
    salesmanStat({ statBeginDate, statEndDate, referenceId, orderByKey } = {}) {
        return super.get(`/salesmanStat`, {
            statBeginDate,
            statEndDate,
            referenceId,
            orderByKey,
        });
    }

    buyerVisitLogDetail({ type, statBeginDate, statEndDate, statBeginDateMonth, statEndDateMonth }) {
        return super.get('/buyerVisitLogDetail', {
            type,
            statBeginDate,
            statEndDate,
            statBeginDateMonth,
            statEndDateMonth,
        });
    }

    couponActivityStatList() {
        return super.get('/couponActivityStatList');
    }
}

export default new Distributor();
export { Distributor };
