<!-- Created by henian.xu on 2018/4/19. -->

<template>
    <div
        :class="['sku-item',{'bc-g2':data.stock<=0}]"
    >
        <div
            class="img-obj"
            :style="`background-image: url('${data.specImageUrl||$defaultImg}');`"
        />
        <div class="body">
            <div class="name">
                {{ data.specDesc }}
            </div>
            <div class="extra">
                <span class="price">{{ data.price }} /件</span>
                <!--<span>库存: {{ data.showStock }}</span>-->
            </div>
        </div>
        <div class="ctrl">
            <div
                class="sold-out badge badge-g5"
                v-if="data.showStock<=0"
            >
                已抢光
            </div>
            <input-number
                v-else
                v-model="quantity"
                :min="0"
                :max="data.showStock"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'SkuItem',
    data() {
        return {
            quantity: this.value,
        };
    },
    props: {
        value: {
            type: Number,
            default: 0,
        },
        data: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    watch: {
        quantity(val) {
            val = +val || 0;
            this.$emit('input', val);
            if (val !== this.value) {
                this.$emit('change', val);
            }
        },
        value(val) {
            this.quantity = val;
        },
    },
};
</script>

<style lang="scss">
.sku-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: $padding;
    border-top: 1px solid $color-border;
    &:first-child {
        border-top: 0;
    }
    > .img-obj {
        flex: 0 0 1rem;
        width: 1rem;
        height: 1rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    > .body {
        flex: 1 1 auto;
        padding: 0 $padding;
        > .name {
        }
        > .extra {
            font-size: 0.26rem;
            color: $gray6;
            > span {
                display: inline-block;
                + span {
                    margin-left: $margin-small;
                }
            }
        }
    }
    > .ctrl {
        flex: 0 0 auto;
        > .sold-out {
            /*background: $gray2 url("~@/assets/images/comm/icon01.png") center no-repeat;
                background-size: 80%;
                width: 1rem;
                height: 1rem;*/
        }
    }
}
</style>
