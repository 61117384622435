/**
 * Created by henian.xu on 2017/10/24.
 *
 */

import { Mb } from '@/api/buyer/Mb/Mb';

class BuyerIdCard extends Mb {
    constructor() {
        super();
        this.baseUrl += '/buyerIdCard';
    }

    /**
     * 校验身份证
     * @param idCardName
     * @param idCardNo
     * @returns {AxiosPromise<any>}
     */
    checkBuyerIdCard({ idCardName, idCardNo } = {}) {
        return super.get(`/checkBuyerIdCard`, {
            idCardName,
            idCardNo,
            _isHandleError: true,
        });
    }

    /**
     * 保存身份证信息
     * @param orderId?
     * @param idCardName
     * @param idCardNo
     * @param idCardFront
     * @param idCardBack
     * @returns {AxiosPromise<any>}
     */
    saveBuyerIdCard({ orderId, idCardName, idCardNo, idCardFront, idCardBack } = {}) {
        return super.post(`/saveBuyerIdCard`, {
            orderId,
            idCardName,
            idCardNo,
            idCardFront,
            idCardBack,
        });
    }
}

export default new BuyerIdCard();
export { BuyerIdCard };
