<!-- Created by henian.xu on 2018/1/19. -->

<template>
    <div
        :class="styleType==='tabs'?'category-tabs':'category'"
        ref="category"
    >
        <div
            class="inner"
            ref="inner"
        >
            <div
                ref="navItem"
                v-for="(item,index1) in panes"
                :class="['item',{'active':active === index1}]"
                :key="item.id"
                @click="onItemClick($event,item,index1)"
            >
                {{ item.label }}
                <div
                    class="tag"
                    v-show="item.amount"
                >
                    {{ item.amount }}
                </div>
            </div>
        </div>
        <div
            class="extend"
            v-if="extendShow"
            @click="onSwitch"
        >
            <i
                class="f-icon"
                v-if="popupShow"
            >&#xf00e;</i>
            <i
                class="f-icon"
                v-else
            >&#xf010;</i>
        </div>
        <transition
            name="show"
        >
            <div
                class="popup category-tabs-popup"
                @click.self="onSwitch"
                ref="popup"
                v-show="popupShow"
            >
                <transition name="query-box">
                    <div
                        class="inner"
                        v-show="popupShow"
                    >
                        <div class="body">
                            <div
                                v-for="(item,index2) in panes"
                                :class="['item',{'active':active === index2}]"
                                :key="item.id"
                                @click="onItemClick($event,item,index2)"
                            >
                                <div class="inner">
                                    {{ item.label }}
                                    <div
                                        class="tag"
                                        v-show="item.amount"
                                    >
                                        {{ item.amount }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </transition>
    </div>
</template>

<script>
import popupMix from '@/mixin/popup';
import { Comm } from '@/utils';

export default {
    name: 'Category',
    mixins: [popupMix],
    data() {
        return {
            index: -1,
            isUserCtrl: false,
            distanceFirst: false,
            extendShow: false,
        };
    },
    props: {
        panes: {
            type: Array,
            default() {
                return [];
            },
        },
        onCategory: {
            type: Function,
            default: Comm.emptyFn,
        },
        styleType: {
            type: String,
            default: '',
        },
    },
    computed: {
        $$popupWrap() {
            let parent = this.$parent;
            let parentName = parent.$options.componentName;
            while (parentName !== 'XClassify') {
                parent = parent.$parent;
                parentName = parent.$options.componentName;
            }
            return parent.$el;
        },
        active() {
            return this.isUserCtrl ? this.index : this.$parent.currIndex;
        },
        isTabs() {
            return this.styleType === 'tabs';
        },
    },
    watch: {
        '$parent.currDistance'() {
            if (this.distanceFirst) {
                this.distanceFirst = false;
                return;
            }
            this.isUserCtrl = false;
        },
        panes() {
            this.$nextTick(() => {
                const { category, inner } = this.$refs;
                this.extendShow = inner.scrollWidth > category.clientWidth;
                // console.log(category.clientWidth);
                // console.log(inner.scrollWidth);
            });
        },
        active(val) {
            const { navItem, category, inner } = this.$refs;
            if (!navItem) return;
            const navWidth = category.clientWidth;
            const currItem = navItem[val];
            const itemWidth = currItem.clientWidth;
            const itemOffset = currItem.offsetLeft;
            inner.scrollLeft = itemOffset - (navWidth - itemWidth) / 2;
            // console.log('category', itemOffset - (navWidth - itemWidth) / 2);
        },
    },
    methods: {
        onItemClick($event, item, index) {
            this.index = index;
            this.distanceFirst = this.isUserCtrl = true;
            this.onCategory($event, item, index);
            if (this.isTabs && this.popupShow) {
                this.popupShow = false;
            }
        },
    },
};
</script>

<style lang="scss">
.category-tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    background-color: #fff;
    min-height: 0.9rem;
    > .inner {
        &::-webkit-scrollbar {
            /*display: none;*/
            /*opacity: 0;*/
        }
        overflow-y: hidden;
        overflow-x: auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        padding: 0 $padding / 2;

        > .item {
            white-space: nowrap;
            padding: $padding;
            border-bottom: 2px solid transparent;
            &.active {
                border-bottom-color: $color-main;
            }
        }
    }
    > .extend {
        padding: $padding;
    }
}

.category-tabs-popup {
    justify-content: flex-start;
    align-items: stretch;
    top: 0.9rem;
    /*background-color: rgba(255, 255, 255, 0.5);*/
    > .inner {
        width: 100%;
        background-color: #fff;

        > .body {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            > .item {
                padding: $padding/2;
                flex: 0 0 auto;
                width: 25%;
                > .inner {
                    text-align: center;
                    background-color: $gray2;
                    border-radius: 10rem;
                }
                &.active {
                    > .inner {
                        background-color: $color-main;
                        color: #fff;
                    }
                }
            }
        }
    }
}
</style>
