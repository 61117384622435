/**
 * Created by henian.xu on 2018/7/17.
 * 基础服务类
 * 如：记录接口、错误日记 等
 */

import { Http, axiosInstance } from '@/api/base/Http';

class HttpBase extends Http {
    constructor() {
        super();
        this.baseUrl += '';
    }

    /**
     * 买家访问记录
     * @param requestObjectType     1:商品详情;2:商品类目;3:平台首页
     * @param requestObjectId       对应 requestObjectType 的 id
     * @returns {AxiosPromise<any>}
     */
    buyerVisitLog({ requestObjectType, requestObjectId } = {}) {
        return super.post('/rpc/buyer/mb/buyerVisitLog/buyerVisitLog', {
            requestObjectType,
            requestObjectId,
            _isHandleError: true,
            _joinBaseUrl: false,
            _isRepeat: true,
        });
    }
}

export default HttpBase;
const HttpInstance = new HttpBase();
export { HttpBase, HttpInstance, axiosInstance };
