<!-- Created by henian.xu on 2018/1/18. -->

<!--<template>
    <div class="classify">
        <div class="category">
            <div class="item">第一分类</div>
            <div class="item active">第二分类</div>
            <div class="item">第三分类</div>
        </div>
        <div class="content">
            <div class="classify-panel">
                <div class="header">
                    <div class="label">第一分类</div>
                </div>
                <div class="body">
                    panel body
                </div>
            </div>
        </div>
    </div>
</template>-->

<script>
export default {
    name: 'Classify',
    componentName: 'XClassify',
    data() {
        return {
            panes: [],
            currIndex_: this.index,

            DOMRect: {},

            debounceTimer: null,
            debounceDuration: 50,

            scrollDirection: true, // true:向下,false:向上
            currDistance: 0,
        };
    },
    props: {
        index: {
            type: Number,
            default: 0,
        },
        direction: {
            type: String,
            // default: 'top',
            default: 'bottom',
        },
        // 分类的 交互风格
        styleType: {
            type: String,
            default: '',
        },
        noScroll: {
            type: Boolean,
            default: false,
        },
        scrollIgnore: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        content_() {
            return this.$refs['content'];
        },
        contentHeader_() {
            return this.$refs['contentHeader'];
        },
        innerStyle() {
            if (this.noScroll) {
                return {
                    'overflow-y': 'hidden',
                };
            }
            return null;
        },
        currIndex: {
            get() {
                // TODO:算法是否可以再优化,(可以结合上下方向做过滤)
                const length = this.panes.length;
                for (let i = 0; i < length; i++) {
                    const item = this.panes[i];
                    if (this.currDistance < item.DOMRect.bottom) {
                        /* eslint-disable vue/no-side-effects-in-computed-properties */
                        this.currIndex_ = i;
                        return this.currIndex_;
                    }
                }
                return this.currIndex_;
            },
            set(val) {
                this.currIndex_ = val;
            },
        },
        currPanel() {
            return this.panes[this.currIndex];
        },
        currPanelHeader_() {
            return this.currPanel ? this.currPanel.$refs['inner'] : null;
        },
        isTabs() {
            return this.styleType === 'tabs';
        },
    },
    methods: {
        addPanes(panel) {
            const index = this.$slots.default
                .filter(item => {
                    return item.elm.nodeType === 1 && /\bclassify-panel\b/.test(item.elm.className);
                })
                .indexOf(panel.$vnode);
            if (index === -1) return;
            panel.index = index;
            this.panes.splice(index, 0, panel);
            this.$nextTick(() => {
                this.updateDOMRect();
            });
        },
        removePanes(item) {
            const panes = this.panes;
            const index = panes.indexOf(item);
            if (index > -1) {
                panes.splice(index, 1);
            }
        },
        resize() {
            // const distance = this.currDistance;
            this.panes.forEach(panel => {
                panel.updateDOMRect();
            });
        },
        onCategory($event, item, index) {
            this.content_.scrollTop = this.panes[index].DOMRect.top;
        },
        addContentHeader(el) {
            !this.isTabs && this.contentHeader_.appendChild(el);
        },

        /**
         * 滚动事件处理(原始)
         * @param ev
         */
        scrollHandlerOriginal() {
            /* if (this.debounceTimer) return;
            if (ev && ev.constructor === Event) {
                this.debounceTimer = setTimeout(() => {
                    clearTimeout(this.debounceTimer);
                    this.debounceTimer = null;
                    this.attemptFind();
                }, this.debounceDuration);
            } else {
            } */
            this.attemptFind();
        },
        attemptFind() {
            const distance = isNaN(this.content_.scrollTop) ? this.content_.pageYOffset : this.content_.scrollTop;
            if (this.currDistance > distance) {
                this.scrollDirection = false;
            } else {
                this.scrollDirection = true;
            }
            this.currDistance = distance;
            const prevPanel = this.panes[this.currIndex + 1];
            let offset = 0;
            if (prevPanel) {
                offset = prevPanel.DOMRect.top - this.currDistance - this.currPanel.headerDOMRect.height;
            }
            if (prevPanel && offset < 0 && this.currPanelHeader_) {
                this.currPanelHeader_.style.top = `${offset}px`;
                // this.currPanel.isFloat = true;
            } else if (this.currPanelHeader_) {
                this.currPanelHeader_.removeAttribute('style');
            }
        },
        updateDOMRect() {
            this.$forceUpdate();
            const node1 = this.content_;
            this.DOMRect = {
                top: node1.offsetTop,
                left: node1.offsetLeft,
                right: node1.offsetLeft + node1.clientWidth,
                width: node1.clientWidth,
                height: node1.clientHeight,
                bottom: node1.offsetTop + node1.clientHeight,
            };
        },
    },
    mounted() {
        if (this.panes.length) {
            this.content_.scrollTop = this.panes[this.index].DOMRect.top;
        }
        // 触发第一次计算
        setTimeout(this.scrollHandlerOriginal, 1);
        this.content_.addEventListener('scroll', this.scrollHandlerOriginal);
    },
    render() {
        const { panes, onCategory } = this;
        const categoryData = {
            props: {
                panes,
                onCategory,
            },
            ref: 'category',
        };
        const { scrollIgnore, innerStyle, styleType, isTabs } = this;
        let inner;
        if (scrollIgnore) {
            inner = (
                <div class="inner" ref="content" scrollIgnore="true" style={innerStyle}>
                    {this.$slots.default}
                </div>
            );
        } else {
            inner = (
                <div class="inner" ref="content" style={innerStyle}>
                    {this.$slots.default}
                </div>
            );
        }
        let header;
        if (!isTabs) {
            header = <div class="header" ref="contentHeader" />;
        }
        return (
            <div class={'classify ' + styleType}>
                <category styleType={styleType} {...categoryData} />
                <div class="content">
                    {header}
                    {inner}
                </div>
            </div>
        );
    },
};
</script>

<style lang="scss">
.classify {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    overflow: hidden;
    height: 100%;

    > .category {
        flex: 0 0 auto;
        width: calc(5em + 2 * #{$padding});
        background-color: $gray2;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        > .inner {
            > .item {
                position: relative;
                padding: $padding * 2 calc(1em + #{$padding}) $padding * 2 $padding;
                &.active {
                    background-color: $gray1;
                    color: $color-main;
                    //background-color: $color-main;
                    //color: #fff;
                }
                > .tag {
                    position: absolute;
                    top: 0;
                    right: 0;
                    text-align: center;
                    color: #fff;
                    font-size: 0.2rem;
                    border-radius: 1em;
                    padding: 0 0.5em;
                    min-width: 2em;
                    height: 2em;
                    line-height: 2em;
                    background-color: $color-red;
                }
            }
        }
    }

    > .content {
        position: relative;
        flex: 1 1 auto;
        max-height: 100%;

        > .header {
            position: absolute;
            z-index: $z-index-2;
            top: 0;
            left: 0;
            right: 0;
            height: 1.03rem;
            line-height: 0;

            > .inner {
                // border-top: 1px solid $color-border;
                border-bottom: 1px solid $color-border;
                line-height: 1.02rem;
                background-color: rgba(246, 249, 252, 0.9);
                // color: $color-main;
                padding: 0 $padding;

                &.float {
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                }
            }
        }
        > .inner {
            position: relative;
            max-height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            > .classify-panel {
                > .header {
                    height: 1.03rem;
                    line-height: 0;

                    > .inner {
                        border-top: 1px solid $color-border;
                        border-bottom: 1px solid $color-border;
                        line-height: 1.01rem;
                        background-color: rgba(246, 249, 252, 0.9);
                        // color: $color-main;
                        padding: 0 $padding;
                    }
                }
                > .body {
                    // padding: $padding;
                }
            }
        }
    }

    &.tabs {
        position: relative;
        flex-direction: column;
        > .content {
            overflow: hidden;
            &,
            > .inner {
                height: 100%;

                > .classify-panel {
                    > .header {
                        height: inherit;
                        > .inner {
                            border: none;
                            line-height: 2;
                            font-weight: bold;
                            background-color: $gray1;
                            padding: 0 $padding;
                        }
                    }
                }
            }
        }
    }
}
</style>
