/**
 * Created by henian.xu on 2018/3/5.
 *
 */

export default {
    data() {
        return {
            appLevel: false,
            delayedCreate: false,
            isAni: true,
            aniName: '',
            queryName: `popup${this.$utils.Comm.getKey()}`,
            isUserCtrl: false,
            doneCtrl: false,
            doneResolve: null,
            isAppend: false,
        };
    },
    props: {
        disabled: Boolean,
        readonly: Boolean,
    },
    computed: {
        $$popupWrap() {
            return !this.appLevel ? this.$utils.Comm.getPopupWrap(this) : this.$utils.Dom.createPopupWrap();
        },
        $$popup() {
            const str = this.delayedCreate ? '' : ''; // 模板模块那里应该用这个显示与否
            return this.$refs['popup' + str] || null;
        },
        $$popupNode() {
            const { $$popup } = this;
            if (!$$popup) return null;
            if (!$$popup.$el && !$$popup.nodeType) return null;
            return $$popup.$el || $$popup;
        },
        popupShow: {
            get() {
                let popupShow = this.$route.query[this.queryName];
                if (popupShow === 'true') popupShow = true;
                else if (popupShow === 'false') popupShow = false;
                return popupShow;
            },
            set(val) {
                if (!val) {
                    this.$router.go(-1);
                    return;
                }
                this.isUserCtrl = true;
                this.aniName = this.isAni ? 'left' : '';
                this.$router.push({
                    ...this.$route,
                    query: {
                        ...this.$route.query,
                        [this.queryName]: val,
                    },
                });
            },
        },
    },
    watch: {
        popupShow(val) {
            if (this.doneResolve) {
                this.$nextTick(() => {
                    this.doneResolve();
                    this.doneResolve = null;
                    this.$emit('done');
                });
            }
            val ? this.$emit('open', val) : this.$emit('close');
        },
        $route() {
            if (!this.isUserCtrl) {
                this.aniName = this.isAni ? 'right' : '';
            }
            this.isUserCtrl = false;
        },
    },
    methods: {
        mount() {
            // if (this.disabled || this.readonly) return;
            const { $$popupNode } = this;
            if (!$$popupNode) return;
            this.$$popupWrap.appendChild($$popupNode);
            this.isAppend = true;
        },
        delayedCreated() {
            return new Promise(resolve => {
                const { isAppend, delayedCreate } = this;
                if (isAppend) return resolve();
                if (delayedCreate) {
                    this.delayedCreate = false;
                    this.$nextTick(() => {
                        this.mount();
                        resolve();
                    });
                }
            });
        },
        gotoType(type) {
            if (this.disabled) return;
            this.delayedCreated().then(() => {
                if (isNaN(type)) {
                    this.popupShow = type;
                } else {
                    this.$router.go(type);
                }
            });
        },
        onSwitch() {
            if (this.disabled || this.readonly) return;
            this.delayedCreated().then(() => {
                this.popupShow = !this.popupShow;
            });
        },
        close() {
            this.popupShow = false;
        },
        open() {
            if (this.disabled || this.readonly) return;
            const { type } = this;
            this.delayedCreated().then(() => {
                this.popupShow = type || true;
            });
        },
        /**
         * 用来记录 $router 已执行完毕
         * @returns {Promise<any>}
         */
        done({ isAni = true } = {}) {
            return new Promise((resolve, reject) => {
                if (!this.popupShow) {
                    resolve();
                    return;
                }
                if (this.disabled || this.readonly) {
                    reject();
                    return;
                }
                this.isAni = isAni;
                this.doneCtrl = true;
                this.popupShow = false;
                // TODO 要在内部过一遍再出去
                this.doneResolve = resolve;
                // this.isAni = true;
            });
        },
    },
    mounted() {
        this.mount();
    },
    beforeDestroy() {
        const { $$popupWrap, $$popupNode, isAppend } = this;
        if (!$$popupNode) return;
        isAppend && $$popupWrap.removeChild($$popupNode);
    },
};
