/**
 * Created by henian.xu on 2019/2/28.
 * 公用组件入口
 */

const requireComponent = require.context('./', true, /index\.(vue|js)$/);

function registerComponent(Vue, component) {
    if (component.noTag) {
        Vue.prototype[`$${component.names}`] = component;
    } else {
        Vue.component(component.name, component);
    }
}

export default Vue => {
    requireComponent.keys().forEach(filePath => {
        // 只取二级目录下的文件
        if (filePath.split('/').length !== 3) return;
        const components = requireComponent(filePath);
        Object.values(components).forEach(component => registerComponent(Vue, component));
    });
};
