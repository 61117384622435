<!-- Created by henian.xu on 2019/5/15. -->

<template>
    <div
        class="proxy-store-goods-cat-picker"
        ref="self"
    >
        <div class="out-show">
            <div class="inner">
                <div
                    :class="['item', {active:item.id===model}]"
                    v-for="item in data"
                    :key="item.id"
                    @click="()=>{onItem(item)}"
                >
                    {{ item.goodsCatName||item.name }}
                </div>
            </div>
            <div
                class="expand"
                @click="onSwitch"
            >
                <i
                    class="f-icon"
                    v-if="popupShow"
                >&#xf00e;</i>
                <i
                    class="f-icon"
                    v-else
                >&#xf010;</i>
            </div>
        </div>

        <transition name="show">
            <div
                ref="popup"
                class="popup proxy-store-goods-cat-picker-popup"
                :style="popupStyle"
                v-show="popupShow"
                @click.self="onSwitch"
            >
                <transition name="query-box">
                    <div
                        class="inner"
                        v-show="popupShow"
                    >
                        <div class="body">
                            <div class="cat-wrap">
                                <div
                                    :class="['item', {active:item.id===model}]"
                                    v-for="item in data"
                                    :key="item.id"
                                    @click="()=>{onItem(item)}"
                                >
                                    <div class="inner">
                                        {{ item.goodsCatName||item.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </transition>
    </div>
</template>

<script>
import popupMixin from '@/mixin/popup';
export default {
    name: 'ProxyStoreGoodsCatPicker',
    mixins: [popupMixin],
    data() {
        return {
            isMounted: false,
            model_: void 0, // 当未绑定 model 时才有用
        };
    },
    props: {
        value: {
            type: [String, Number],
            default: void 0,
        },
        data: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        model: {
            get() {
                const { value, model_ } = this;
                return value === void 0 ? model_ : value;
            },
            set(val) {
                this.value === void 0 ? (this.model_ = val) : this.$emit('input', val);
            },
        },
        popupStyle() {
            const { popupShow, $$self } = this;
            if (!$$self) return {};
            const { bottom } = $$self.getBoundingClientRect();
            return {
                top: `${bottom}px` + (popupShow ? '' : ''),
            };
        },
        $$self() {
            const { isMounted, $refs } = this;
            if (!isMounted) return null;
            return $refs['self'];
        },
        resetModel() {
            const { model, data } = this;
            return !!(!model && data && data.length);
        },
    },
    watch: {
        resetModel: {
            handler(val) {
                const { data } = this;
                if (!val) return;
                this.model = data[0].id;
            },
            immediate: true,
        },
    },
    methods: {
        onItem(item) {
            this.model = item.id;
            if (this.popupShow) this.done();
        },
    },
    mounted() {
        this.isMounted = true;
    },
};
</script>

<style lang="scss">
.popup.proxy-store-goods-cat-picker-popup {
    justify-content: flex-start;
    align-items: stretch;
    > .inner {
        border-top: 1px solid $color-border;
        background-color: #fff;
        > .body {
            > .cat-wrap {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: stretch;
                flex-wrap: wrap;
                margin: 0 (-$padding-small);
                > .item {
                    padding: $padding-small;
                    flex: 0 0 auto;
                    width: (100% / 3);
                    > .inner {
                        padding: $padding-small;
                        text-align: center;
                        background-color: $gray2;
                        border-radius: $padding-small;
                        word-break: break-all;
                        @include text-line(1);
                    }
                    &.active {
                        > .inner {
                            background-color: $color-main;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}
.proxy-store-goods-cat-picker {
    background-color: #fff;
    > .out-show {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        > .inner {
            flex: 1 1 1%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: stretch;
            overflow-y: hidden;
            overflow-x: auto;
            overflow-scrolling: touch;
            > .item {
                white-space: nowrap;
                padding: $padding $padding-big;
                &.active {
                    border-bottom: 2px solid $color-main;
                    color: $color-main;
                }
            }
        }
        > .expand {
            flex: 0 0 auto;
            width: 0.8rem;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
    }
}
</style>
