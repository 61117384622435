/**
 * Created by henian.xu on 2018/4/19.
 *
 */

import { Ex } from '@/api/buyer/Ex/Ex';

class Pay extends Ex {
    constructor() {
        super();
        this.baseUrl += '/pay';
    }

    /**
     * 获取支付相关信息
     * @param orderIds
     * @returns {AxiosPromise<any>}
     */
    toPay({ module, orderIds } = {}) {
        return super.get(`/toPay`, {
            module,
            orderIds,
            _isRepeat: true,
        });
    }

    /**------------------ 线下支付 ------------------**/

    /**
     * 获取线下付款的信息
     * @returns {AxiosPromise<any>}
     */
    getShopOfflineInfo() {
        return super.get(`/getShopOfflineInfo`);
    }

    /**
     * 线下付款银行转账
     * @param orderIds
     * @param payer
     * @param payVoucher
     * @param paymentComments
     * @returns {AxiosPromise<any>}
     */
    payforOffline({ module, orderIds, payer, payVoucher, paymentComments } = {}) {
        return super.post(`/payforOffline`, {
            module,
            orderIds,
            payer,
            payVoucher,
            paymentComments,
        });
    }

    /**------------------ 线上支付 ------------------**/

    /**
     * 零钱支付
     * @param orderIds
     * @returns {AxiosPromise<any>}
     */
    payforBalance({ module, orderIds } = {}) {
        return super.post(`/payforBalance`, { module, orderIds });
    }

    /**
     * stripe 支付
     * @param orderIds
     * @param payType 1:快捷支付；2:普通支付
     * @param token
     * @param cardInfoId
     * @returns {AxiosPromise<any>}
     */
    payforStripe({ module, orderIds, payType, token, cardInfoId } = {}) {
        return super.post(`/payforStripe`, { module, orderIds, token, cardInfoId, payType });
    }

    /**
     * 获得订单付款信息
     * @returns {Promise<any>}
     */
    getOrderPayInfo({ module, paymentType, orderIds } = {}) {
        return super.get(`/getOrderPayInfo`, { module, paymentType, orderIds });
    }

    /**
     * 微信支付
     * @param orderIds               // 订单ID
     * @returns {AxiosPromise}
     */
    payforWeixin({ module, orderIds } = {}) {
        return super.post(`/payforWeixin`, { module, orderIds });
    }

    /**
     * 微信扫码支付
     * @param orderIds
     * @returns {AxiosPromise<any>}
     */
    payforWeixinByScanCode({ module, orderIds } = {}) {
        return super.post(`/payforWeixinByScanCode`, { module, orderIds });
    }

    /**
     * 通联微信支付
     * @param orderIds
     * @returns {AxiosPromise<any>}
     */
    payforAllinPayWeixin({ module, orderIds } = {}) {
        return super.post(`/payforAllinPayWeixin `, { module, orderIds });
    }

    /**
     * 通联微信扫码支付(暂无用)
     * @param orderIds
     * @returns {AxiosPromise<any>}
     */
    payforAllinPayWeixinByScanCode({ module, orderIds } = {}) {
        return super.post(`/payforAllinPayWeixinByScanCode`, { module, orderIds });
    }

    /**
     * Alpha微信扫码支付 (微信支付走 href)
     * @param orderIds
     * @returns {AxiosPromise<any>}
     */
    payforAlphaPayWeixinByScanCode({ module, orderIds } = {}) {
        return super.post(`/payforAlphaPayWeixinByScanCode`, { module, orderIds });
    }

    /**
     * Alpha支付宝扫码支付 (支付宝支付走 href)
     * @param orderIds
     * @returns {AxiosPromise<any>}
     */
    payforAlphaPayAlipayByScanCode({ module, orderIds } = {}) {
        return super.post(`/payforAlphaPayAlipayByScanCode`, { module, orderIds });
    }

    /**
     * 中投科信 umb
     * @param orderIds               // 订单ID
     * @returns {AxiosPromise}
     */
    payforUmbPayWeixin({ module, orderIds } = {}) {
        return super.post(`/payforUmbPayWeixin`, { module, orderIds });
    }

    /**
     * 中投科信 umb扫码支付
     * @param orderIds
     * @returns {AxiosPromise<any>}
     */
    payforUmbPayWeixinByScanCode({ module, orderIds } = {}) {
        return super.post(`/payforUmbPayWeixinByScanCode`, { module, orderIds });
    }

    /**
     * 中投科信 umb支付宝扫码支付 (支付宝支付走 href)
     * @param orderIds
     * @returns {AxiosPromise<any>}
     */
    payforUmbPayAlipayByScanCode({ module, orderIds } = {}) {
        return super.post(`/payforUmbPayAlipayByScanCode`, { module, orderIds });
    }

    /**
     * CitconPay 微信扫码支付 (微信支付走 href)
     * @param orderIds
     * @returns {AxiosPromise<any>}
     */
    payforCitconPayWeixinByScanCode({ module, orderIds } = {}) {
        return super.post(`/payforCitconPayWeixinByScanCode`, { module, orderIds });
    }

    /**
     * CitconPay 支付宝扫码支付 (支付宝支付走 href)
     * @param orderIds
     * @returns {AxiosPromise<any>}
     */
    payforCitconPayAlipayByScanCode({ module, orderIds } = {}) {
        return super.post(`/payforCitconPayAlipayByScanCode`, { module, orderIds });
    }

    /**
     * HantePay 微信扫码支付 (微信支付走 href)
     * @param orderIds
     * @returns {AxiosPromise<any>}
     */
    payforHantePayWeixinByScanCode({ module, orderIds } = {}) {
        return super.post(`/payforHantePayWeixinByScanCode`, { module, orderIds });
    }

    /**
     * supayTech
     * @param orderIds               // 订单ID
     * @returns {AxiosPromise}
     */
    payforSupayTechPayWeixin({ module, orderIds } = {}) {
        return super.post(`/payforSupayTechPayWeixin`, { module, orderIds });
    }

    /**
     * supayTech
     * @param orderIds
     * @returns {AxiosPromise<any>}
     */
    payforSupayTechPayAlipay({ module, orderIds } = {}) {
        return super.post(`/payforSupayTechPayAlipay`, { module, orderIds });
    }

    /**
     * supayTech
     * @param orderIds
     * @returns {AxiosPromise<any>}
     */
    payforSupayTechPayWeixinByScanCode({ module, orderIds } = {}) {
        return super.post(`/payforSupayTechPayWeixinByScanCode`, {
            module,
            orderIds,
        });
    }

    /**
     * 是否已付款 (用于扫码确认是否支付)
     * @param paymentId
     * @returns {AxiosPromise}
     */
    isPay({ module, paymentId } = {}) {
        return super.get(`/isPay`, {
            module,
            paymentId,
            _isHandleError: true,
        });
    }

    /**
     * 确认支付是否成功 (由于支付异步延迟而存在)
     * @param paymentSn
     * @param module 1:订单;2:充值卡;3:分销高;
     * @returns {AxiosPromise<any>}
     */
    checkPayment({ module, paymentSn } = {}) {
        return super.get(`/checkPayment`, {
            module,
            paymentSn,
            _isHandleError: true,
        });
    }
}

export default new Pay();
export { Pay };
