<!-- Created by henian.xu on 2019/2/27. -->

<template>
    <div class="swiper-popup">
        <!--<div
            class="out-show"
            @click="onSwitch">SwiperPopup</div>-->
        <!--popup-->
        <transition :name="aniName">
            <Page
                class="swiper-popup-page"
                ref="popup"
                v-show="popupShow"
            >
                <Container>
                    <swiper
                        ref="swiper"
                        :options="swiperOption"
                    >
                        <swiper-slide
                            v-for="(item,index) in data"
                            :key="index"
                        >
                            <img
                                :src="item"
                            >
                        </swiper-slide>
                        <div
                            class="swiper-pagination"
                            slot="pagination"
                        />
                    </swiper>
                </Container>
            </Page>
        </transition>
    </div>
</template>

<script>
import popupMixin from '@/mixin/popup';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
export default {
    name: 'SwiperPopup',
    mixins: [popupMixin],
    components: {
        swiper,
        swiperSlide,
    },
    data() {
        return {
            swiperOption: {
                autoplay: false,
                /*autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },*/
                // loop: true,
                pagination: {
                    el: '.swiper-pagination',
                },
            },
        };
    },
    props: {
        data: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    computed: {
        swiper() {
            const $swiper = this.$refs['swiper'] || {};
            return $swiper.swiper || {};
        },
    },
};
</script>

<style lang="scss">
.swiper-popup {
}
.swiper-popup-page {
    background-color: rgba(0, 0, 0, 0.9);
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;

        .swiper-container {
            width: 100%;
            height: 100%;
            > .swiper-wrapper {
                > .swiper-slide {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
            .swiper-pagination-bullet {
                width: 0.2rem;
                height: 0.2rem;
                background: #fff;
            }

            .swiper-pagination-bullet-active {
                background: $color-main;
            }
        }
    }
}
</style>
