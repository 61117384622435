/**
 * Created by henian.xu on 2018/4/19.
 *
 */

import { Comm } from '@/utils';
import Md5 from 'md5';
import { Mb } from '@/api/buyer/Mb/Mb';

class Buyer extends Mb {
    constructor() {
        super();
        this.baseUrl += '/buyer';
    }

    /**
     * 登录
     * @param name
     * @param password
     * @returns {AxiosPromise}
     */
    login({ countryCodeId, mobile, password, verificationCode } = {}) {
        Comm.required('countryCodeId', countryCodeId);
        Comm.required('mobile', mobile);
        Comm.required('password', password);
        return super.post(`/buyerLogin`, {
            countryCodeId,
            buyerAccount: mobile,
            password: password ? Md5(password) : password,
            verificationCode,
            _isHandleError: true,
        });
    }

    /**
     * 登出
     */
    logout() {
        return super.post(`/logout`);
    }

    /**
     * 注册
     */
    register({ countryCodeId, mobile, iHealthAccount, email, password, rawPassword, validateCode } = {}) {
        return super.post(`/buyerRegister`, {
            countryCodeId,
            buyerAccount: mobile,
            iHealthAccount,
            email,
            password: password ? Md5(password) : password,
            rawPassword,
            verificationCode: validateCode,
        });
    }

    /**
     * 重置密码
     * @param countryCodeId
     * @param mobile
     * @param password
     * @param validateCode
     * @returns {AxiosPromise}
     */
    resetBuyerPassword({ countryCodeId, mobile, password, validateCode } = {}) {
        return super.post(`/resetBuyerPassword `, {
            countryCodeId,
            buyerAccount: mobile,
            password: password ? Md5(password) : password,
            verificationCode: validateCode,
        });
    }

    /**
     * 修改密码
     * @param password
     * @param oldPassword
     * @returns {AxiosPromise}
     */
    modPwdByBuyer({ password, oldPassword } = {}) {
        Comm.required('password', password);
        Comm.required('oldPassword ', oldPassword);
        Comm.required('name', name);
        return super.post(`/modPwdByBuyer`, {
            password: password ? Md5(password) : password,
            oldPassword: oldPassword ? Md5(oldPassword) : oldPassword,
        });
    }
    /**
     * 修改用户信息
     * @param password
     * @param oldPassword
     * @returns {AxiosPromise}
     */
    editUserInfo({ userAvatar, nickName } = {}) {
        return super.post(`/editUserInfo`, {
            userAvatar,
            nickName,
        });
    }

    /**
     * 验证账号
     * @param mobile
     * @returns {Promise<T>}
     */
    checkAccount({ countryCodeId, buyerAccount }) {
        return super.get(`/checkAccount`, {
            countryCodeId,
            buyerAccount,
            _isHandleError: true,
            _isRepeat: true,
        });
    }

    /**
     * 买家详情
     * @returns {AxiosPromise}
     */
    detail() {
        return super.get(`/detail`);
    }

    /**
     * 买家积分日志
     * @returns {AxiosPromise}
     */
    pointList({ currentPage, pageSize } = {}) {
        return super.get(`/pointLogList`, { currentPage, pageSize });
    }

    growthValueLogList({ currentPage, pageSize } = {}) {
        return super.get(`/growthValueLogList`, { currentPage, pageSize });
    }

    offlinePaymentList({ currentPage, pageSize } = {}) {
        return super.get(`/offlinePaymentList`, { currentPage, pageSize });
    }

    balanceLogList({ currentPage, pageSize } = {}) {
        return super.get(`/balanceLogList`, { currentPage, pageSize });
    }

    countInfo() {
        return super.get(`/buyerCountInfo`);
    }

    error(error) {
        return super.post('/error', { error });
    }
}

export default new Buyer();
export { Buyer };
