<!-- Created by henian.xu on 2018/1/19. -->

<template>
    <div 
        class="classify-panel" 
        ref="panel" 
        :style="isLast&&`padding-bottom: ${bottom}px;`"
    >
        <div 
            class="header" 
            ref="header"
        >
            <div 
                :class="['inner',{'float':current&&!isTabs}]" 
                ref="inner"
            >
                <div class="label">
                    <!--<i class="f-icon">&#xf051;</i>-->{{ label }}
                </div>
            </div>
        </div>
        <div class="body">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ClassifyPanel',
    data() {
        return {
            index: 0,
            DOMRect: {},
            headerDOMRect: {},
        };
    },
    props: {
        label: {
            type: String,
            default: '',
        },
        amount: {
            type: [Number, String],
            default: '',
        },
    },
    computed: {
        prev() {
            return this.index < this.$parent.currIndex;
        },
        current() {
            return this.index === this.$parent.currIndex;
        },
        panel_() {
            return this.$refs['panel'];
        },
        header_() {
            return this.$refs['header'];
        },
        inner_() {
            return this.$refs['inner'];
        },
        panes() {
            return this.$parent.panes || null;
        },
        isLast() {
            return this.index + 1 === this.panes.length;
        },
        bottom() {
            if (!this.panes.length || !this.isLast || !this.$parent.DOMRect.height) return 0;
            return this.$parent.DOMRect.height - this.DOMRect.height;
        },
        isTabs() {
            return this.$parent.styleType === 'tabs';
        },
    },
    watch: {
        current(val) {
            if (val) {
                this.$parent.addContentHeader(this.inner_);
            } else {
                this.header_.appendChild(this.inner_);
            }
        },
    },
    methods: {
        updateDOMRect() {
            this.$forceUpdate();
            const node1 = this.$el;
            this.DOMRect = {
                top: node1.offsetTop,
                left: node1.offsetLeft,
                right: node1.offsetLeft + node1.clientWidth,
                width: node1.clientWidth,
                height: node1.clientHeight,
                bottom: node1.offsetTop + node1.clientHeight,
            };
            const node2 = this.header_;
            this.headerDOMRect = {
                top: node2.offsetTop,
                left: node2.offsetLeft,
                right: node2.offsetLeft + node2.clientWidth,
                width: node2.clientWidth,
                height: node2.clientHeight,
                bottom: node2.offsetTop + node2.clientHeight,
            };
        },
    },
    mounted() {
        // console.log(this.bottom);
        this.$parent.addPanes(this);
        this.updateDOMRect();
    },
    destroyed() {
        if (this.$el && this.$el.parentNode) {
            this.$el.parentNode.removeChild(this.$el);
        }
        this.$parent.removePanes(this);
    },
};
</script>

<style lang="scss">
</style>
