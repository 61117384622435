/**
 * Created by henian.xu on 2019/3/4.
 *
 */
import { Sp } from '@/api/buyer/Sp/Sp';
import { Session } from '@/store/storage';

export class Store extends Sp {
    constructor() {
        super();
        this.baseUrl += '';
    }
    setStoreUserName(params) {
        params = { ...params, storeUserName: Session.get('__storeUserName__') };
        return params;
    }
    get(url, params = {}, cfg = {}) {
        params = this.setStoreUserName(params);
        return super.get(url, params, cfg);
    }
    post(url, params = {}, cfg = {}) {
        params = this.setStoreUserName(params);
        return super.post(url, params, cfg);
    }
    postJson(url, params = {}) {
        params = this.setStoreUserName(params);
        // url += `?storeUserName=${Session.get('__storeUserName__')}`;
        return super.postJson(url, params);
    }
    download(url, params = {}) {
        params = this.setStoreUserName(params);
        return super.download(url, params);
    }
}

export default new Store();
