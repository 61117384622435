<!-- Created by henian.xu on 2018/12/7. -->

<template>
    <div class="group-info">
        <div class="label ma-t">
            参加 {{ groupBuyDetail.nickName }} 的拼团
        </div>
        <div
            class="desc"
            v-if="isGroupBuyAvailable"
        >
            此拼团差 {{ groupBuyDetail.groupBuyNums - groupBuyDetail.joinBuyNums }} 人成功，
            <GroupCountdown :end-time="groupBuyDetail.endTime" />
            后结束
        </div>

        <div class="pa-a">
            <JoinUser :data="groupBuyDetail" />
        </div>

        <div
            class="pa-l pa-r pa-b"
            v-if="isActivityAvailable"
        >
            <BuyPopup
                v-if="!isJoinGroup"
                class="btn-group-buy"
                order-type="20"
                :data="activityGoods"
                :group-buy-id="isGroupBuyAvailable ? groupBuyId : 0"
            >
                {{ isGroupBuyAvailable?'参与拼团': '一键发起拼团' }}
            </BuyPopup>
            <Linker
                v-else-if="isGroupBuyAvailable"
                tag="div"
                :to="`/goods/groupBuy/${data.orderId}`"
                class="btn-group-buy"
            >
                分享拼团
            </Linker>
        </div>
        <div
            v-else
            class="pa-l pa-r pa-b"
        >
            <div class="btn-group-buy disabled">
                活动已结束
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GroupInfo',
    data() {
        return {};
    },
    props: {
        data: {
            type: Object,
            default() {
                return {};
            },
        },
        // 活动是否可用
        activityAvailable: {
            type: Boolean,
            default: false,
        },
        goods: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    computed: {
        groupBuyDetail() {
            return this.data.groupBuyDetail || {};
        },
        groupBuyStatus() {
            return this.groupBuyDetail.groupBuyStatus;
        },
        groupBuyId() {
            return this.groupBuyDetail.id;
        },
        isActivityAvailable() {
            return this.activityAvailable || (this.data && this.data.goods && this.data.goods.activityAvailable);
        },
        isGroupBuyAvailable() {
            return this.activityAvailable || this.data.groupBuyAvailable;
        },
        isJoinGroup() {
            return this.data.isJoinGroup;
        },
        activityGoods() {
            const { goods /*, groupBuyAvailable */ } = this.data;
            this.$utils.Comm.makeDataMap(goods || {}, this.$utils.DataMap.goods);
            const obj = { ...this.goods, ...goods };
            // if (!groupBuyAvailable) return {};
            obj.maxPrice = obj.activityMaxPrice;
            obj.minPrice = obj.activityMinPrice;
            obj.price = obj.activityPrice;
            obj.showStock = obj.activityShowStock;
            obj.skuMap = obj.activitySkuMap;
            obj.sku = obj.activitySku;
            return obj;
        },
    },
};
</script>

<style lang="scss">
.group-info {
    //padding: $padding;
    text-align: center;
    > .desc {
        font-weight: bold;
    }

    .btn-group-buy {
        font-size: 120%;
        background-color: $color-main;
        color: #fff;
        border-radius: 0.1rem;
        padding: $padding;

        &.disabled {
            background-color: $gray6;
        }
    }
}
</style>
