<!-- Created by henian.xu on 2018/4/18. -->

<template>
    <div
        class="order-goods-item"
        v-bind="linkerProps"
        v-on="$listeners_"
    >
        <div class="inner">
            <div
                class="img-wrap"
                :style="`background-image: url('${$options.filters.imgCropping(data.specImageUrl || $defaultImg,{width:350})}');`"
            >
                <!--<div class="sold-out"
                     v-if="data.sellQuantity>=data.limitQuantity"
                ></div>-->
            </div>
            <div class="inner">
                <div class="name">
                    {{ data.name||data.goodsName }}
                </div>
                <div
                    class="sku"
                    v-if="data.specDesc"
                >
                    <span>{{ data.specDesc }}</span>
                </div>
                <div class="price-wrap">
                    <div class="inner">
                        <span
                            v-if="data.finalPrice"
                            class="price"
                        >
                            {{ data.finalPrice | price }}
                        </span>
                        <span
                            v-if="data.goodsPremiumAmount && isPremium"
                            class="premium"
                        >
                            <template v-if="model">
                                <Checkbox
                                    v-model="model"
                                    :val="data.skuId"
                                >
                                    <Icon
                                        code="&#xf078;"
                                        icon-cls="tc-red"
                                    />
                                    <span class="price">{{ data.goodsPremiumAmount | price }}</span>
                                </Checkbox>
                                <Icon
                                    code="&#xf040;"
                                    icon-cls="tc-red"
                                    @click="onPremiumTips"
                                />
                            </template>
                            <template v-else>
                                <Icon
                                    code="&#xf078;"
                                    icon-cls="tc-red"
                                />
                                &nbsp;
                                <span class="price">{{ data.goodsPremiumAmount | price }}</span>
                            </template>
                        </span>
                    </div>
                    <div
                        v-if="data.quantity"
                        class="quantity"
                    >
                        x {{ data.quantity }}
                    </div>
                    <refund-popup
                        v-if="!noCanRefund && data.isCanRefund"
                        :order-detail-id="data.id"
                        class="btn btn-main small link bor radius all"
                    >
                        申请售后
                    </refund-popup>
                    <template v-if="type===2">
                        <div />
                        <div class="btn btn-main small link bor radius all">
                            查看评价
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <!--保费-->
        <!--<div
            v-if="data.goodsPremiumAmount && model"
            class="premium"
        >
            <Checkbox
                v-model="model"
                :val="data.skuId"
            >
                <Icon
                    code="&#xf078;"
                    icon-cls="tc-red"
                />&nbsp;<span class="price">{{ data.goodsPremiumAmount }}</span>
            </Checkbox>
            <Icon
                code="&#xf041;"
                icon-cls="tc-red"
                @click="onPremiumTips"
            />
        </div>-->
        <!--<div
            v-else-if="data.goodsPremiumAmount"
            class="premium"
        >
            <div v-if="data.goodsPremiumAmount">
                <Icon
                    code="&#xf078;"
                    icon-cls="tc-red"
                /> <span class="price">{{ data.goodsPremiumAmount }}</span>
            </div>
        </div>-->
    </div>
</template>

<script>
import goodsItemMixin from './goodsItemMixin';
import labelMixin from '@/mixin/linker';

export default {
    name: 'OrderGoodsItem',
    mixins: [goodsItemMixin, labelMixin],
    data() {
        return {
            countdown: {},
        };
    },
    props: {
        value: {
            type: Array,
            default: null,
        },
        noCanRefund: {
            type: Boolean,
            default: false,
        },
        type: {
            type: Number,
            default: 0,
        },
        isPremium: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        $listeners_() {
            const data = {
                ...this.$listeners,
            };
            delete data.input;
            return data;
        },
    },
    methods: {
        onPremiumTips() {
            this.$messageBox.alert(`<div style="white-space: pre-line;">${this.data.goodsPremiumDesc}</div>`);
        },
    },
};
</script>

<style lang="scss">
.order-goods-item {
    padding: $padding;
    background-color: #fff;
    + .order-goods-item {
        border-top: 1px solid $color-border;
    }
    > .inner {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        > .img-wrap {
            flex: 0 0 auto;
            width: 1.6rem;
            height: 1.6rem;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            margin-right: $margin;

            > .sold-out {
                background: rgba(0, 0, 0, 0.5) url('~@/assets/images/comm/icon01.png') center no-repeat;
                background-size: 70%;
                width: 100%;
                height: 100%;
            }
        }
        > .inner {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: stretch;

            > .name {
                line-height: 1.2;
                font-size: 0.3rem;
                @include text-line(2);
            }
            > .sku {
                > span {
                    display: inline-block;
                    font-size: 0.22rem;
                    line-height: 1.5;
                    padding: 0 $padding-small;
                    border: 1px solid $color-main;
                    color: $color-main;
                    border-radius: (0.22rem * 1.5)/2;
                }
            }
            > .price-wrap {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-end;
                > .inner {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    .premium {
                        display: inline-flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        margin-left: $margin-small;

                        > .icon {
                            margin-bottom: 3px;
                            margin-left: $margin-small;
                        }
                    }
                }
            }
        }
    }
}
</style>
