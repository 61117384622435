/**
 * Created by henian.xu on 2017/10/24.
 *
 */
import { Store } from './_Store';

class ShopStoreGoodsCat extends Store {
    constructor() {
        super();
        this.baseUrl += '/shopStoreGoodsCat';
    }

    /**
     * 商品分类分页列表
     * @returns {AxiosPromise<any>}
     */
    data({ currentPage, pageSize }) {
        return super.get(`/data`, { currentPage, pageSize });
    }

    /**
     * 获取商品类目详情
     * @returns {AxiosPromise<any>}
     */
    get(id) {
        return super.get(`/get`, { id });
    }

    /**
     * 删除商品分类
     * @param ids
     * @returns {*}
     */
    delete(ids) {
        return super.post(`/delete`, { ids });
    }

    /**
     * 保存商品类目
     * @param id 空为新增
     * @param goodsCatName
     * @param orderNum
     * @param isAvailable
     * @returns {*}
     */
    save({
        id,
        goodsCatName, //类目名称
        orderNum, //排序号
        isAvailable, //是否可用
    }) {
        return super.post(`/save`, {
            id,
            goodsCatName, //类目名称
            orderNum, //排序号
            isAvailable, //是否可用
        });
    }

    /**
     * 改变商品类目状态
     * @param id
     * @param isAvailable
     * @returns {*}
     */
    status({
        id,
        isAvailable, //是否可用
    }) {
        return super.post('/status', {
            id,
            isAvailable, //是否可用
        });
    }

    /**
     * 商品类目下拉列表数据
     * @returns {*}
     */
    select() {
        return super.get('/select', {
            _isRepeat: true,
        });
    }
}

export default new ShopStoreGoodsCat();
