<!-- Created by henian.xu on 2018/5/11. -->

<template>
    <div class="img-list">
        <template v-for="(item,index) in data">
            <div
                v-if="!maxShowQuantity || index<maxShowQuantity"
                :class="['item',`column-${column}`]"
                :key="index"
                @click="onItem(item,index)"
            >
                <template v-if="!autoHeight">
                    <div
                        class="inner"
                        :style="`background-image: url('${item}');`"
                    />
                </template>
                <template v-else>
                    <div class="inner auto-height">
                        <img
                            :src="item"
                            width="100%"
                        >
                    </div>
                </template>
            </div>
        </template>
        <SwiperPopup
            v-if="!$globalVar.device.isWeiXin"
            ref="swiperPopup"
            :data="data"
        />
        <div
            v-if="maxShowQuantity && data.length > maxShowQuantity"
            class="total-quantity"
        >
            共 {{ data.length }} 张
        </div>
    </div>
</template>
<script>
export default {
    name: 'ImgList',
    data() {
        return {};
    },
    props: {
        data: {
            type: Array,
            default() {
                return [];
            },
            required: true,
        },
        column: {
            type: Number,
            default: 4,
        },
        autoHeight: {
            type: Boolean,
            default: false,
        },
        maxShowQuantity: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        swiperPopup() {
            return this.$refs['swiperPopup'] || {};
        },
        swiper() {
            return this.swiperPopup.swiper || {};
        },
    },
    methods: {
        onItem(item, index) {
            this.$wx.previewImage(item, this.data).catch(() => {
                console.log(index, this.swiper);
                this.swiper.slideTo(index, 1000, false);
                this.swiperPopup.open();
            });
        },
    },
};
</script>

<style lang="scss">
.img-list {
    flex: 1 1 auto;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    > .item {
        flex: 0 0 auto;
        width: 100%;
        padding: $padding-small/2;
        line-height: 0;
        @for $i from 1 through 10 {
            &.column-#{$i} {
                width: 100% / $i;
            }
        }
        > .inner {
            line-height: $line-height;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            width: 100%;
            height: 0;
            padding-top: 100%;
            box-shadow: 0 0.05rem 0.1rem 0 #cacaca;
            border-radius: 0.1rem;
            &.auto-height {
                padding: 0;
                height: inherit;
                line-height: 0;
                overflow: hidden;
            }
        }
    }

    > .total-quantity {
        position: absolute;
        top: $padding;
        left: $padding;
        padding: 0 $padding-small;
        border-radius: 10px;
        font-size: 80%;
        background-color: rgba(255, 255, 255, 0.8);
        color: $gray6;
    }
}
</style>
