/**
 * Created by henian.xu on 2017/10/17 0017.
 *
 */

import Vue from 'vue';
import Moment from 'moment';
import GlobalVar from 'globalVar';
import { Comm } from '@/utils';

class Formatter {
    date(value) {
        if (!value) {
            return value;
        }
        const date = new Date(value);
        const Y = date.getFullYear();
        let m = date.getMonth() + 1;
        let d = date.getDate();
        if (m < 10) {
            m = '0' + m;
        }
        if (d < 10) {
            d = '0' + d;
        }

        const t = Y + '-' + m + '-' + d;
        return t;
    }

    dateTime(value) {
        if (!value) {
            return value;
        }
        if (typeof value !== 'number') {
            return value;
        }
        const date = new Date(value);

        const Y = date.getFullYear();
        let m = date.getMonth() + 1;
        let d = date.getDate();
        let H = date.getHours();
        let i = date.getMinutes();
        let s = date.getSeconds();
        if (m < 10) {
            m = '0' + m;
        }
        if (d < 10) {
            d = '0' + d;
        }
        if (H < 10) {
            H = '0' + H;
        }
        if (i < 10) {
            i = '0' + i;
        }
        if (s < 10) {
            s = '0' + s;
        }
        const t = Y + '-' + m + '-' + d + ' ' + H + ':' + i + ':' + s;
        return t;
    }

    time(value) {
        if (!value) {
            return value;
        }
        if (typeof value !== 'number') {
            return value;
        }

        const date = new Date(value);
        let H = date.getHours();
        let i = date.getMinutes();
        let s = date.getSeconds();

        if (H < 10) {
            H = '0' + H;
        }
        if (i < 10) {
            i = '0' + i;
        }
        if (s < 10) {
            s = '0' + s;
        }
        const t = H + ':' + i + ':' + s;
        return t;
    }
}

const formatter = new Formatter();

Vue.filter('dateTime', function(value) {
    return formatter.dateTime(value);
});

Vue.filter('date', function(value) {
    return formatter.date(value);
});

Vue.filter('time', function(value) {
    return formatter.time(value);
});

Vue.filter('moment', (value, format = 'YYYY-MM-DD', b = false) => {
    // YYYY-MM-DD HH:mm:ss
    if (!value) return '';
    const now = Moment().startOf('day');
    const moment = Moment(value);
    let res = moment.format(format);
    if (!b || !/(.*)MM(.*)DD/.test(format)) return res;
    const diff = now.diff(moment.clone().startOf('day'), 'days');
    const diffYear = now.diff(moment.clone().startOf('yesy'), 'year');
    if (diff > 0 && diff < 3) {
        // format = format.replace('YYYY-MM-DD', '');
        format = format.replace(/(.*)YYYY(.*)MM(.*)DD(.*)/, '').trim();
        res = format ? moment.format(format) : '';
        if (diff === 1) {
            res = '昨天' + res;
        } else if (diff === 2) {
            res = '前天' + res;
        }
    } else if (diffYear <= 0) {
        format = format.replace(/Y+.[^MD]/g, '').trim();
        res = moment.format(format);
    }
    return res;
});

Vue.filter('substringStr', function(value) {
    const maxLength = 20;
    if (value.length > maxLength) {
        return value.substring(0, maxLength) + '...';
    } else {
        return value;
    }
});

Vue.filter('number', function(value, length = 2, strict = true) {
    return Comm.filterNumber(value, length, strict);
});

Vue.filter('price', function(value, length = 0, strict = false) {
    const amountFormat = GlobalVar.moduleConfig.amountFormat + '';
    const signStrict = amountFormat.charAt(0) !== '+';
    const arr = amountFormat.split('.');
    const len = arr.length < 2 ? 0 : arr[1].length;
    return Comm.filterNumber(value, length || len, strict || signStrict);
});

Vue.filter('htmlDecode', function(value) {
    if (value === '' || value === undefined || value === null) return '';
    const node = document.createElement('div');
    node.innerHTML = value;
    return node.innerText || '';
});

Vue.filter('money', function(value = '') {
    value += '';
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0;
});

/*Vue.filter('imgCropping', function(value, { width = 750 } = {}) {
    value += '';
    const { moduleConfig } = GlobalVar;
    if (!value || !moduleConfig || !moduleConfig.imgCropping || value.indexOf(GlobalVar.ossFilePath) !== 0)
        return value;
    // const extensionName = value.replace(/.+\./, '');
    return `${value}` + (width ? `@${width}w` : '');
});*/

/**
 * imgCropping
 * mini: 100
 * small: 200
 * little: 375
 * normal: 750
 * big: 900
 * more: 1200
 * super: 1920
 */
Vue.filter('imgCropping', (value, params) => {
    value += '';
    const { moduleConfig: { imgCropping } = {} } = GlobalVar;
    if (!value || !imgCropping || params === 'none') return value;
    const imgCroppingList = Array.isArray(imgCropping) ? imgCropping : [imgCropping];
    const available = imgCroppingList.some(val => typeof val === 'string' && value.indexOf(val) === 0);
    // console.log(available, params);
    if (!available) return value;
    if (params && typeof params === 'string') return `${value}_${params}`;
    if (params && typeof params === 'object') {
        // doc: https://help.aliyun.com/document_detail/44688.html?spm=a2c4g.11186623.6.1372.11313168wqiX8s
        const keyMap = {
            mode: 'm', // lfit、mfit、fill、pad、fixed，默认为 lfit。
            width: 'w',
            height: 'h',
            long: 'l',
            short: 's',
            limit: 'limit',
            color: 'color',
        };
        const query = Object.entries(params)
            .reduce(
                (pre, [key, val]) => {
                    const k = keyMap[key];
                    if (k) pre.push(`${k}_${val}`);
                    return pre;
                },
                ['image/resize'],
            )
            .join(',');
        const fn = '?x-oss-process=';
        return `${value}${fn}${query}`;
    }
    return `${value}_normal`;
});
