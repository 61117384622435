/**
 * Created by henian.xu on 2017/10/24.
 *
 */
import { Mb } from '@/api/buyer/Mb/Mb';

class Coupon extends Mb {
    constructor() {
        super();
        this.baseUrl += '/buyerCoupon';
    }

    list({ couponType, currentPage, pageSize } = {}) {
        return super.get(`/couponList`, {
            couponType,
            currentPage,
            pageSize,
        });
    }
    orderCouponList({ uuid } = {}) {
        return super.get(`/orderCouponList`, { uuid });
    }
    receiveCoupon({ couponCodes } = {}) {
        return super.post(`/receiveCoupon`, { couponCodes, _isHandleError: true });
    }
}

export default new Coupon();
