/**
 * Created by henian.xu on 2017/5/16.
 *
 */

'use strict';

/**
 * @param {Number} [baseFontSize = 100] - 基础fontSize, 默认100px;
 * @param {Number} [fontscale = 1] - 有的业务希望能放大一定比例的字体;
 */
/* const win = window;
export default win.flex = (baseFontSize, fontscale) => {
    const _baseFontSize = baseFontSize || 100;
    const _fontscale = fontscale || 1;

    const doc = win.document;
    const ua = navigator.userAgent;
    const matches = ua.match(/Android[\S\s]+AppleWebkit\/(\d{3})/i);
    const UCversion = ua.match(/U3\/((\d+|\.){5,})/i);
    const isUCHd = UCversion && parseInt(UCversion[1].split('.').join(''), 10) >= 80;
    const isIos = navigator.appVersion.match(/(iphone|ipad|ipod)/gi);
    let dpr = win.devicePixelRatio || 1;
    if (!isIos && !(matches && matches[1] > 534) && !isUCHd) {
        // 如果非iOS, 非Android4.3以上, 非UC内核, 就不执行高清, dpr设为1;
        dpr = 1;
    }
    const scale = 1 / dpr;

    let metaEl = doc.querySelector('meta[name="viewport"]');
    if (!metaEl) {
        metaEl = doc.createElement('meta');
        metaEl.setAttribute('name', 'viewport');
        doc.head.appendChild(metaEl);
    }
    metaEl.setAttribute('content', `width=device-width,user-scalable=no,initial-scale=${scale},maximum-scale=${scale},minimum-scale=${scale}`);
    const fontSize = _baseFontSize / 2 * dpr * _fontscale;
    doc.documentElement.style.fontSize = `${fontSize}px`;
    win.globalVar.device = {
        ...win.globalVar.device,
        scale: scale,
        dpr: dpr,
        fontSize: fontSize,
        width: doc.documentElement.clientWidth,
        height: doc.documentElement.clientHeight,
        ...browserType(),
    };
    /!* if (dpr <= 1 && win.globalVar.device.width >= 768) {
        const $app = doc.querySelector('#app');
        $app.style.width = '600px';
        console.log($app);
    }
    console.log(win.globalVar.device); *!/
    // alert(win.globalVar.device.width + ' -- ' + win.globalVar.device.height + ' -- ' + win.globalVar.device.dpr);
};

win.flex(100, 1); */

window.globalVar = window.globalVar || {};
(function(doc, win) {
    const docEl = doc.documentElement;
    const resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize';
    const clientWidth = docEl.clientWidth;
    const browserData = browserType();
    const zoom = clientWidth / 750;
    let fontSize = 100 * zoom;
    const recalc = function() {
        if (!clientWidth) return;
        if (!browserData.isMobile) {
            docEl.style.fontSize = '50px';
            fontSize = 50;
            window.globalVar.zoom = zoom;
        } else if (clientWidth >= 750) {
            fontSize = 100;
            docEl.style.fontSize = '100px';
        } else {
            docEl.style.fontSize = fontSize + 'px';
            window.globalVar.zoom = zoom;
        }
    };
    recalc();

    if (!doc.addEventListener) return;
    win.addEventListener(resizeEvt, recalc, false);
    doc.addEventListener('DOMContentLoaded', recalc, false);

    win.globalVar.device = {
        ...win.globalVar.device,
        scale: zoom,
        // dpr: dpr,
        fontSize: fontSize,
        width: doc.documentElement.clientWidth,
        height: doc.documentElement.clientHeight,
        ...browserData,
    };
})(document, window);

function browserType() {
    const userAgent = navigator.userAgent; // 取得浏览器的userAgent字符串
    const isOpera = userAgent.indexOf('Opera') > -1; // 判断是否Opera浏览器
    const isIE = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1 && !isOpera; // 判断是否IE浏览器
    const isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1;
    const isEdge = userAgent.indexOf('Edge') > -1 && !isIE; // 判断是否IE的Edge浏览器
    const isFF = userAgent.indexOf('Firefox') > -1; // 判断是否Firefox浏览器
    const isSafari = userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') === -1; // 判断是否Safari浏览器
    const isChrome = userAgent.indexOf('Chrome') > -1 && userAgent.indexOf('Safari') > -1; // 判断Chrome浏览器
    const isUC = userAgent.indexOf('UCBrowser') > -1; // 判断UC浏览器
    const isMobile = !!userAgent.match(/AppleWebKit.*Mobile.*/); // || !!userAgent.match(/AppleWebKit/); // 是否为移动终端
    const isWeiXin = !!userAgent.match(/MicroMessenger/);

    const res = {
        isWeiXin: isMobile && isWeiXin,
        isMobile,
        browser: '',
        // userAgent,
    };
    // console.log(isMobile, isWeiXin, userAgent);
    // alert(isMobile + ' -- ' + isWeiXin + ' -- ' + userAgent);
    if (isIE) {
        const reIE = new RegExp('MSIE (\\d+\\.\\d+);');
        reIE.test(userAgent);
        const fIEVersion = +parseFloat(RegExp['$1']);
        if (fIEVersion === 7) return 'IE7';
        else if (fIEVersion === 8) return 'IE8';
        else if (fIEVersion === 9) return 'IE9';
        else if (fIEVersion === 10) return 'IE10';
        else return 'IE7以下'; // IE版本过低
    }
    if (isIE11) res.browser = 'IE11';
    if (isEdge) res.browser = 'Edge';
    if (isFF) res.browser = 'FF';
    if (isOpera) res.browser = 'Opera';
    if (isSafari) res.browser = 'Safari';
    if (isChrome) res.browser = 'Chrome';
    if (isUC) res.browser = 'UCBrowser';
    if (isWeiXin) res.browser = 'WeiXin';
    return res;
}
