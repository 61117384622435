<!-- Created by henian.xu on 2018/8/26. -->

<template>
    <div
        :class="[...classes,'x-label']"
        :style="styles"
        v-bind="bindProps"
        v-on="listenerProps"
    >
        <slot name="icon">
            <i
                class="f-icon"
                :class="iconClass"
                :style="hasInner&&'font-size: 160%;'"
                v-if="icon"
                v-html="icon"
            />
        </slot>
        <template
            v-if="hasInner"
        >
            <slot
                name="inner"
            >
                <div
                    class="inner"
                    :class="innerClass"
                    v-if="!$slots.inner"
                >
                    <slot name="label">
                        <div
                            class="label"
                            v-if="hasLabel"
                        >
                            {{ label }}
                        </div>
                        <slot v-else />
                    </slot>
                    <slot name="subLabel">
                        <div class="sub-label">
                            {{ subLabel }}
                        </div>
                    </slot>
                    <slot v-if="hasLabel && defaultSlotAtInner" />
                </div>
                <slot v-if="hasLabel && !defaultSlotAtInner" />
            </slot>
            <slot v-if="$slots.inner" />
        </template>
        <template v-else>
            <slot name="label">
                {{ label }}
            </slot>
            <slot name="subLabel">
                {{ subLabel }}
            </slot>
            <slot />
        </template>
    </div>
</template>

<script>
import labelMixin from './labelMixin';

export default {
    name: 'Label',
    mixins: [labelMixin],
};
</script>

<style lang="scss">
.#{$pf}label {
    flex: 1 1 1%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    > .f-icon {
        line-height: 1;
        margin-right: $margin-small / 2;
    }

    > .inner {
        position: relative;
        > .label {
            line-height: 1.2;
        }
        > .sub-label {
            font-size: 70%;
            line-height: 1;
        }
    }

    /*.badge {
        position: absolute;
        z-index: $z-index-4;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
    }
    > .inner {
        > .badge {
            transform: translate(70%, -70%);
        }
    }*/
}
</style>
