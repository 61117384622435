/**
 * Created by henian.xu on 2018/4/19.
 *
 */

export default {
    data() {
        return {
            infiniteDisabled: false,
            pagination: {},
        };
    },
    computed: {
        $$infiniteState() {
            const infinite = this.$refs['infinite'];
            return infinite ? infinite.infiniteState : null;
        },
    },
    methods: {
        onInfinite($state) {
            const { currentPage, totalRows, totalPages } = this.pagination;
            if (totalRows && currentPage >= totalPages) {
                $state.loaded();
                $state.complete();
                return;
            }
            this.getListData().then(json => {
                const result = json.data;
                this.pagination = result.pagination;
                const { currentPage, totalRows, totalPages } = this.pagination;
                if (this.pagination.currentPage === 1) {
                    $state.reset();
                }
                if (!totalRows || currentPage >= totalPages) {
                    if (totalRows) {
                        $state.loaded();
                    }
                    $state.complete();
                } else {
                    $state.loaded();
                }
            });
        },
    },
};
