<!-- Created by henian.xu on 2019/5/13. -->

<template>
    <div class="proxy-store-category-item">
        <div class="header">
            <div class="label">
                {{ data.goodsCatName }}
            </div>
            <div>排序号: <span class="badge badge-main fs-small">{{ data.orderNum }}</span></div>
        </div>
        <div class="body">
            <Checkbox
                v-model="data.isAvailable"
                @input="(val)=>{onAvailableInput(val)}"
            >
                是否启用
            </Checkbox>
            <div class="extra">
                <Button
                    icon="&#xf008;"
                    theme="main"
                    link
                    @click="()=>{onDel()}"
                />
                <ProxyStoreAddCategory
                    :data="data"
                    @update="onUpdate"
                >
                    <Button
                        icon="&#xf009;"
                        theme="blue"
                        link
                    />
                </ProxyStoreAddCategory>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProxyStoreCategoryItem',
    data() {
        return {};
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },
    methods: {
        async onDel() {
            const json = await this.$api.Buyer.Sp.ShopStoreGoodsCat.delete(this.data.id);
            this.$emit('del', this.data.id);
            this.$messageBox.tips(json.data.msg);
        },
        onUpdate(val) {
            const { data } = this;
            data.orderNum = data.orderNum || null;
            Object.keys(data).forEach(key => {
                if (val.hasOwnProperty(key)) {
                    data[key] = val[key];
                }
            });
        },
        async onAvailableInput(val) {
            const json = await this.$api.Buyer.Sp.ShopStoreGoodsCat.status({
                id: this.data.id,
                isAvailable: val,
                _isHandleError: true,
            });
            const res = json.data;
            this.$messageBox.tips(res.msg);
            if (!res.success) {
                this.$nextTick(() => {
                    this.data.isAvailable = !val;
                });
            }
        },
    },
};
</script>

<style lang="scss">
.proxy-store-category-item {
    padding: $padding $padding;
    + .category-item {
        border-top: 1px solid $color-border;
    }

    > .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .label {
            flex: 1 1 1%;
            font-weight: bold;
            @include text-line(1);
        }
    }

    > .body {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .extra {
            flex: 0 0 auto;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            .button {
                padding-left: $padding-small;
                padding-right: $padding-small;
            }
        }
    }
}
</style>
