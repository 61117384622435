<!-- Created by henian.xu on 2018/2/27. -->

<template>
    <div 
        :class="['switch',value?'open':'close']"
        @click="onClick"
    >
        <div class="dot" />
    </div>
</template>

<script>
export default {
    name: 'XSwitch',
    data() {
        return {};
    },
    props: {
        value: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },
    methods: {
        onClick() {
            this.$emit('input', !this.value);
        },
    },
};
</script>

<style lang="scss">
.switch {
    position: relative;
    display: inline-block;
    width: 1rem;
    height: 0.5rem;
    border-radius: 2.5rem;
    border: 1px solid $color-border;
    background-color: $gray6;
    > .dot {
        position: absolute;
        top: -1px;
        left: -1px;
        width: 0.5rem;
        height: 0.5rem;
        background-color: #fff;
        border: 1px solid $color-border;
        border-radius: 50%;
    }

    &.close {
        background-color: #fff;
    }
    &.open {
        background-color: $color-main;
        border-color: $color-main;
        > .dot {
            border-color: $color-main;
            left: auto;
            right: -1px;
        }
    }
}
</style>
