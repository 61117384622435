<!-- Created by henian.xu on 2019/2/25. -->

<template>
    <div
        class="review-item"
        v-bind="linkerProps"
        v-on="$listeners"
    >
        <div class="inner">
            <div class="header">
                <div class="user">
                    <div class="avatar">
                        <div
                            class="inner"
                            :style="`background-image: url('${data.avatar||$defaultAvatar}');`"
                        />
                    </div>
                    <div class="info">
                        <div class="nickname">
                            {{ data.nickName }}
                        </div>
                        <div class="review-time">
                            {{ data.reviewTime | moment }}
                        </div>
                    </div>
                </div>
                <div class="extra">
                    <Rater
                        readonly
                        hide-label
                        class="ta-r"
                        v-model="data.rating"
                    />
                </div>
            </div>
            <div class="body">
                <div class="content">
                    {{ data.reviewContent }}
                </div>
                <ImgList
                    :column="3"
                    :data="data.reviewAttachmentList"
                />
            </div>
            <div
                v-if="data.specDesc"
                class="footer"
            >
                <div>{{ data.specDesc }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import linkerMixin from '@/mixin/linker';
export default {
    name: 'ReviewItem',
    mixins: [linkerMixin],
    data() {
        return {
            rate: 2,
        };
    },
    props: {
        data: {
            type: Object,
            default() {
                return {};
            },
        },
    },
};
</script>

<style lang="scss">
.review-item {
    display: block;
    padding: $padding;
    + .review-item {
        margin-top: -$padding;
    }
    > .inner {
        padding: $padding;
        background-color: #fff;
        border-radius: 10px;

        > .header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            > .user {
                flex: 1 1 1%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                > .avatar {
                    > .inner {
                        width: 0.8rem;
                        height: 0.8rem;
                        background: transparent center no-repeat;
                        background-size: cover;
                        border-radius: 100vw;
                    }

                    margin-right: $margin-small;
                }
                > .info {
                    > .review-time {
                        color: $gray6;
                        font-size: 80%;
                    }
                }
            }
            > .extra {
                flex: 0 0 auto;
            }
        }
        > .body {
            padding: $padding-small 0;
            > .content {
                padding: $padding-small 0;
                word-break: break-all;
            }
        }
        > .footer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            color: $gray6;
        }
    }
}
</style>
