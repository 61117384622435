<!-- Created by henian.xu on 2018/12/6. -->

<template>
    <div class="group-countdown">
        {{ surplusTime }}
    </div>
</template>

<script>
export default {
    name: 'GroupCountdown',
    data() {
        return {
            surplusTime: '00:00:00',
        };
    },
    props: {
        endTime: {
            type: Number,
            default: 0,
            required: true,
        },
    },
    watch: {
        endTime: {
            handler(val) {
                if (!val) return;
                this.setSurplusTime(val);
            },
            immediate: true,
        },
    },
    methods: {
        setSurplusTime() {
            let ss = this.endTime - new Date().getTime();
            setInterval(() => {
                ss -= 1000;
                const d = parseInt(ss / 86400000);
                const h = parseInt((ss % 86400000) / 3600000);
                const i = parseInt(((ss % 86400000) % 3600000) / 60000);
                const s = parseInt((((ss % 86400000) % 3600000) % 60000) / 1000);
                const data_ = {
                    d: d > 9 ? '' + d : '0' + d,
                    h: h > 9 ? '' + h : '0' + h,
                    i: i > 9 ? '' + i : '0' + i,
                    s: s > 9 ? '' + s : '0' + s,
                    ss,
                };
                this.surplusTime = (+data_.d === 0 ? '' : `${data_.d}天 `) + `${data_.h}:${data_.i}:${data_.s}`;
            }, 1000);
        },
    },
};
</script>

<style lang="scss">
.group-countdown {
    display: inline-block;
}
</style>
