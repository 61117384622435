<!-- Created by henian.xu on 2018/1/12. -->

<!--<template>
    <div class="tab-nav-wrap">
        <div class="btn prev"><i class="f-icon">&#xf011;</i></div>
        <div class="tab-nav">
            <div class="inner">
                <div class="item">
                    <div class="label">1item item item</div>
                </div>
                <div class="item">
                    <div class="label">2item item item</div>
                </div>
                <div class="item">
                    <div class="label">3item item item</div>
                </div>
                <div class="item">
                    <div class="label">4item item item</div>
                </div>
                <div class="item">
                    <div class="label">5item item item</div>
                </div>
            </div>
        </div>
        <div class="btn next"><i class="f-icon">&#xf012;</i></div>
        <div class="btn btn-main">btn</div>
    </div>
</template>-->

<script>
import { Comm } from '@/utils';

export default {
    name: 'TabNav',
    data() {
        return {
            index: this.$parent.index || 0,
        };
    },
    props: {
        panes: {
            type: Array,
            default() {
                return [];
            },
        },
        onTabClick: {
            type: Function,
            default: Comm.emptyFn,
        },
    },
    computed: {
        parntIndex() {
            return this.$parent.currIndex || 0;
        },
        $$nav() {
            return this.$refs.nav;
        },
        $$currItem() {
            return this.$refs[`navItem${this.index}`];
        },
    },
    watch: {
        parntIndex: {
            handler(val) {
                this.index = val;
                this.onTabClick({ type: 'customize' }, this.panes[val], val);
                this.$nextTick(() => {
                    this.keepCenter();
                });
            },
            immediate: true,
        },
    },
    methods: {
        onItem($event, item, index) {
            this.index = index;
            this.onTabClick($event, item, index);
        },
        keepCenter() {
            if (!this.$$nav || !this.$$currItem) return;
            const navWidth = this.$$nav.clientWidth;
            const itemWidth = this.$$currItem.clientWidth;
            const itemOffset = this.$$currItem.offsetLeft;
            this.$$nav.scrollLeft = itemOffset - (navWidth - itemWidth) / 2;
        },
    },
    mounted() {},
    render() {
        const self = this;
        return (
            <div class="tab-nav-wrap">
                {/* <div class="btn prev"><i class="f-icon">&#xf011;</i></div> */}
                <div class="tab-nav" ref="nav">
                    <div class="inner">
                        {this._l(this.panes, (item, index) => {
                            item.index = index;
                            return (
                                <div
                                    ref={'navItem' + index}
                                    class={{
                                        item: true,
                                        active: index === self.index,
                                    }}
                                    on-click={$event => {
                                        self.onItem($event, item, index);
                                    }}
                                >
                                    {item._navItem || item.label}
                                </div>
                            );
                        })}
                    </div>
                </div>
                {/* <div class="btn next"><i class="f-icon">&#xf012;</i></div>
                        <div class='btn btn-main'>btn</div> */}
            </div>
        );
    },
};
</script>

<style lang="scss">
</style>
