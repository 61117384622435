<!-- Created by henian.xu on 2018/5/9. -->

<template>
    <div class="gift-card-picker">
        <div
            class="inner"
            @click="onSwitch"
        >
            <div
                class="label"
                v-if="label"
            >
                {{ label }}
            </div>
            <slot name="extra">
                <div class="extra">
                    <div v-if="!model.id && available">
                        <div class="badge badge-red plane">
                            有 {{ available }} 张可用
                        </div>
                        <span class="tc-red">&nbsp;去使用&nbsp;</span>
                    </div>
                    <div
                        v-else-if="available==0"
                        class="details"
                    >
                        无可用
                    </div>
                    <div v-else>
                        <Icon
                            code="&#xf040;"
                            icon-cls="tc-red"
                            @click.stop.prevent="onPremiumTips"
                        /> 返现金额 <span class="price">{{ currentDeductibleAmount }}</span>
                    </div>
                </div>
            </slot>
        </div>
        <!--popup-->
        <transition :name="aniName">
            <Page
                class="bc-g2"
                v-show="popupShow"
                ref="popup"
            >
                <query-box
                    class="second-header"
                    top=".89rem"
                    icon="&#xf00d;"
                    placeholder="请输入礼品卡号"
                    search-btn-str="绑定"
                    show-search-btn
                    show-reset-btn
                    v-model="cardSn"
                    @query="onConvertCoupon"
                />
                <div
                    class="second-header"
                    @click="onSecondHeader"
                >
                    <div class="label">
                        不使用礼品卡
                    </div>
                    <checkbox
                        type="radio"
                        :val="0"
                        :stop-propagation="false"
                        v-model="currChecked"
                    />
                </div>
                <container>
                    <list class="pa-a">
                        <GiftCardItem
                            v-for="(item) in listData"
                            :key="item.id"
                            :data="item"
                            v-model="currChecked"
                            is-select
                            @click="onItem(item)"
                        />
                    </list>
                    <!--<infinite-loading
                        ref="infinite"
                        :disabled="infiniteDisabled"
                        @infinite="onInfinite"
                    />-->
                </container>
            </Page>
        </transition>
    </div>
</template>

<script>
import popup from '@/mixin/popup';
import infiniteLoading from '@/mixin/infiniteLoading';

export default {
    name: 'GiftCardPicker',
    mixins: [popup, infiniteLoading],
    data() {
        return {
            appLevel: true,
            listData: [],
            currChecked: 0,
            infiniteDisabled: true,
            cardSn: '',
        };
    },
    props: {
        value: {
            type: Object,
            default() {
                return {};
            },
        },
        label: {
            type: String,
            default: '',
        },
        uuid: {
            type: String,
            default: '',
            required: true,
        },
        deductibleAmount: {
            type: Number,
            required: true,
        },
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        currentDeductibleAmount() {
            const { model, deductibleAmount } = this;
            const overage = model.totalAmount - model.useAmount;
            if (deductibleAmount > overage) return overage;
            else return deductibleAmount;
        },
        available() {
            // return this.listData.reduce((p, c) => ((p += c.isAvailable ? 1 : 0), p), 0);
            return this.listData.length;
        },
    },
    watch: {
        /*popupShow(val) {
            if (val) {
                this.listData = [];
                this.pagination = {};
                this.infiniteDisabled = false;
            } else {
                this.infiniteDisabled = true;
            }
        },*/
    },
    methods: {
        getListData({ currentPage /*, pageSize*/ } = {}) {
            return this.$api.Buyer.Mb.GiftCard.giftCardList({
                uuid: this.uuid,
                isAllUse: 0,
                currentPage: currentPage || this.pagination.currentPage ? this.pagination.currentPage + 1 : 1,
                pageSize: 99999,
            }).then(json => {
                const res = json.data;
                console.log(res);
                res.data.forEach(item => {
                    item.checked = 0;
                });
                this.listData = res.data;
                return Promise.resolve(json);
            });
        },
        onItem(item) {
            console.log(item);
            // if (!item.isAvailable) return;
            this.currChecked = item.id;
            this.model = item;
            this.popupShow = false;
        },
        onSecondHeader() {
            this.currChecked = 0;
            this.model = {};
            this.popupShow = false;
        },
        refreshData() {
            // this.infiniteDisabled = true;
            // this.pagination = {};
            // this.listData = [];
            // this.$nextTick(() => (this.infiniteDisabled = false));
            this.getListData();
        },
        onConvertCoupon() {
            const { cardSn } = this;
            if (!cardSn) return;
            console.log(cardSn);
            this.$api.Buyer.Mb.GiftCard.bind({
                cardSn,
            }).then(json => {
                const result = json.data;
                if (!result.success) {
                    this.$messageBox.tips(result.msg, 3000);
                    return;
                }
                this.$messageBox.alert('<div class="ta-c">绑定成功</div>', '温馨提示').then(() => {
                    this.refreshData();
                });
            });
        },
        onPremiumTips() {
            this.$messageBox.alert(
                `<div style="white-space: pre-line;">因保税订单付款需要实名认证，且付款金额要与产品申报金额接近，故而针对礼品卡使用的用户，礼品卡抵扣金额将在订单完成后以返现形式退至客户零钱账户，客户通过系统将零钱金额可提现至微信。</div>`,
            );
        },
    },
    created() {
        this.getListData();
    },
};
</script>

<style lang="scss" scoped>
.second-header {
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $padding;
}
</style>
<style lang="scss">
.gift-card-picker {
    flex: 1 1 auto;
    padding: $padding 0;
    > .inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}
</style>
