/**
 * Created by henian.xu on 2018/4/19.
 *
 */

import { DataMap, Comm } from '@/utils';
import { Mb } from '@/api/buyer/Mb/Mb';

class Cart extends Mb {
    constructor() {
        super();
        this.baseUrl += '/cart';
    }

    /**
     * 商品预览
     * @param id
     * @returns {Promise<any>}
     */
    cartList({ currentPage, pageSize } = {}) {
        return super
            .get(`/cartList`, {
                currentPage,
                pageSize,
            })
            .then(json => {
                const res = json.data.data;
                Comm.makeDataMap(res, DataMap.cartGoods);
                return Promise.resolve(json);
            });
    }

    /**
     * 添加商品到购物车
     * @param skuId
     * @param quantity
     * @returns {AxiosPromise}
     */
    addSkuToCart({ skuId, quantity } = {}) {
        return super.post(`/addSkuToCart`, { skuId, quantity });
    }

    /**
     * 修改购物车
     * @param cartJson
     * @returns {AxiosPromise}
     */
    editCart({ cartJson } = {}) {
        return super.post(`/editCart`, { cartJson });
    }

    deleteCart({ cartIds } = {}) {
        return super.post(`/deleteCart`, { cartIds });
    }
}

export default new Cart();
export { Cart };
