<!-- Created by henian.xu on 2018/9/7. -->

<template>
    <list class="goods">
        <GoodsItem
            v-for="item in goodsList"
            :key="item.id"
            :data="item"
            :double-row="+type===1"
        />
        <infinite-loading
            ref="infinite"
            :disabled="infiniteDisabled"
            @infinite="onInfinite"
        />
    </list>
</template>

<script>
import infiniteLoading from '@/mixin/infiniteLoading';
export default {
    name: 'GoodsList',
    mixins: [infiniteLoading],
    data() {
        return {
            goodsList: [],
        };
    },
    props: {
        // 单列:1;双列:2;
        type: {
            type: [Number, String],
            default: 1,
        },
        orderBy: {
            type: String,
            default: 'onSaleTime,asc',
            validator(val) {
                return val.split(',').length >= 2;
            },
        },
    },
    computed: {
        orderByKey() {
            const orderByArr = this.orderBy.split(',');
            return orderByArr[0];
        },
        orderByType() {
            const orderByArr = this.orderBy.split(',');
            return orderByArr[1];
        },
    },
    watch: {
        orderBy: {
            handler(val) {
                if (!val) return;
                const orderByArr = val.split(',');
                if (!orderByArr || !orderByArr.length) return;
                this.$api.Buyer.Gd.Goods.goodsList({
                    orderByKey: orderByArr[0],
                    orderByType: orderByArr[1],
                    pageSize: 6,
                }).then(json => {
                    this.goodsList = json.data.data;
                });
            },
            // immediate: true,
        },
    },
    methods: {
        getListData({ currentPage } = {}) {
            return this.$api.Buyer.Gd.Goods.goodsList({
                orderByKey: this.orderByKey,
                orderByType: this.orderByType,
                currentPage: currentPage || this.pagination.currentPage ? this.pagination.currentPage + 1 : 1,
            }).then(json => {
                const res = json.data;
                this.goodsList = this.goodsList.concat(res.data);
                this.isComplete(json);
                return Promise.resolve(json);
            });
        },
        isComplete(json) {
            const result = json.data;
            const { currentPage, totalRows, totalPages } = result.pagination;
            if (!totalRows || currentPage === totalPages) {
                this.infiniteDisabled = true;
                this.$emit('complete');
            }
        },
    },
};
</script>

<style lang="scss">
</style>
