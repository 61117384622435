/**
 * Created by henian.xu on 2018/9/11.
 * 商品模块
 */
import { Buyer } from '@/api/buyer/Buyer';

class Pm extends Buyer {
    constructor() {
        super();
        this.baseUrl += '/pm';
    }
}

export default new Pm();
export { Pm };
