<!-- Created by henian.xu on 2018/11/16. -->

<template>
    <div class="prepaid-pay-popup">
        <List>
            <ListItem
                v-for="(item,index) in payMethods"
                :key="index"
                :icon-class-name="item.iconClassName"
                :icon="item.icon"
                :label="item.label"
                sub-label-class-name="tc-red"
                :sub-label="item.exportStr"
                :extra="item.extra"
                @click="action(item.action, item)"
            >
                <i
                    v-if="item.iconUrl"
                    :class="['icon',item.iconClassName]"
                    slot="icon"
                >
                    <img :src="item.iconUrl">
                </i>
            </ListItem>
        </List>
        <slot />

        <!-- popup -->
        <transition-group name="show">
            <div
                class="popup prepaid"
                v-show="popupShow === 'credit'"
                ref="popupCredit"
                key="credit"
                @click.self="close"
            >
                <transition name="show">
                    <div
                        class="inner"
                        v-show="popupShow"
                    >
                        <div class="header">
                            <div class="label">
                                请选择银行卡
                            </div>
                        </div>
                        <div class="body">
                            <ListItem
                                v-for="item in stripeCardInfoList"
                                :key="item.id"
                                :to="`/pay/stripe/${module}/${orderIds}?cardInfoId=${item.id}`"
                                :label="item.cardNo"
                            >
                                <i
                                    class="icon visa-icon"
                                    slot="icon"
                                >
                                    <img src="~@/assets/images/icon/visa-icon.png">
                                </i>
                            </ListItem>
                            <ListItem
                                :to="`/pay/stripe/${module}/${orderIds}`"
                                label="使用新卡"
                                icon="&#xf015;"
                                icon-class-name="tc-blue"
                            />
                        </div>
                    </div>
                </transition>
            </div>

            <!--checkoutCounter-->
            <Page
                v-show="popupShow === 'checkoutCounter'"
                class="bc-w"
                ref="popupCheckoutCounter"
                key="checkoutCounter"
            >
                <Container class="checkout-counter">
                    <div class="logo-info">
                        <img
                            :src="$globalVar.shopData.logoUrl"
                            class="log"
                        >
                        <div class="label">
                            {{ $globalVar.shopData.name }}
                        </div>
                        <!--<div class="desc">{{ $globalVar.shopData.siteUrl }}</div>-->
                    </div>
                    <div class="checkout-counter-list">
                        <div class="item">
                            <div class="label">
                                订单金额
                            </div>
                            <div class="content">
                                {{ $globalVar.countryData.currencyCode }} {{ payInfo.paymentAmount }}
                            </div>
                        </div>
                        <div
                            class="item"
                            v-if="paymentPoundage.paymentPoundage"
                        >
                            <div class="label">
                                支付手续费
                            </div>
                            <div class="content">
                                <span class="">+{{ $globalVar.countryData.currencyCode }} {{ paymentPoundage.paymentPoundage }}</span>
                                <span
                                    v-if="paymentPoundage"
                                    class="fs-small ma-l"
                                >( 需额外收取 {{ paymentPoundage.paymentPoundageRate }}% 的订单金额 )</span>
                            </div>
                        </div>
                        <div
                            class="item"
                            v-if="paymentPoundage.exchangeRate"
                        >
                            <div class="label">
                                汇率
                            </div>
                            <div class="content">
                                1{{ $globalVar.countryData.currencyCode }}≈{{ paymentPoundage.exchangeRate }}CNY
                            </div>
                        </div>
                        <div class="item">
                            <div class="label">
                                总金额
                            </div>
                            <div class="content">
                                {{ $globalVar.countryData.currencyCode }} {{ payInfo.paymentAmount + (paymentPoundage?paymentPoundage.paymentPoundage:0) | price }}
                            </div>
                        </div>
                        <!--<div class="item">
                            <div class="label">商户订单ID</div>
                            <div class="content">AUD 77.77</div>
                        </div>-->
                    </div>
                    <div class="ctrl">
                        <Button
                            label="立即支付"
                            theme="green"
                            class="w-100per"
                            size="big"
                            @click="action(currentAction.action,currentAction)"
                        />
                    </div>
                </Container>
                <!--<PageBar>
                    <Button
                        label="立即支付"
                        theme="green"
                        @click="action(currentAction.action,currentAction)"/>
                </PageBar>-->
            </Page>
        </transition-group>
    </div>
</template>

<script>
import popupMixin from '@/mixin/popup.js';
import payMixin from '@/mixin/pay';

export default {
    name: 'PrepaidPayPopup',
    mixins: [popupMixin, payMixin],
    data() {
        return {};
    },
    props: {
        orderIds: {
            type: Number,
            default: 0,
            required: true,
        },
    },
    methods: {
        mount() {
            if (this.delayedCreate) return;
            this.$$popupWrap.appendChild(this.$refs['popupCredit']);
            this.$$popupWrap.appendChild(this.$refs['popupCheckoutCounter'].$el);
            this.isAppend = true;
        },
    },
    beforeDestroy() {
        if (!this.isAppend) return;
        this.$$popupWrap.removeChild(this.$refs['popupCredit']);
        this.$$popupWrap.removeChild(this.$refs['popupCheckoutCounter'].$el);
    },
};
</script>

<style lang="scss">
.popup-wrap {
    .popup.prepaid {
        justify-content: flex-end;
        align-items: stretch;
        .inner {
            width: 100%;
            background-color: #fff;
        }
    }
}
</style>
