<!-- Created by henian.xu on 2019/5/14. -->

<template>
    <div
        :class="classes"
        ref="self"
    >
        <div
            class="category-tab-panel-header"
            :style="headerStyle"
            ref="header"
        >
            <div
                class="inner"
                ref="headerInner"
            >
                <div class="label">
                    <!--{{ index }} -->{{ label }}
                </div>
                <!--= {{ currentTop }} = {{ currentBottom }} = {{ inRange }}-->
            </div>
        </div>
        <div class="body">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'CategoryTabPanel',
    inject: ['Tab'],
    data() {
        return {
            isMounted: false,
            index: -1,
        };
    },
    props: {
        label: {
            type: String,
            default: '',
        },
    },
    computed: {
        currentIndex: {
            get() {
                return this.Tab.currentIndex;
            },
            set(val) {
                this.Tab.currentIndex = val;
            },
        },
        active() {
            return this.currentIndex === this.index;
        },
        classes() {
            const { active } = this;
            return ['category-tab-panel', { active }];
        },
        headerHeight() {
            const { inRange, $$header } = this;
            return !inRange ? 0 : $$header.clientHeight;
        },
        headerStyle() {
            const { headerHeight } = this;
            return headerHeight
                ? {
                      height: `${headerHeight}px`,
                  }
                : {};
        },
        $$self() {
            const { $refs, isMounted } = this;
            const { self } = $refs;
            return !isMounted ? null : self || null;
        },
        $$header() {
            const { $refs, isMounted } = this;
            const { header } = $refs;
            return !isMounted ? null : header || null;
        },
        $$headerInner() {
            const { $refs, isMounted } = this;
            const { headerInner } = $refs;
            return !isMounted ? null : headerInner || null;
        },
        currentTop() {
            const { isMounted, $$self, Tab } = this;
            if (!isMounted) return null;
            return $$self.offsetTop - Tab.scrollTop;
        },
        currentBottom() {
            // TODO 看看有没有不用实时计算的方法
            const { index, Tab, currentTop, $$self } = this;
            const { panels } = Tab;
            const len = panels.length;
            if (index < 0) return null;
            if (index + 1 >= len) {
                return $$self.clientHeight;
            } else {
                const panel = panels[index + 1];
                return panel.currentTop - currentTop;
            }
        },
        inRange() {
            const { currentTop, currentBottom, isMounted, Tab } = this;
            return !!(isMounted && Tab.$$float && currentTop <= 0 && currentTop + currentBottom > 0);
        },
        isFloat() {
            const { headerHeight, inRange } = this;
            return headerHeight && inRange;
        },
    },
    watch: {
        isFloat: {
            handler(val, oldVal) {
                if (oldVal === undefined) return;
                const { index, Tab, $$header, $$headerInner } = this;
                if (val) {
                    Tab.floatAppendChild($$headerInner);
                    !Tab.isAnimate && (this.currentIndex = index);
                } else {
                    $$header.appendChild($$headerInner);
                }
            },
            immediate: true,
        },
    },
    created() {
        this.Tab.addPanel(this);
    },
    mounted() {
        this.isMounted = true;
    },
};
</script>

<style lang="scss">
.category-tab-panel {
    &.active {
        //color: $color-main;
    }
}
</style>
