<!-- Created by henian.xu on 2018/2/1. -->

<template>
    <div
        :class="[
            type==='textarea'?'textarea':'input'
        ]"
    >
        <input
            v-if="type !== 'textarea'"
            v-bind="$props"
            ref="input"
            :value="currentValue"
            @input="handleInput"
            @focus="handleFocus"
            @blur="handleBlur"
            @change="handleChange"
            v-on="listeners"
        >
        <textarea
            v-else
            v-bind="$props"
            ref="textarea"
            :value="currentValue"
            :style="textareaStyle"
            @input="handleInput"
            @focus="handleFocus"
            @blur="handleBlur"
            @change="handleChange"
            v-on="listeners"
        />

        <i
            class="f-icon tc-g6"
            v-if="clear && value"
            @click="onClear"
        >&#xf01a;</i>
    </div>
</template>

<script>
import { Comm } from '@/utils';
import emitter from '@/mixin/emitter';
import calcTextareaHeight from './calcTextareaHeight';

export default {
    name: 'XInput',
    mixins: [emitter],
    data() {
        return {
            currentValue: this.value,
            textareaCalcStyle: {},
        };
    },
    props: {
        value: {
            type: [String, Number],
            default: '',
        },
        placeholder: {
            type: String,
            default: '请输入...',
        },
        autocomplete: {
            type: String,
            default: '',
        },
        size: {
            type: String,
            default: '',
        },
        resize: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        form: {
            type: String,
            default: '',
        },
        id: {
            type: String,
            default() {
                return Comm.getUniqueId();
            },
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'text',
        },
        autoSize: {
            type: [Boolean, Object],
            default: false,
        },
        rows: {
            type: Number,
            default: 2,
        },
        clear: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        listeners() {
            const obj = {
                ...this.$listeners,
            };
            delete obj.input;
            return obj;
        },
        textareaStyle() {
            return {
                ...this.textareaCalcStyle,
                resize: this.resize,
            };
        },
    },
    watch: {
        value(val) {
            this.setCurrentValue(val);
        },
    },
    methods: {
        handleInput(event) {
            const value = event.target.value;
            this.$emit('input', value);
            this.setCurrentValue(value);
        },
        handleFocus() {},
        handleBlur() {
            this.dispatch('formItem', 'x.form.blur', [this.currentValue]);
        },
        handleChange() {},
        setCurrentValue(value) {
            if (value === this.currentValue) return;
            this.$nextTick(() => {
                this.resizeTextarea();
            });
            this.currentValue = value;
            this.dispatch('formItem', 'x.form.change', [value]);
        },
        resizeTextarea() {
            const { autoSize, type } = this;
            if (this.$isServer || type !== 'textarea') return;
            if (!autoSize) {
                this.textareaCalcStyle = {
                    minHeight: calcTextareaHeight(this.$refs.textarea).minHeight,
                };
                return;
            }
            const minRows = autoSize.minRows;
            const maxRows = autoSize.maxRows;

            this.textareaCalcStyle = calcTextareaHeight(this.$refs.textarea, minRows, maxRows);
        },

        onClear() {
            this.$emit('input', '');
            this.setCurrentValue('');
        },
    },
    mounted() {
        this.resizeTextarea();
    },
};
</script>

<style lang="scss">
</style>
