/**
 * Created by henian.xu on 2018/4/19.
 *
 */

import { Ex } from '@/api/buyer/Ex/Ex';

class Order extends Ex {
    constructor() {
        super();
        this.baseUrl += '/goodsReview';
    }

    /**
     * 是否已付款
     * @param paymentId
     * @returns {AxiosPromise}
     */
    saveGoodsReview({ jsonStr } = {}) {
        return super.post(`/saveGoodsReview`, {
            jsonStr,
        });
    }
    goodsReviewList({ goodsId, currentPage, pageSize } = {}) {
        return super.get(`/goodsReviewList`, {
            goodsId,
            currentPage,
            pageSize,
        });
    }
    buyerGoodsReviewList({ currentPage, pageSize } = {}) {
        return super.get(`/buyerGoodsReviewList`, {
            currentPage,
            pageSize,
        });
    }
    buyerGoodsReviewDetail({ id } = {}) {
        return super.get(`/buyerGoodsReviewDetail`, {
            id,
        });
    }
}

export default new Order();
export { Order };
