/**
 * Created by henian.xu on 2017/10/24.
 *
 */

import { Mb } from '@/api/buyer/Mb/Mb';

class BuyerBalance extends Mb {
    constructor() {
        super();
        this.baseUrl += '/buyerBalance';
    }

    withdraw({ amount } = {}) {
        return super.post(`/withdraw`, {
            amount,
        });
    }
    withdrawList({ currentPage, pageSize } = {}) {
        return super.get(`/withdrawList`, {
            currentPage,
            pageSize,
        });
    }
}

export default new BuyerBalance();
export { BuyerBalance };
