/**
 * Created by henian.xu on 2017/10/24.
 *
 */
import { DataMap, Comm } from '@/utils';
import { Mb } from '@/api/buyer/Mb/Mb';

class BuyerDeliveryAddress extends Mb {
    constructor() {
        super();
        this.baseUrl += '/buyerDeliveryAddress';
    }

    /**
     * 买家收货地址列表
     * @param uuid      为了取运费而存在
     * @param currPage
     * @param pageSize
     * @returns {Promise<any>}
     */
    data({ keywords, uuid, currPage, pageSize = 999 } = {}) {
        return super
            .get(`/data`, {
                keywords,
                uuid,
                currPage,
                pageSize,
            })
            .then(json => {
                const res = json.data.data;
                Comm.makeDataMap(res, DataMap.address);
                return Promise.resolve(json);
            });
    }

    /**
     * 买家收货地址详情
     * @param id
     * @returns {Promise<any>}
     */
    detail({ id } = {}) {
        return super
            .get(`/get`, {
                id,
            })
            .then(json => {
                const res = json.data.data;
                Comm.makeDataMap(res, DataMap.address);
                return Promise.resolve(json);
            });
    }

    /**
     * 批量删除买家收货地址
     * @param ids
     * @returns {AxiosPromise}
     */
    delete({ ids } = {}) {
        return super.post(`/delete`, { ids });
    }

    /**
     * 保存买家收货地址
     * @param id
     * @param isDefault
     * @param consignee
     * @param phone
     * @param regionId
     * @param address
     * @param postcode
     * @returns {AxiosPromise}
     */
    save({ id, isDefault, consignee, mobile, regionId, regionJson, address, postcode } = {}) {
        return super.post(`/save`, {
            id,
            isDefault,
            consignee,
            phone: mobile,
            regionId,
            regionJson,
            address,
            postcode,
        });
    }

    /**
     * 编辑买家收货地址身份信息
     * @param id
     * @param idCardNo
     * @returns {AxiosPromise<any>}
     */
    edit({ id, idCardNo } = {}) {
        return super.post(`/edit`, {
            id,
            idCardNo,
        });
    }

    /**
     * 设置默认收货地址
     * @param id
     * @returns {AxiosPromise}
     */
    setDefault({ id } = {}) {
        return super.post(`/setDefault`, { id });
    }

    /**
     * 获取附近收货地址
     * @param lat
     * @param lng
     * @returns {AxiosPromise}
     */
    findNearbyAddress({ lat, lng }) {
        return super
            .get(`/findNearbyAddress`, {
                lat,
                lng,
                _isHandleError: true,
            })
            .then(json => {
                const res = json.data.data || {};
                Comm.makeDataMap(res, DataMap.address);
                return Promise.resolve(json);
            });
    }

    cleanseAddress({ address }) {
        return super.get(`/cleanseAddress`, { address });
    }
}

export default new BuyerDeliveryAddress();
export { BuyerDeliveryAddress };
