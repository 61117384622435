<!-- Created by henian.xu on 2019/5/15. -->

<template>
    <div class="proxy-store-add-goods">
        <div
            class="out-show"
            @click="onSwitch"
        >
            <slot />
        </div>

        <transition :name="aniName">
            <Page
                ref="popup"
                v-show="popupShow"
            >
                <Container>
                    <XForm
                        ref="form"
                        :model="formModel"
                        :rules="formRules"
                    >
                        <FormItem prop="id">
                            <ProxyStoreEditGoodsItem
                                v-if="currentGoods"
                                :data="currentGoods.goods"
                            />
                        </FormItem>
                        <FormItem
                            v-if="currentGoods"
                            prop="goodsCatIds"
                        >
                            <Selector
                                :data="catList"
                                placeholder="请选择店铺分类,可以多选"
                                multiple
                                v-model="formModel.goodsCatIds"
                            />
                        </FormItem>
                        <FormItem
                            v-if="skuList"
                            prop="skuJson"
                        >
                            <ProxyStoreSkuEditor
                                :data="skuList"
                                v-model="formModel.skuJson"
                            />
                        </FormItem>
                        <FormItem
                            v-if="currentGoods"
                            prop="goodsStyleSource"
                        >
                            <Selector
                                tiling
                                :data="goodsStyleList"
                                v-model="formModel.goodsStyleSource"
                            />
                        </FormItem>
                        <FormItem
                            v-if="goodsMultiStyleList && formModel.goodsStyleSource === 2"
                            prop="goodsStyleId"
                        >
                            <ProxyStoreGoodsStylePicker
                                :data="goodsMultiStyleList"
                                v-model="formModel.goodsStyleId"
                            />
                        </FormItem>
                        <template v-else-if="formModel.goodsStyleSource === 3">
                            <FormItem
                                label="商品名称"
                                prop="goodsName"
                            >
                                <XInput v-model="formModel.goodsName" />
                            </FormItem>
                            <FormItem
                                label="商品副标题"
                                prop="goodsSubtitle"
                            >
                                <XInput v-model="formModel.goodsSubtitle" />
                            </FormItem>
                            <FormItem prop="goodsImage">
                                <div class="w-100per">
                                    <div>商品列表图</div>
                                    <Upload
                                        class="npa-a"
                                        :column="3"
                                        :max-files="6"
                                        v-model="formModel.goodsImage"
                                        action="/rpc/comm/rs/attachment/uploadGoodsImage"
                                    />
                                </div>
                            </FormItem>
                            <FormItem prop="goodsDetailImage">
                                <div class="w-100per">
                                    <div>商品列表图</div>
                                    <Upload
                                        :column="3"
                                        :max-files="12"
                                        v-model="formModel.goodsDetailImage"
                                        action="/rpc/comm/rs/attachment/uploadGoodsImage"
                                    />
                                </div>
                            </FormItem>
                        </template>
                        <!--<FormItem>
                            <Checkbox>是否启用</Checkbox>
                        </FormItem>-->
                    </XForm>
                </Container>
                <PageBar>
                    <Button
                        label="保存"
                        theme="main"
                        @click="()=>{onSubmit()}"
                    />
                </PageBar>
            </Page>
        </transition>
    </div>
</template>

<script>
import popupMixin from '@/mixin/popup';
const defaultFormModel = {
    id: '', //商品ID
    goodsCatIds: [], //商品类目ID 逗号分隔
    goodsStyleSource: '1', //素材类型 1默认风格 2商品风格库 3自定义风格
    goodsStyleId: '', //商品风格ID
    goodsName: '', //商品名称
    goodsSubtitle: '', //商品副标题
    goodsImage: [], //商品列表图
    goodsDetailImage: [], //商品详情图
    skuJson: '', //商品json {skuId:'123',addPrice:'123'}
};
// 这个组件目前是编辑商品
export default {
    name: 'ProxyStoreAddGoods',
    mixins: [popupMixin],
    data() {
        return {
            currentGoods: null,
            catList: [],
            formModel: {
                ...defaultFormModel,
            },
            formRules: {
                id: {
                    required: true,
                    message: '不能为空',
                },
                goodsCatIds: {
                    type: 'array',
                    min: 1,
                    required: true,
                    message: '不能为空',
                },
                goodsStyleSource: {
                    required: true,
                    message: '不能为空',
                },
                goodsStyleId: {
                    required: true,
                    message: '不能为空',
                },
                goodsName: {
                    required: true,
                    message: '不能为空',
                },
                goodsSubtitle: {
                    required: true,
                    message: '不能为空',
                },
                goodsImage: {
                    type: 'array',
                    min: 1,
                    required: true,
                    message: '不能为空',
                },
                goodsDetailImage: {
                    type: 'array',
                    min: 1,
                    required: true,
                    message: '不能为空',
                },
                skuJson: {
                    required: true,
                    message: '不能为空',
                },
            },
        };
    },
    props: {
        goodsId: {
            type: [String, Number],
            default: '',
        },
        initFormModel: {
            type: Object,
            default: null,
        },
    },
    computed: {
        goods() {
            return this.currentGoods && this.currentGoods.goods;
        },
        goodsMultiStyleList() {
            return this.currentGoods && this.currentGoods.goodsMultiStyleList;
        },
        skuList() {
            const { initFormModel, goods } = this;
            if (!goods) return null;
            let skuJsonMap = {};
            if (initFormModel) {
                skuJsonMap = JSON.parse(initFormModel.skuJson).reduce((p, c) => ((p[c.skuId] = c), p), {});
                return goods.skuList.map(item => {
                    item.addPrice = skuJsonMap[item.skuId] ? skuJsonMap[item.skuId].addPrice : 0;
                    return item;
                });
            }
            return goods.skuList;
        },
        goodsStyleList() {
            const arr = [{ id: 1, name: '默认风格' }, { id: 3, name: '自定义' }];
            const { goodsMultiStyleList } = this;
            if (goodsMultiStyleList && goodsMultiStyleList.length) {
                arr.splice(1, 0, { id: 2, name: '商品风格库' });
            }
            return arr;
        },
    },
    watch: {
        popupShow(val) {
            const { catList, goodsId, currentGoods, initFormModel } = this;
            if (!val) return;
            if (!catList || !catList.length) {
                this.getCatList();
            }
            if (!currentGoods && goodsId) {
                this.formModel.id = goodsId;
                this.getGoodsDetail(goodsId);
            } else if (initFormModel && currentGoods) {
                this.onGoodsUpdate(currentGoods);
            }
        },
    },
    methods: {
        getGoodsDetail(val) {
            if (!val) {
                this.currentGoods = null;
                return;
            }
            this.$nprogress.start();
            this.$api.Buyer.Sp.ShopStoreGoods.agentGoodsDetail(val)
                .then(json => this.onGoodsUpdate(json.data.data))
                .finally(() => this.$nprogress.done());
        },
        onGoodsUpdate(data) {
            const { goodsId, formModel, initFormModel } = this;
            if (goodsId) {
                const { goods, shopStoreGoods } = data;
                const goodsDetail = {
                    ...goods,
                    ...shopStoreGoods,
                };
                Object.keys(formModel).forEach(key => {
                    if (key === 'goodsImage' || key === 'goodsDetailImage') {
                        const item = goodsDetail[key];
                        const defaultImage = (item ? item.split(',') : []).reduce(
                            (p, c) => (p.push({ name: c, address: goodsDetail.basePathUrl + c }), p),
                            [],
                        );
                        if (initFormModel && initFormModel[key] && initFormModel[key].length) {
                            formModel[key] = initFormModel[key];
                        } else {
                            formModel[key] = defaultImage;
                        }
                    } else if (key === 'goodsCatIds') {
                        const defaultIds = goodsDetail[key] ? goodsDetail[key].split(',') : [];
                        if (initFormModel && initFormModel[key] && initFormModel[key].length) {
                            formModel[key] = initFormModel[key];
                        } else {
                            formModel[key] = defaultIds.map(item => +item || 0);
                        }
                        console.log(formModel[key]);
                    } else if (key === 'id') {
                        formModel[key] = goodsDetail.goodsId || formModel[key];
                    } else {
                        if (initFormModel) {
                            formModel[key] = initFormModel[key] || goodsDetail[key];
                        } else {
                            formModel[key] = goodsDetail[key];
                        }
                    }
                });
                console.log(formModel.skuJson);
            }
            this.currentGoods = data;
        },
        async onSubmit() {
            await this.$refs['form'].validate();
            const { formModel, initFormModel } = this;
            const data = {
                ...formModel,
            };
            let json;
            if (!initFormModel) {
                data.goodsImage = formModel.goodsImage.reduce((p, c) => (p.push(c.name), p), []).join(',');
                data.goodsDetailImage = formModel.goodsDetailImage.reduce((p, c) => (p.push(c.name), p), []).join(',');
                data.goodsCatIds = formModel.goodsCatIds.join(',');
                json = await this.$api.Buyer.Sp.ShopStoreGoods.saveAgentGoods(data);
                this.$emit('success');
            } else {
                this.$emit('success', data);
            }
            console.log(data);
            this.done().then(() => {
                json && this.$messageBox.tips(json.data.msg);
            });
            this.onReset();
        },
        onReset() {
            this.formModel = { ...defaultFormModel };
            this.currentGoods = null;
            const { form } = this.$refs;
            form && form.clearValidate();
        },
        getCatList() {
            this.$api.Buyer.Sp.ShopStoreGoodsCat.select().then(json => {
                const res = json.data.data || [];
                this.catList = res.reduce((p, c) => (p.push({ id: c.id, name: c.goodsCatName }), p), []);
            });
        },
    },
};
</script>

<style lang="scss">
.proxy-store-add-goods {
    flex: 1 1 1%;
    > .out-show {
        min-height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        > .button {
            flex: 1 1 1%;
        }
    }
}
</style>
