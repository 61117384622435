/**
 * Created by henian.xu on 2018/6/25.
 * 超链接混合器
 */

const urlReg = new RegExp(/[a-zA-z]+:\/\/[^\s]*/);

export default {
    data() {
        return {
            command: {},
        };
    },
    props: {
        to: {
            type: [String, Object],
            default: '',
            // required: true,
        },
        tag: {
            type: String,
            default: 'a',
        },
        exact: Boolean,
        append: Boolean,
        replace: Boolean,
        activeClass: String,
        exactActiveClass: String,
        event: {
            type: [String, Array],
            default: 'click',
        },
        disabled: Boolean,
        rootTo: Boolean,
    },
    computed: {
        isUrl() {
            return urlReg.test(this.to);
        },
        isCommand() {
            // api/couponGive/receiveCoupon
            const reg = /^COMM:\/\/([^\s]*)/;
            const { to } = this;
            let path = typeof to === 'object' ? to.path : to;
            if (!path) return false;
            path = path.match(reg);
            if (!path) return false;
            path = path[1];
            this.command = {
                comm: path.split('/')[0].toUpperCase(),
                options: this.$utils.Comm.getSarch(path),
            };
            return true;
        },
        linkerProps() {
            const { to, tag, exact, append, replace, activeClass, exactActiveClass, event, disabled } = this;
            if (!to || disabled) {
                return {};
            } else if (this.isCommand) {
                return {
                    is: 'div',
                };
            } else if (this.isUrl || this.rootTo) {
                return {
                    is: 'a',
                    href: to,
                };
            } else {
                return {
                    is: 'router-link',
                    to,
                    tag,
                    exact,
                    append,
                    replace,
                    activeClass,
                    exactActiveClass,
                    event,
                };
            }
        },
    },
    methods: {
        onCommand() {
            switch (this.command.comm) {
                case 'COUPONGIVE':
                    this.runCouponGive();
                    break;
            }
        },
        runCouponGive() {
            console.log(this.command);
            this.$api.Buyer.Mb.Coupon.receiveCoupon({
                couponCodes: this.command.options.couponCodes,
            }).then(json => {
                const result = json.data;
                if (!result.success) {
                    this.$messageBox.tips(result.msg, 3000);
                    return;
                }
                this.$messageBox
                    .confirm(result.msg, '领券成功', {
                        cancelBtnText: '继续逛逛',
                        confirmBtnText: '查看优惠券',
                    })
                    .then(() => {
                        this.$router.push('/buyer/couponList/1');
                    });
            });
        },
    },
};
