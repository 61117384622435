/**
 * Created by henian.xu on 2018/8/7.
 *
 */

import GlobalVar from 'globalVar';
import './geolocation.min';

let loading = false;
const _resolve = [];
window.tencentMapInit = () => {
    console.log('地图script加载完成');
    loading = false;
    _resolve.forEach(item => {
        item(window.qq.maps);
    });
};

export function loadMapScript() {
    return new Promise(resolve => {
        const qq = window.qq;
        if (!qq || !qq.maps || !qq.maps.Map) {
            _resolve.push(resolve);
            if (loading) return;
            loading = true;
            // const script2 = document.createElement('script');
            // script2.type = 'text/javascript';
            // script2.src = `https://apis.map.qq.com/tools/geolocation/min?key=${GlobalVar.mapKey}&referer=myapp`;
            // document.body.appendChild(script2);
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = `https://map.qq.com/api/js?v=2.exp&key=${
                GlobalVar.mapKey
            }&libraries=place&callback=tencentMapInit`;
            document.body.appendChild(script);
        } else {
            resolve(qq.maps);
        }
    });
}
