<!-- Created by henian.xu on 2018/5/2. -->

<template>
    <div
        class="spu-picker"
        v-if="isSpec"
    >
        <div
            class="spec"
            v-for="(spec) in specs"
            :key="spec.pid"
        >
            <div class="name">
                {{ spec.pn }}
            </div>
            <div class="skus">
                <div
                    :class="['item',{'active':spec.value === sku.zs}]"
                    v-for="(sku) in spec.v"
                    :key="sku.vid"
                    @click="onItem(sku,spec)"
                >
                    {{ sku.vn }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SpuPicker',
    data() {
        return {
            isSpec: 0,
            skuMap: {},
            specs: {},
        };
    },
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
        data: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    watch: {
        data: {
            handler(val) {
                this.isSpec = val.isSpec;
                if (+this.isSpec) {
                    this.skuMap = val.skuMap;
                    this.specs = val.specs.map(item => {
                        const { v = [] } = item;
                        const [sku = {}] = v;
                        item.value = sku.zs || 0;
                        sku.vimgUrl && this.$emit('spuImgChange', sku.vimgUrl);
                        return item;
                    });
                    this.specsValue();
                } else {
                    this.sku = val.sku || {};
                    this.$emit('input', this.sku);
                }
            },
            immediate: true,
        },
    },
    methods: {
        onItem(sku, spec) {
            spec.value = sku.zs;
            this.$forceUpdate(); // 因为数据层级太深需要强刷新
            sku.vimgUrl && this.$emit('spuImgChange', sku.vimgUrl);
            this.specsValue();
        },

        specsValue() {
            const zs = this.specs.reduce((prev, next) => {
                return prev * next.value;
            }, 1);
            this.$emit('input', this.skuMap[`sku_${zs}`] || {});
        },
    },
};
</script>

<style lang="scss">
.spu-picker {
    > .spec {
        > .name {
            padding: $padding-small/2 $padding-small;
            background-color: $gray1;
        }
        > .skus {
            padding: $padding-small 0;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            > .item {
                margin: $margin-small;
                padding: $padding-small $padding;
                border: 1px solid $color-border;
                border-radius: 5px;
                + .item {
                    margin-left: $margin-small;
                }
                &.active {
                    background-color: $color-danger;
                    color: #fff;
                    border-color: $color-danger;
                }
            }
        }
    }
}
</style>
