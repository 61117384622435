/**
 * Created by henian.xu on 2018/3/5.
 *
 */

export { default as XForm } from './form';
export { default as formItem } from './item';
export { default as inputNumber } from './inputNumber';
export { default as XInput } from './input';
export { default as XDate } from './date';
export { default as XSelect } from './select';
export { default as Selector } from './selector';
export { default as DicSelector } from './dicSelector';
export { default as CheckBox } from './checkBox';
export { default as Xswitch } from './switch';
export { default as RegionPicker } from './regionPicker';
export { default as AddressPicker } from './addressPicker';
export { default as ShopPicker } from './shopPicker';
export { default as checkBox } from './checkBox';
export { default as areaMapPicker } from './areaMapPicker';
