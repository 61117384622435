<!-- Created by henian.xu on 2018/8/8. -->

<template>
    <div class="store-add-cart">
        <!--<input-number
            noInput
            v-model="currValue"
        />-->
        <!--{{isSpec}}-->
        <button 
            :class="['minus',{'disabled':disabled||minusDisabled}]"
            @click.stop.prevent="onMinus"
        >
            <i class="f-icon">&#xf014;</i>
        </button>
        <div class="input">
            {{ currValue }}
        </div>
        <button 
            :class="['plus',{'disabled':disabled||plusDisabled}]"
            @click.stop.prevent="onPlus"
        >
            <i class="f-icon">&#xf013;</i>
        </button>

        <!--popup-->
        <transition
            name="show"
            v-if="!delayedCreate"
        >
            <div 
                class="popup store-add-cart-popup"
                @click.self="onSwitch"
                ref="popup"
                v-show="popupShow"
            >
                <transition name="popup-down">
                    <div 
                        class="inner"
                        v-show="popupShow"
                    >
                        <div class="header">
                            <div class="label">
                                请选择规格
                            </div>
                        </div>
                        <div class="body">
                            <spu-picker
                                :data="spu"
                                v-model="selectedSku"
                            />
                        </div>
                        <div class="footer">
                            <div 
                                class="btn btn-second"
                                @click="onSkuCancel"
                            >
                                取消
                            </div>
                            <div 
                                :class="['btn',skuConfirmAvailable?'btn-main':'btn-g6']"
                                @click="onSkuConfirm"
                            >
                                {{ currentSku.id?'选好了':'请选择 SKU' }}
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </transition>
    </div>
</template>

<script>
import popupMixin from '@/mixin/popup';
import storeMixin from './storeMixin';

export default {
    name: 'StoreAddCart',
    mixins: [popupMixin, storeMixin],
    data() {
        return {
            delayedCreate: true,
            appLevel: true,
            selectedSku: {},
        };
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        isSkuData: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        currValue() {
            const { data, isSkuData, cartGoodsMap, currentSku } = this;
            if (isSkuData) {
                return currentSku ? currentSku.quantity : 0;
            }
            const skus = cartGoodsMap[data.id] || [];
            return skus.reduce((prev, curr) => {
                return prev + curr.quantity;
            }, 0);
        },
        isSpec() {
            const { isSkuData, data } = this;
            return isSkuData ? false : !!data.isSpec;
        },
        spu() {
            const { isSpec, data } = this;
            return {
                isSpec,
                sku: data.sku,
                specs: data.specConfigs,
                skuMap: data.skuMap,
            };
        },
        skuConfirmAvailable() {
            const { currentSku, cartMap } = this;
            if (!currentSku.id) return false;
            const mapItem = cartMap[currentSku.id];
            if (!mapItem) return true;
            const quantity = mapItem.quantity;
            return quantity < currentSku.showStock;
        },
        currentSku() {
            const { isSpec, isSkuData, selectedSku, spu, cartMap, data } = this;
            if (isSkuData) {
                return cartMap[data.id];
            } else {
                return isSpec ? selectedSku : spu.sku;
            }
        },
        minusDisabled() {
            return this.currValue <= 0;
        },
        plusDisabled() {
            const { data, currValue, isSkuData, currentSku, cartGoodsMap } = this;
            let max = 0;
            if (isSkuData) {
                const { maxPerOrderQuantity, goodsId, showStock } = currentSku;
                max = showStock;
                if (typeof maxPerOrderQuantity === 'number' && +maxPerOrderQuantity) {
                    max = Math.min(max, maxPerOrderQuantity);
                }
                const skus = cartGoodsMap[goodsId] || [];
                const val = skus.reduce((prev, curr) => {
                    return prev + curr.quantity;
                }, 0);
                // console.log(max, goodsId, maxPerOrderQuantity);
                return val >= max;
            } else {
                const { maxPerOrderQuantity, showStock } = data;
                max = showStock;
                if (typeof maxPerOrderQuantity === 'number' && +maxPerOrderQuantity) {
                    max = Math.min(max, maxPerOrderQuantity);
                }
                return currValue >= max;
            }
        },
    },
    methods: {
        onMinus() {
            const { disabled, isSpec, currentSku, minusDisabled } = this;
            if (disabled || minusDisabled) return;
            if (isSpec) {
                this.$messageBox.tips('多规格商品只能去购物车删除哦');
            } else {
                this.storeRemoveCart(currentSku);
            }
        },
        onPlus() {
            const { disabled, isSpec, currentSku, plusDisabled, data } = this;
            if (disabled) return;
            if (plusDisabled) {
                this.$messageBox.tips('已超过最大购买数量');
                return;
            }
            if (isSpec) {
                this.onSwitch();
            } else {
                if (!currentSku || !currentSku.id) return;
                this.storeAddCart({
                    ...currentSku,
                    maxPerOrderQuantity: data.maxPerOrderQuantity,
                });
            }
        },
        onSkuCancel() {
            this.onSwitch();
        },
        onSkuConfirm() {
            const { currentSku, skuConfirmAvailable, data } = this;
            if (!skuConfirmAvailable) {
                if (!currentSku.id) {
                    this.$messageBox.tips('请选择 SKU');
                } else {
                    this.$messageBox.tips('当前 SKU 已超过最大购买数量');
                }
                return;
            }
            this.storeAddCart({
                ...currentSku,
                maxPerOrderQuantity: data.maxPerOrderQuantity,
            });
            this.onSwitch();
        },
    },
};
</script>

<style lang="scss">
.store-add-cart {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    > button {
        appearance: none;
        border: 1px solid $color-main;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 0.4rem;
        width: 0.4rem;
        border-radius: 50%;
        text-align: center;
        color: $color-main;
        outline: none;
        font-size: 0.24rem;
        &.disabled {
            border-color: $gray5;
            color: $gray5;
        }
        &.plus {
            background-color: $color-main;
            color: #fff;
            &.disabled {
                background-color: $gray5;
                border-color: $gray5;
            }
        }
    }
    > .input {
        appearance: none;
        display: block;
        padding: 0 $padding-small;
        text-align: center;
        font-size: 0.3rem;
        min-width: 1em;
        line-height: 0.4rem;
        outline: none;
    }
}

.store-add-cart-popup {
    justify-content: flex-end;
    align-items: stretch;
    > .inner {
        width: 100%;
        background-color: #fff;
    }
}
</style>
