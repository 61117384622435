<!-- Created by henian.xu on 2018/7/8. -->

<template>
    <div
        class="shop-item"
        v-on="$listeners"
    >
        <div class="img-obj">
            <div
                class="inner"
                :style="`background-image: url('${data.storeLogoUrl||$defaultImg}');`"
            />
        </div>
        <div class="inner">
            <div class="header">
                <div class="label">
                    <!--<div class="logo" :style="`background-image: url('${data.storeLogoUrl||$defaultImg}');`"></div>-->
                    <div class="name">
                        {{ data.storeName }}
                    </div>
                </div>
                <!--<div class="extra tc-blue"
                     @click.prevent.stop="onShowMap"
                >
                    {{data.distanceText}} <i class="f-icon">&#xf00a;</i>
                </div>-->
            </div>
            <div class="body">
                <!--<div class="freight">
                    <div v-if="+data.freightAmount===-1">
                        仅自取
                    </div>
                    <template
                        v-else
                    >
                        <div>
                            <span>{{ $globalVar.countryData.currency }}{{ data.startDeliverAmount }}起送</span>
                            <span>{{ $globalVar.countryData.currency }}{{ data.freeDeliverAmount }}免配送费</span>
                        </div>
                        <div>
                            <span>配送{{ $globalVar.countryData.currency }}{{ data.freightAmount }}</span>
                            <span>{{ data.planDeliverTime }}分钟</span>
                        </div>
                    </template>
                </div>-->
                <div class="tag-list">
                    <dl>
                        <dt><i class="f-icon tc-blue">&#xf026;</i></dt>
                        <dd>
                            <a
                                :href="`tel:${data.contactTel}`"
                                class="tc-blue"
                            >{{ data.contactTel }}</a>
                        </dd>
                    </dl>
                    <dl>
                        <dt><i class="f-icon">&#xf027;</i></dt>
                        <dd>{{ data.openTime }}</dd>
                    </dl>
                    <!--<dl>
                        <dt><i class="f-icon">&#xf00a;</i></dt>
                        <dd>{{data.storeDetailAddress}}<br>{{data.storeRegionName}}</dd>
                    </dl>-->
                </div>
            </div>
            <div class="footer">
                <div />
                <div
                    @click.prevent.stop="onShowMap"
                >
                    {{ data.storeRegionName }} {{ data.storeDetailAddress }}
                    <span class="tc-blue">{{ data.distanceText }} <i class="f-icon">&#xf00a;</i></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ShopItem',
    data() {
        return {};
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        isGoto: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onShowMap() {
            this.$wx.openLocation({
                latitude: +this.data.addressLatitude,
                longitude: +this.data.addressLongitude,
            });
        },
    },
};
</script>

<style lang="scss">
.shop-item {
    //border-top: 1px solid $color-border;
    padding: $padding/2 $padding;
    background-color: #fff;
    margin-bottom: $margin-big;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    &:first-child {
        border-top: 0;
    }

    > .img-obj {
        flex: 0 0 auto;
        width: 1rem;
        margin-right: $margin;
        > .inner {
            width: 100%;
            padding-top: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
    > .inner {
        flex: 1 1 1%;
        > .header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: stretch;
            .label {
                flex: 1 1 1%;
                font-size: 0.32rem;
                font-weight: bold;
            }
        }
        > .body {
            color: $gray7;
            //margin-top: $margin-small;

            .freight {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: stretch;
                flex-wrap: wrap;
                span + span {
                    border-left: 1px solid $color-border;
                    line-height: 1;
                    padding: 0 $padding-small;
                }
            }
            .tag-list {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                dl {
                    @include clearfix;
                    dt {
                        float: left;
                        /*text-align: justify;*/
                        /*width: 5em;*/
                        padding: 0 $padding-small/2;
                        height: $body-font-size * $line-height;
                        overflow: hidden;
                        &:after {
                            content: '';
                            display: inline-block;
                            padding-left: 100%;
                            height: 0;
                        }
                    }
                    dd {
                        overflow: hidden;
                    }
                    + dl {
                        margin-left: $margin;
                    }
                }
            }
        }
        > .footer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: $padding;

            > .f-icon {
                font-size: 0.3rem;
            }
        }
    }
}
</style>
