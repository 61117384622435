<!-- Created by henian.xu on 2018/5/29. -->

<template>
    <div
        class="refund-express-form-popup"
        @click.stop.prevent="onSwitch"
    >
        <slot />
        <!--popup-->
        <transition :name="aniName">
            <Page
                ref="popup"
                v-show="popupShow"
            >
                <div class="footer">
                    <div
                        class="btn btn-main"
                        @click="onSubmit"
                    >
                        提交
                    </div>
                </div>
                <container>
                    <x-form
                        ref="form"
                        :model="formData"
                        :rules="rules"
                    >
                        <form-item
                            class="ta-r"
                            prop="expressId"
                            label="物流公司"
                        >
                            <x-select
                                class="ta-r"
                                v-model="formData.expressId "
                            >
                                <option value="">
                                    请选择
                                </option>
                                <option
                                    v-for="(item) in expressList"
                                    :key="item.id"
                                    :value="item.expressId"
                                >
                                    {{ item.expressName }}
                                </option>
                            </x-select>
                        </form-item>
                        <form-item
                            class="ta-r"
                            prop="trackingNumber"
                            label="物流单号"
                        >
                            <x-input
                                placeholder="请输入"
                                v-model.number="formData.trackingNumber"
                            />
                        </form-item>
                    </x-form>
                </container>
            </Page>
        </transition>
    </div>
</template>

<script>
import popup from '@/mixin/popup';

export default {
    name: 'RefundExpressFormPopup',
    mixins: [popup],
    data() {
        return {
            expressList: [],
            formData: {
                id: '',
                expressId: '',
                trackingNumber: '',
            },
            rules: {
                expressId: {
                    required: true,
                    message: '请选择物流公司',
                },
                trackingNumber: {
                    required: true,
                    message: '物流单号不可为空',
                },
            },
        };
    },
    props: {
        id: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        $$form() {
            return this.$refs.form;
        },
    },
    methods: {
        onSubmit() {
            this.$$form.validate().then(() => {
                const data = {
                    ...this.formData,
                    id: this.id,
                };
                this.$api.Buyer.Ex.OrderRefund.orderRefundShipping(data).then(json => {
                    const res = json.data;
                    this.done({ isAni: false }).then(() => {
                        this.$emit('submitSuccess');
                        this.isAni = true;
                        this.$messageBox.tips(res.msg);
                    });
                });
            });
        },
    },
    mounted() {
        this.$api.Buyer.Ex.OrderRefund.shopExpressList().then(json => {
            const res = json.data.data;
            this.expressList = res;
        });
    },
};
</script>

<style lang="scss">
</style>
