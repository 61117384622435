/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import { Pm } from './Pm';

class Raffle extends Pm {
    constructor() {
        super();
        this.baseUrl += '/raffle';
    }
    /**
     * 抽奖码详情
     * dc145861c25346408c37dcf45a87b426
     * 6019c27a04a542b480a90f2425c40652
     * ac168e5372594c1fbde7d3e31a733343
     * @param raffleCode
     * @returns {Promise<any>}
     */
    raffleDetail({ raffleCode, _isHandleError } = {}) {
        return super.get(`/raffleDetail`, { raffleCode, _isHandleError });
    }
    raffleDraw({ raffleCode } = {}) {
        return super.post(`/raffleDraw`, { raffleCode });
    }
    saveDeliveryInfo({ raffleCode, fullName, mobile, address, idCardNo } = {}) {
        return super.post(`/saveDeliveryInfo`, {
            raffleCode,
            fullName,
            mobile,
            address,
            idCardNo,
        });
    }
    userRaffleDetailList({ pageSize, currentPage } = {}) {
        return super.get(`/userRaffleDetailList`, {
            pageSize,
            currentPage,
        });
    }
}

export default new Raffle();
export { Raffle };
