<!-- Created by henian.xu on 2018/9/13. -->

<template>
    <div
        v-if="+type === 2"
        class="ad-list"
    >
        <div
            class="item"
            :is="item.href?'Linker':'div'"
            :to="item.href"
            v-for="(item,index) in data"
            :key="item.id || index"
            :style="`margin: ${margin / 100}rem 0;`"
        >
            <img
                :src="item.src | imgCropping"
                :alt="item.label"
            >
        </div>
    </div>
    <swiper
        v-else-if="+type === 1 && data.length"
        class="ad-swiper"
        :options="_swiperOption"
    >
        <swiper-slide
            v-for="(item,index) in data"
            :key="item.id || index"
            :style="slideHeight?`height: ${slideHeight};`:''"
        >
            <div
                class="slide-inner"
                :is="item.href?'Linker':'div'"
                :to="item.href"
                :style="isImagesBackground?`background-image: url('${item.src||$defaultImg}');`:''"
            >
                <img
                    v-if="!isImagesBackground"
                    class="img"
                    :src="item.src||$defaultImg | imgCropping"
                    alt=""
                >
                <div
                    class="label"
                    v-if="item.label"
                >
                    {{ item.label }}
                </div>
            </div>
        </swiper-slide>
        <div
            class="swiper-pagination"
            slot="pagination"
        />
    </swiper>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper';
export default {
    name: 'Ad',
    components: {
        swiper,
        swiperSlide,
    },
    data() {
        return {};
    },
    props: {
        type: {
            type: [Number, String],
            default: '1',
        },
        margin: {
            type: [Number, String],
            default: 0,
        },
        delay: {
            type: [Number, String],
            default: 0,
        },
        data: {
            type: Array,
            default() {
                return [];
            },
        },
        // 只当 type = 1 时有效
        swiperOption: {
            type: Object,
            default() {
                return {
                    autoplay: {
                        delay: 3000,
                        disableOnInteraction: false,
                    },
                    loop: true,
                    pagination: {
                        el: '.swiper-pagination',
                    },
                };
            },
        },
        isImagesBackground: {
            type: Boolean,
            default: false,
        },
        slideHeight: {
            type: String,
            default: '',
        },
    },
    computed: {
        _swiperOption() {
            const { delay } = this;
            const swiperOption = { ...this.swiperOption };
            if (!delay) {
                delete swiperOption.autoplay;
                return swiperOption;
            }
            swiperOption.autoplay = swiperOption.autoplay || {};
            swiperOption.autoplay.delay = delay;
            return swiperOption;
        },
    },
};
</script>

<style lang="scss">
.ad-list {
    // margin: $margin 0;
    > .item {
        display: block;
        line-height: 0;
        > img {
            width: 100%;
            max-width: 100%;
            height: auto;
            line-height: 1.2;
        }
    }
}

.ad-swiper {
    .swiper-pagination {
        text-align: right;
        padding-right: $padding;
    }

    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 0;
    }

    .swiper-pagination-bullet {
        width: 0.2rem;
        height: 0.2rem;
        background: #fff;
    }

    .swiper-pagination-bullet-active {
        background: $color-main;
    }

    .swiper-slide {
        display: inline-block;
        position: relative;
        line-height: 0;
        .slide-inner {
            width: 100%;
            min-height: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            /*display: flex;
                text-align: center;*/
            > .img {
                /*position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    max-width: 100%;
                    max-height: 100%;
                    min-width: 100%;*/
                width: 100%;
                height: auto;
            }
            > .label {
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.8);
                color: #fff;
                line-height: $line-height;
                padding: $padding-small $padding;
                text-align: left;
            }
        }
    }
}
</style>
