/**
 * Created by henian.xu on 2018/1/22.
 *
 */

export default {
    state: {
        skuIds: [],
        shelvesCart: [],
    },
    getters: {
        shelvesCartGoodsMap(state) {
            const map = {};
            state.shelvesCart.forEach(item => {
                const catMap = (map[item.catId] = map[item.catId] || {});
                if (catMap[item.goodsId]) {
                    catMap[item.goodsId].quantity += item.quantity;
                    catMap[item.goodsId].spec = true;
                } else {
                    catMap[item.goodsId] = {};
                    catMap[item.goodsId].quantity = item.quantity;
                    catMap[item.goodsId].spec = false;
                }
            });
            return map;
        },
        shelvesCartNum(state) {
            const map = {};
            state.shelvesCart.forEach(item => {
                map[item.catId] = (map[item.catId] || 0) + item.quantity;
            });
            return map;
        },
        shelvesCartSkuMap(state) {
            const map = {};
            state.shelvesCart.forEach(item => {
                map[item.id] = item;
            });
            return map;
        },
        totalAmount(state) {
            let amount = 0;
            state.shelvesCart.forEach(item => {
                amount += +item.price * item.quantity;
            });
            return +amount.toFixed(2);
        },
        skuJson(state) {
            const json = [];
            state.shelvesCart.forEach(item => {
                json.push({
                    skuId: item.id,
                    quantity: item.quantity,
                });
            });
            return json;
        },
        shelvesCartLength(state) {
            let quantity = 0;
            state.shelvesCart.forEach(item => {
                quantity += item.quantity;
            });
            return quantity;
        },
    },
    mutations: {
        shelvesAddCart(state, data) {
            const index = state.skuIds.indexOf(data.id);
            if (index === -1) {
                state.shelvesCart.push({
                    ...data,
                    quantity: 1,
                });
                state.skuIds.push(data.id);
            } else {
                state.shelvesCart[index].quantity++;
            }
        },
        shelvesRemoveCart(state, data) {
            let item = null;
            const length = state.shelvesCart.length;
            for (let i = 0; i < length; i++) {
                item = state.shelvesCart[i];
                if (data.id === item.id) {
                    if (item.quantity > 1) {
                        item.quantity--;
                    } else {
                        state.shelvesCart.splice(i, 1);
                        state.skuIds.splice(i, 1);
                    }
                    break;
                }
            }
        },
        shelvesClearCart(state) {
            state.skuIds = [];
            state.shelvesCart = [];
        },
    },
};
