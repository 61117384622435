<!-- Created by henian.xu on 2018/8/23. -->

<template>
    <!--
    :class="{
            'blue':data.cardValue<50,
            'green':data.cardValue>=50&&data.cardValue<100,
            'yellow':data.cardValue>=100,
        }"
        -->
    <Linker
        class="prepaid-item yellow"
        :to="`/pocketMoney/prepaid/new/${data.id}`"
    >
        <div class="inner">
            <!--<div class="header">
                <div class="label">{{data.cardName}}</div>
            </div>-->
            <div class="body">
                <!--<div class="content">
                    <div class="worth">${{data.cardValue | price}}</div>
                    <div class="desc">{{data.cardDesc }}</div>
                </div>-->
                <div class="img-obj">
                    <div
                        class="inner"
                        :style="`background-image: url('${$options.filters.imgCropping(data.cardLogoUrl || $defaultImg)}');`"
                    />
                </div>
            </div>
            <div class="footer">
                <div class="price">
                    {{ data.cardValue | price }}
                </div>
                <div class="btn btn-main fs-small radius">
                    立即充值
                </div>
            </div>
        </div>
    </Linker>
</template>

<script>
export default {
    name: 'PrepaidItem',
    data() {
        return {};
    },
    props: {
        data: {
            type: Object,
            default() {
                return {};
            },
        },
    },
};
</script>

<style lang="scss">
.prepaid-item {
    flex: 0 0 auto;
    width: 100%;
    padding: $padding;
    display: block;
    > .inner {
        height: 100%;
        background-color: #fff;
        border-radius: 0.08rem;
        box-shadow: 0 2px 10px 2px rgba(60, 60, 60, 0.2);
        overflow: hidden;

        > .header {
            padding: $padding;
            background-color: mix($color-blue, #fff, 80%);
            color: #fff;
            > .label {
                @include text-line(1);
            }
        }
        > .body {
            //padding: $padding;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            > .content {
                > .worth {
                    font-size: 0.5rem;
                    font-weight: bold;
                    color: $color-blue;
                }
                > .desc {
                    color: $gray6;
                }
            }
            > .img-obj {
                width: 100%;
                > .inner {
                    width: 100%;
                    height: 0;
                    padding-top: 45%;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                }
            }
        }
        > .footer {
            padding: $padding;
            background-color: $gray1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }

    &.blue {
    }
    &.green {
        > .inner {
            > .header {
                //background-color: mix($color-main, #fff, 80%);
            }
            > .body {
                > .content {
                    > .worth {
                        color: $color-main;
                    }
                }
            }
        }
    }
    &.yellow {
        > .inner {
            > .header {
                //background-color: mix($color-second, #fff, 80%);
            }
            > .body {
                > .content {
                    > .worth {
                        color: $color-second;
                    }
                }
            }
        }
    }
}
</style>
