<!-- Created by henian.xu on 2018/7/8. -->
<!-- 确认订单页面选择配送方式组件 -->

<template>
    <div class="ship-method">
        <ship-method-header
            :ship-method="shipMethod"
            :goods-send-mode="goodsSendMode"
            v-model="shipType"
            :disabled="disabled"
        />
        <template
            v-if="+shipType === 1"
        >
            <AddressPicker
                ref="addressPicker"
                :uuid="uuid"
                v-model="address"
                :disabled="disabled || addressDisabled"
            />
        </template>
        <template
            v-else-if="+shipType === 2"
        >
            <shop-picker
                ref="shopPicker"
                v-model="shop"
                :disabled="disabled || shopDisabled"
            />
            <list v-if="shop.id">
                <form-item
                    class="bor-t"
                    label="取货日期: "
                >
                    <x-select
                        v-model="pickupDate"
                        :disabled="disabled"
                    >
                        <option value="">
                            请选择
                        </option>
                        <option
                            v-for="(item,index) in storeServiceDateList"
                            :key="index"
                            :value="item.storeServiceDate"
                        >
                            {{ item.storeServiceDate }}
                        </option>
                    </x-select>
                </form-item>
                <form-item
                    label="取货时间: "
                >
                    <x-select
                        v-model="storeServiceTimeId"
                        :disabled="disabled"
                    >
                        <option value="">
                            请选择
                        </option>
                        <option
                            v-for="(item) in storeServiceTimeList"
                            :key="item.id"
                            :value="item.id"
                        >
                            {{ item.timeName }}
                        </option>
                    </x-select>
                </form-item>
            </list>
        </template>
    </div>
</template>

<script>
import ShipMethodHeader from './_header';
import XSelect from '@/components/form/select';

export default {
    name: 'ShipMethod',
    components: { XSelect, ShipMethodHeader },
    data() {
        return {
            // shipType: '',
            // pickupDate: '',
            // storeServiceTimeId: '',
        };
    },
    props: {
        value: {
            type: Object,
            default() {
                return {};
            },
        },
        shipMethod: {
            type: [Number, String],
            default: 0,
        },
        goodsSendMode: {
            type: [Number, String],
            default: 1,
        },
        uuid: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        addressDisabled: {
            type: Boolean,
            default: false,
        },
        shopDisabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        $$addressPicker() {
            return this.$refs.addressPicker;
        },
        shipType: {
            get() {
                return this.value.shipType || '';
            },
            set(val) {
                this.$emit('input', {
                    ...this.value,
                    shipType: val,
                });
            },
        },
        pickupDate: {
            get() {
                return this.value.pickupDate || '';
            },
            set(val) {
                this.$emit('input', {
                    ...this.value,
                    pickupDate: val,
                    storeServiceTimeId: '',
                });
            },
        },
        storeServiceTimeId: {
            get() {
                return this.value.storeServiceTimeId || '';
            },
            set(val) {
                this.$emit('input', {
                    ...this.value,
                    storeServiceTimeId: val,
                });
            },
        },
        address: {
            get() {
                return this.value.address || {};
            },
            set(val) {
                this.$emit('input', {
                    ...this.value,
                    // shop: {},
                    address: val,
                });
            },
        },
        shop: {
            get() {
                return this.value.shop || {};
            },
            set(val) {
                this.$emit('input', {
                    ...this.value,
                    // address: {},
                    shop: val,
                });
            },
        },
        storeServiceDateList() {
            return this.shop.storeServiceDateList || [];
        },
        storeServiceDateMap() {
            const res = {};
            this.storeServiceDateList.forEach(item => {
                res[item.storeServiceDate] = item;
            });
            return res;
        },
        storeServiceTimeList() {
            const { pickupDate, storeServiceDateMap } = this;
            const mapItem = storeServiceDateMap[pickupDate] || {};
            return mapItem.storeServiceTimeList || [];
        },
    },
    methods: {},
};
</script>

<style lang="scss">
.ship-method {
    flex: 1 1 1%;
}
</style>
