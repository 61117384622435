/**
 * Created by henian.xu on 2017/11/10.
 *
 */

import Base from '../base';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

export default {
    mixins: [Base],
    data() {
        return {
            recoverData: {
                scrollHeight: 0,
                scrollTop: 0,
            },
            shareData: this.$globalVar.defaultShareData,
            shareResolve: null,
        };
    },
    computed: {
        ...mapGetters('user', ['userData', 'isDistributor', 'isSalesman', 'isLogin', 'distributionIncomeMode']),
        ...mapState('global', { shoeSize: 'shoeSize' }),
        ...mapGetters('global', {
            currency: 'currency',
            moduleConfig: 'moduleConfig',
        }),
        ...mapGetters('countInfo', {
            orderCount: 'orderCount',
            cartCount: 'cartCount',
            unAgentGoodsNum: 'unAgentGoodsNum',
        }),
        pageData() {
            return {
                title: this.$route.meta.title,
            };
        },
        $$container() {
            return this.$refs.container;
        },
        $$form() {
            return this.$refs.form;
        },
    },
    watch: {
        shareData: {
            handler(val) {
                this.$nextTick(() => {
                    const data = this.$wx.setShare(val, true);
                    if (this.shareResolve) {
                        this.shareResolve(data);
                        this.shareResolve = null;
                    }
                });
            },
            deep: true,
        },
    },
    methods: {
        ...mapMutations('user', { addIdentity: 'addIdentity', updateUserData: 'updateUserData' }),
        ...mapMutations('global', { setShoeSize: 'setShoeSize' }),
        ...mapMutations('store', { setStoreId: 'setStoreId' }),
        ...mapActions('store', { getShopStoreInfo: 'getShopStoreInfo' }),
        ...mapActions('countInfo', {
            updatedCountInfo: 'updated',
        }),
        setShareData(data) {
            return new Promise(resolve => {
                this.shareData = {
                    ...this.shareData,
                    ...data,
                    /* title: data.title,
                    desc: data.description,
                    imgUrl: data.imgUrl,
                    link: data.link,
                    successData: data.successData, */
                };
                this.shareResolve = resolve;
            });
        },
    },
    // 路由守卫
    beforeRouteEnter(to, from, next) {
        // 在渲染该组件的对应路由被 confirm 前调用
        // 不！能！获取组件实例 `this`
        // 因为当守卫执行前，组件实例还没被创建
        // next(vm => {});
        next();
    },
    beforeRouteUpdate(to, from, next) {
        // 在当前路由改变，但是该组件被复用时调用
        // 举例来说，对于一个带有动态参数的路径 /foo/:id，在 /foo/1 和 /foo/2 之间跳转的时候，
        // 由于会渲染同样的 Foo 组件，因此组件实例会被复用。而这个钩子就会在这个情况下被调用。
        // 可以访问组件实例 `this`
        next();
    },
    beforeRouteLeave(to, from, next) {
        // 导航离开该组件的对应路由时调用
        // 可以访问组件实例 `this`
        next();
    },
    // 生命周期
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    activated() {
        // 在这里设置默认分享
        this.$wx.setShare(this.shareData);
    },
    deactivated() {},
    beforeDestroy() {},
    destroyed() {},
    errorCaptured() {},
};
