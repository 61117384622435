<!-- Created by henian.xu on 2018/4/19. -->

<template>
    <div :class="['countdown',{'start':status===2}]">
        <div class="label">
            {{ status===1?'距开始':status===2?'离结束':'已结束' }}：
        </div>
        <div class="inner">
            <template>
                <countdown-item
                    v-model="countdown.d"
                />
                <span>天</span>
            </template>
            <template>
                <countdown-item
                    v-model="countdown.h"
                />
                <span>时</span>
            </template>
            <template>
                <countdown-item
                    v-model="countdown.m"
                />
                <span>分</span>
            </template>
            <template>
                <countdown-item
                    v-model="countdown.s"
                />
                <span>秒</span>
            </template>
        </div>
    </div>
</template>

<script>
import countdownItem from './countdownItem';

export default {
    name: 'Countdown',
    components: { countdownItem },
    data() {
        return {
            countdown: {},
            countdownId: 0,
            nowDate: 0,
        };
    },
    props: {
        value: {
            type: Object,
            default() {},
        },
        startTimestamps: {
            type: Number,
            default: 0,
        },
        endTimestamps: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        status() {
            const now = this.nowDate;
            // 1：未开始; 2：进行中; 3：已结束
            if (now < this.startTimestamps) {
                return 1;
            } else if (now >= this.startTimestamps && now < this.endTimestamps) {
                return 2;
            } else {
                return 3;
            }
        },
    },
    watch: {
        status: {
            handler(val) {
                if (+val === 3) {
                    clearInterval(this.countdownId);
                }
                this.$emit('input', {
                    status: val,
                    countdown: this.countdown,
                });
            },
            immediate: true,
        },
        countdown(val) {
            this.$emit('input', {
                status: this.status,
                countdown: val,
            });
        },
    },
    methods: {
        getCountdown() {
            this.nowDate = new Date().getTime();
            this.countdown = this.$utils.Comm.getRemainTime(
                this.nowDate,
                this.status === 1 ? this.startTimestamps : this.endTimestamps,
            );
        },
        setSurplusTime() {
            this.getCountdown();
            this.countdownId = setInterval(() => {
                this.getCountdown();
            }, 1000);
        },
    },
    mounted() {
        this.setSurplusTime();
    },
    beforeDestroy() {
        clearInterval(this.countdownId);
    },
};
</script>

<style lang="scss">
.countdown {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    font-size: 0.22rem;
    > .label {
    }
    > .inner {
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        > span {
            margin: 0 $margin-small/4;
        }
    }

    &.start {
        > .inner {
            > .countdown-item {
                background-color: $color-red;
            }
        }
    }
}
</style>
