<!-- Created by henian.xu on 2018/4/28. -->

<template>
    <div
        :is="!!to?'linker':'div'"
        :to="to"
        class="menu-item"
    >
        <div class="label">
            {{ label }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'MenuItem',
    data() {
        return {};
    },
    props: {
        label: {
            type: String,
            default: '',
        },
        to: {
            type: String,
            default: '',
        },
    },
    mounted() {
        this.$parent.addItem(this);
    },
};
</script>

<style lang="scss">
.menu-item {
    display: inline-block;
    padding: $padding;
    border-top: 1px solid $color-border;
    width: 100%;
    &:first-child {
        border-top: 0;
    }
    > .label {
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 6em;
    }
}
</style>
