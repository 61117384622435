import Vue from 'vue';
import Router from 'vue-router';
import Store from '@/store';
import GlobalVar from 'globalVar';
import Nprogress from 'nprogress';
import MeiQiaControl from '@/store/meiQia/MeiQiaControl';

Vue.use(Router);

const routerInstance = new Router({
    linkActiveClass: 'active',
    linkExactActiveClass: 'exact-active',
    routes: [
        {
            path: '/',
            redirect: '/index',
        },
        {
            path: '/index/:id?',
            name: 'Index',
            component: () => import(/* webpackChunkName: "buyer" */ '@/pages/decoration/index.vue'),
            meta: {
                title: '',
                footerShow: 1,
            },
        },
        // 与项目无关
        ...[
            {
                path: '/tpl',
                name: 'TPL',
                meta: {
                    title: '页面模板',
                },
                component: () => import(/* webpackChunkName: "demo" */ '@/pages/template.vue'),
            },
        ],
        // 买家账号相关 (绑定)
        ...[
            {
                path: '/buyer/login',
                name: 'login',
                meta: {
                    title: '登录',
                },
                component: () => import(/* webpackChunkName: "buyer" */ '@/pages/buyer/login.vue'),
            },
            {
                path: '/buyer/changePassword',
                name: 'changePassword',
                meta: {
                    title: '修改密码',
                },
                component: () => import(/* webpackChunkName: "buyer" */ '@/pages/buyer/changePassword.vue'),
            },
            {
                path: '/buyer/register',
                name: 'register',
                meta: {
                    title: '注册新用户',
                },
                component: () => import(/* webpackChunkName: "buyer" */ '@/pages/buyer/register.vue'),
            },
            {
                path: '/buyer/forgotPassword',
                name: 'forgotPassword',
                meta: {
                    title: '忘记密码',
                },
                component: () => import(/* webpackChunkName: "buyer" */ '@/pages/buyer/forgotPassword.vue'),
            },
            {
                path: '/buyer/userInfo',
                name: 'buyer-userInfo',
                meta: {
                    title: '修改用户信息',
                },
                component: () => import(/* webpackChunkName: "buyer" */ '@/pages/buyer/userInfo.vue'),
            },
            {
                path: '/buyer/home',
                name: 'home',
                meta: {
                    title: '个人中心',
                    footerShow: 1,
                },
                component: () => import(/* webpackChunkName: "buyer" */ '@/pages/buyer/home.vue'),
            },
            {
                path: '/buyer/bonus',
                name: 'home',
                meta: {
                    title: '会员福利',
                    footerShow: 1,
                },
                component: () => import(/* webpackChunkName: "buyer" */ '@/pages/buyer/home.vue'),
            },
            {
                path: '/buyer/pointList',
                name: 'buyerPointList',
                meta: {
                    title: '积分列表',
                },
                component: () => import(/* webpackChunkName: "buyer" */ '@/pages/buyer/pointList.vue'),
            },
            {
                path: '/buyer/growthList',
                name: 'buyerGrowthList',
                meta: {
                    title: '成长值日志',
                },
                component: () => import(/* webpackChunkName: "buyer" */ '@/pages/buyer/growthList.vue'),
            },
            {
                path: '/buyer/couponList/:type',
                name: 'buyerCouponList',
                meta: {
                    title: '优惠卷列表',
                },
                component: () => import(/* webpackChunkName: "buyer" */ '@/pages/buyer/couponList.vue'),
            },
            {
                path: '/buyer/giftCardList/:type',
                name: 'buyerGiftCardListList',
                meta: {
                    title: '礼品卡列表',
                },
                component: () => import(/* webpackChunkName: "buyer" */ '@/pages/buyer/giftCardList.vue'),
            },
            {
                path: '/buyer/collect/',
                name: 'buyerCollect',
                meta: {
                    title: '我的收藏',
                },
                component: () => import(/* webpackChunkName: "buyer" */ '@/pages/buyer/collect.vue'),
            },
            {
                path: '/buyer/footprint/',
                name: 'buyerFootprint',
                meta: {
                    title: '我的足迹',
                },
                component: () => import(/* webpackChunkName: "buyer" */ '@/pages/buyer/footprint.vue'),
            },
            {
                path: '/afterSalesNotice',
                name: 'afterSalesNotice',
                meta: {
                    title: '售后须知',
                },
                component: () => import(/* webpackChunkName: "buyer" */ '@/pages/buyer/afterSalesNotice.vue'),
            },
            {
                path: '/buyer/offlinePaymentList',
                name: 'buyerOfflinePaymentList',
                meta: {
                    title: '线下付款列表',
                },
                component: () => import(/* webpackChunkName: "buyer" */ '@/pages/buyer/offlinePaymentList.vue'),
            },
            {
                path: '/buyer/income',
                name: 'buyerIncome',
                meta: {
                    title: '总收入',
                },
                component: () => import(/* webpackChunkName: "buyer" */ '@/pages/buyer/income.vue'),
            },
        ],
        // 零钱
        ...[
            {
                path: '/pocketMoney/index',
                name: 'pocketMoney-index',
                component: () => import(/* webpackChunkName: "pocketMoney" */ '@/pages/pocketMoney/index.vue'),
                meta: {
                    title: '零钱',
                },
            },
            {
                path: '/pocketMoney/prepaid',
                name: 'pocketMoney-prepaid',
                component: () => import(/* webpackChunkName: "pocketMoney" */ '@/pages/pocketMoney/prepaid.vue'),
                meta: {
                    title: '充值',
                },
            },
            {
                path: '/pocketMoney/prepaid/new/:cardId',
                name: 'pocketMoney-prepaid-new',
                component: () => import(/* webpackChunkName: "pocketMoney" */ '@/pages/pocketMoney/prepaidNew.vue'),
                meta: {
                    title: '确认充值',
                },
            },
            {
                path: '/pocketMoney/withdraw',
                name: 'pocketMoney-withdraw',
                component: () => import(/* webpackChunkName: "pocketMoney" */ '@/pages/pocketMoney/withdraw.vue'),
                meta: {
                    title: '确认充值',
                },
            },
        ],
        // 支付页面 strip
        ...[
            {
                path: '/pay/stripe/:module/:orderIds',
                name: 'pay-stripe',
                component: () => import(/* webpackChunkName: "pay" */ '@/pages/pay/stripe.vue'),
                meta: {
                    title: '支付页面',
                },
            },
            {
                path: '/pay/payResult/:type/:paymentSn',
                name: 'pay-payResult',
                component: () => import(/* webpackChunkName: "pay" */ '@/pages/pay/payResult.vue'),
                meta: {
                    title: '支付页面',
                },
            },
            {
                path: '/pay/checkoutCounter/:paymentType/:action/:orderIds/:module',
                name: 'pay-checkoutCounter',
                component: () => import(/* webpackChunkName: "pay" */ '@/pages/pay/checkoutCounter.vue'),
                meta: {
                    title: '支付页面',
                },
            },
        ],
        // 分销商
        ...[
            {
                path: '/distributor/home',
                name: 'distributorHome',
                meta: {
                    title: '分销商个人中心',
                    footerShow: 1,
                },
                component: () => import(/* webpackChunkName: "distributor" */ '@/pages/distributor/home.vue'),
            },
            {
                path: '/distributor/goods/list/:activityType/:catId?',
                name: 'distributorGoodsList',
                meta: {
                    title: '商品列表',
                    footerShow: 1,
                },
                component: () => import(/* webpackChunkName: "distributor" */ '@/pages/distributor/goods/list.vue'),
            },
            {
                path: '/distributor/goods/detail/:id',
                name: 'distributorGoodsDetail',
                meta: {
                    title: '商品详情',
                    footerShow: 0,
                },
                component: () => import(/* webpackChunkName: "distributor" */ '@/pages/distributor/goods/detail.vue'),
            },
            {
                path: '/distributor/goods/shareEarn/:id',
                name: 'distributorGoodsShareEarn',
                meta: {
                    title: '商品详情',
                    footerShow: 0,
                },
                component: () =>
                    import(/* webpackChunkName: "distributor" */ '@/pages/distributor/goods/shareEarn.vue'),
            },
            // -----------------------------------
            {
                path: '/distributor/monthlyBill',
                name: 'distributor/monthlyBill',
                component: () => import(/* webpackChunkName: "distributor" */ '@/pages/distributor/monthlyBill.vue'),
                meta: {
                    title: '月度账单',
                },
            },
            {
                path: '/distributor/orderList/:orderStatus',
                name: 'distributor/orderList',
                component: () => import(/* webpackChunkName: "distributor" */ '@/pages/distributor/orderList.vue'),
                meta: {
                    title: '分享订单',
                },
            },
            {
                path: '/distributor/orderSurvey',
                name: 'distributor/orderSurvey',
                component: () => import(/* webpackChunkName: "distributor" */ '@/pages/distributor/orderSurvey.vue'),
                meta: {
                    title: '订单概况',
                },
            },
            {
                path: '/distributor/ranking',
                name: 'distributor/ranking',
                component: () => import(/* webpackChunkName: "distributor" */ '@/pages/distributor/ranking.vue'),
                meta: {
                    title: '排行榜',
                },
            },
            {
                path: '/distributor/register',
                name: '/distributor/register',
                component: () => import(/* webpackChunkName: "distributor" */ '@/pages/distributor/register.vue'),
                meta: {
                    title: '成为分销商',
                },
            },
            {
                path: '/distributor/contact',
                name: '/distributor/contact',
                component: () => import(/* webpackChunkName: "distributor" */ '@/pages/distributor/contact.vue'),
                meta: {
                    title: '联系公司',
                },
            },
            {
                path: '/distributor/orderImport',
                name: '/distributor/orderImport',
                component: () => import(/* webpackChunkName: "distributor" */ '@/pages/distributor/orderImport.vue'),
                meta: {
                    title: '批量上传订单',
                },
            },
            {
                path: '/distributor/salesman/:sign',
                name: '/distributor/salesman',
                component: () => import(/* webpackChunkName: "distributor" */ '@/pages/distributor/salesman.vue'),
                meta: {
                    title: '业务员销售统计',
                    secondHeaderShow: 1,
                },
            },
            {
                path: '/distributor/visitLogDetail',
                name: '/distributor/visitLogDetail',
                component: () => import(/* webpackChunkName: "distributor" */ '@/pages/distributor/visitLogDetail.vue'),
                meta: {
                    title: '流量统计',
                },
            },
            {
                path: '/distributor/platformRegister',
                name: '/distributor/platformRegister',
                component: () =>
                    import(/* webpackChunkName: "distributor" */ '@/pages/distributor/platformRegister.vue'),
                meta: {
                    title: '分销商申请提示',
                },
            },
            {
                path: '/distributor/couponStat',
                name: '/distributor/couponStat',
                component: () => import(/* webpackChunkName: "distributor" */ '@/pages/distributor/couponStat.vue'),
                meta: {
                    title: '优惠券活动统计',
                },
            },
            {
                path: '/distributor/registerStore',
                name: '/distributor/registerStore',
                component: () => import(/* webpackChunkName: "distributor" */ '@/pages/distributor/registerStore.vue'),
                meta: {
                    title: '开店缴费',
                },
            },
            // 等级卡
            ...[
                {
                    path: '/distributor/cardList',
                    name: 'distributor-cardList',
                    component: () => import(/* webpackChunkName: "distributor" */ '@/pages/distributor/cardList.vue'),
                    meta: {
                        title: '分销商会员卡列表',
                    },
                },
                {
                    path: '/distributor/cardDetail/:distributorLevelId',
                    name: 'distributor-cardDetail',
                    component: () => import(/* webpackChunkName: "distributor" */ '@/pages/distributor/cardDetail.vue'),
                    meta: {
                        title: '分销商会员卡详情',
                    },
                },
                {
                    path: '/distributor/buyCard/:distributorLevelId',
                    name: 'distributor-buyCard',
                    component: () => import(/* webpackChunkName: "distributor" */ '@/pages/distributor/buyCard.vue'),
                    meta: {
                        title: '分销商会员卡购买',
                    },
                },
            ],
            // 团队
            ...[
                {
                    path: '/distributor/team/list',
                    name: '/distributor/team/list',
                    component: () => import(/* webpackChunkName: "distributor" */ '@/pages/distributor/team/list.vue'),
                    meta: {
                        title: '我的团队',
                    },
                },
                {
                    path: '/distributor/team/log',
                    name: '/distributor/team/log',
                    component: () => import(/* webpackChunkName: "distributor" */ '@/pages/distributor/team/log.vue'),
                    meta: {
                        title: '团队日志',
                    },
                },
            ],
            // 提成
            ...[
                {
                    path: '/distributor/percentage/:sign',
                    name: '/distributor/percentage',
                    component: () =>
                        import(/* webpackChunkName: "distributor" */ '@/pages/distributor/percentage/index.vue'),
                    meta: {
                        title: '提成明细',
                    },
                },
                {
                    path: '/distributor/collect',
                    name: '/distributor/collect',
                    component: () =>
                        import(/* webpackChunkName: "distributor" */ '@/pages/distributor/percentage/collect.vue'),
                    meta: {
                        title: '我的提成',
                    },
                },
                {
                    path: '/distributor/monthList/:sign',
                    name: '/distributor/monthList/:sign',
                    component: () =>
                        import(/* webpackChunkName: "distributor" */ '@/pages/distributor/percentage/monthList.vue'),
                    meta: {
                        title: '提成详情',
                    },
                },
                {
                    path: '/distributor/withdraw',
                    name: '/distributor/withdraw',
                    component: () =>
                        import(/* webpackChunkName: "distributor" */ '@/pages/distributor/percentage/withdraw.vue'),
                    meta: {
                        title: '提取记录',
                    },
                },
                {
                    path: '/distributor/bestow',
                    name: '/distributor/bestow',
                    component: () =>
                        import(/* webpackChunkName: "distributor" */ '@/pages/distributor/percentage/bestow.vue'),
                    meta: {
                        title: '优惠码',
                    },
                },
            ],
        ],
        // 商品
        ...[
            {
                path: '/goods/category/:activityType',
                name: 'goods/category',
                meta: {
                    title: '分类列表',
                    footerShow: 1,
                },
                component: () => import(/* webpackChunkName: "goods" */ '@/pages/goods/category.vue'),
            },
            {
                path: '/goods/list/:activityType/:catId?',
                name: 'goods/list',
                meta: {
                    title: '商品列表',
                    footerShow: 1,
                },
                component: () => import(/* webpackChunkName: "goods" */ '@/pages/goods/list.vue'),
            },
            {
                path: '/goods/search/:keyword?',
                name: 'goods/search',
                meta: {
                    title: '商品搜索',
                    footerShow: 1,
                },
                component: () => import(/* webpackChunkName: "goods" */ '@/pages/goods/search.vue'),
            },
            {
                path: '/goods/detail/:id',
                name: 'goods/detail',
                meta: {
                    title: '商品详情',
                    footerShow: 0,
                },
                component: () => import(/* webpackChunkName: "goods" */ '@/pages/goods/detail.vue'),
            },
            {
                path: '/goods/review/:goodsId',
                name: 'goods/review',
                meta: {
                    title: '商品评价',
                    footerShow: 0,
                },
                component: () => import(/* webpackChunkName: "goods" */ '@/pages/goods/review.vue'),
            },
            {
                path: '/goods/groupBuy/:orderId',
                name: 'goods/groupBuy',
                meta: {
                    title: '拼团详情',
                    footerShow: 0,
                },
                component: () => import(/* webpackChunkName: "goods" */ '@/pages/goods/groupBuy/index.vue'),
            },
            // 活动
            ...[],
        ],
        // 购物车
        ...[
            {
                path: '/cart',
                redirect: '/cart/index',
            },
            {
                path: '/cart/index',
                name: 'cart',
                meta: {
                    title: '购物车',
                    footerShow: 1,
                },
                component: () => import(/* webpackChunkName: "cart" */ '@/pages/cart/index.vue'),
            },
        ],
        // 订单
        ...[
            {
                path: '/order/new/:uuid',
                name: 'order/new',
                meta: {
                    title: '确认订单页',
                    // footerShow: 1,
                },
                component: () => import(/* webpackChunkName: "order" */ '@/pages/order/new.vue'),
            },
            {
                path: '/order/list/:orderStatus?',
                name: 'order/list',
                meta: {
                    title: '订单列表',
                },
                component: () => import(/* webpackChunkName: "order" */ '@/pages/order/list.vue'),
            },
            {
                path: '/order/detail/:id',
                name: 'order/detail',
                meta: {
                    title: '订单详情',
                },
                component: () => import(/* webpackChunkName: "order" */ '@/pages/order/detail.vue'),
            },
            {
                path: '/order/offlinePay/:module/:orderIds',
                name: 'order/offlinePay',
                meta: {
                    title: '线下支付',
                },
                component: () => import(/* webpackChunkName: "order" */ '@/pages/order/offlinePay.vue'),
            },
            {
                path: '/order/offlineDetail/:orderId',
                name: 'order/offlineDetail',
                meta: {
                    title: '付款详情',
                },
                component: () => import(/* webpackChunkName: "order" */ '@/pages/order/offlineDetail.vue'),
            },
            {
                path: '/order/tracking/:orderSn',
                name: 'order/tracking',
                meta: {
                    title: '物流信息',
                    quickNav: 0,
                },
                component: () => import(/* webpackChunkName: "order" */ '@/pages/order/tracking.vue'),
            },
            {
                path: '/order/trackingDetail/:orderSn/:trackingNumber',
                name: 'order/trackingDetail',
                meta: {
                    title: '物流详情',
                    quickNav: 0,
                },
                component: () => import(/* webpackChunkName: "order" */ '@/pages/order/trackingDetail.vue'),
            },
        ],
        // 评价
        ...[
            {
                path: '/review/list/:reviewStatus',
                name: 'review/list',
                meta: {
                    title: '评价中心',
                    footerShow: 0,
                },
                component: () => import(/* webpackChunkName: "goods" */ '@/pages/review/list.vue'),
            },
            {
                path: '/review/detail/:reviewId',
                name: 'review/detail',
                meta: {
                    title: '评价详情',
                    footerShow: 0,
                },
                component: () => import(/* webpackChunkName: "goods" */ '@/pages/review/detail.vue'),
            },
            {
                path: '/review/new/:orderId',
                name: 'review/new',
                meta: {
                    title: '创建评价',
                    footerShow: 0,
                },
                component: () => import(/* webpackChunkName: "goods" */ '@/pages/review/new.vue'),
            },
        ],
        // 售后
        ...[
            {
                path: '/refund/list',
                name: 'refund/list',
                meta: {
                    title: '订单售后列表',
                },
                component: () => import(/* webpackChunkName: "refund" */ '@/pages/refund/list.vue'),
            },
            {
                path: '/refund/detail/:id',
                name: 'refund/detail',
                meta: {
                    title: '售后详情',
                },
                component: () => import(/* webpackChunkName: "refund" */ '@/pages/refund/detail.vue'),
            },
        ],
        // 门店
        ...[
            // 自由商品(平台或自己的商品)
            ...[
                {
                    path: '/store/:storeUserName/home',
                    name: 'store/home',
                    meta: {
                        title: '门店首页',
                    },
                    component: () => import(/* webpackChunkName: "store" */ '@/pages/store/home.vue'),
                },
                {
                    path: '/store/:storeUserName/edit',
                    name: 'store/edit',
                    meta: {
                        title: '门店编辑',
                    },
                    component: () => import(/* webpackChunkName: "store" */ '@/pages/store/edit.vue'),
                },
                {
                    path: '/store/:storeUserName/goods',
                    name: 'store/goods',
                    meta: {
                        title: '商品管理',
                    },
                    component: () => import(/* webpackChunkName: "store" */ '@/pages/store/goods.vue'),
                },
                {
                    path: '/store/:storeUserName/addGoods/:goodsId?',
                    name: 'store/addGoods',
                    meta: {
                        title: '发布商品',
                    },
                    component: () => import(/* webpackChunkName: "store" */ '@/pages/store/addGoods.vue'),
                },
                {
                    path: '/store/:storeUserName/serviceStation',
                    name: 'store/serviceStation',
                    meta: {
                        title: '自取点管理',
                    },
                    component: () => import(/* webpackChunkName: "store" */ '@/pages/store/serviceStation.vue'),
                },
                {
                    path: '/store/:storeUserName/addServiceStation/:id?',
                    name: 'store/addServiceStation',
                    meta: {
                        title: '新增自取点',
                    },
                    component: () => import(/* webpackChunkName: "store" */ '@/pages/store/addServiceStation.vue'),
                },
                {
                    path: '/store/:storeUserName/activity',
                    name: 'store/activity',
                    meta: {
                        title: '活动管理',
                    },
                    component: () => import(/* webpackChunkName: "store" */ '@/pages/store/activity.vue'),
                },
                {
                    path: '/store/:storeUserName/managementActivity/:id',
                    name: 'store/managementActivity',
                    meta: {
                        title: '管理活动',
                    },
                    component: () => import(/* webpackChunkName: "store" */ '@/pages/store/managementActivity.vue'),
                },
                {
                    path: '/store/:storeUserName/addActivity/:id?',
                    name: 'store/addActivity',
                    meta: {
                        title: '新增活动',
                    },
                    component: () => import(/* webpackChunkName: "store" */ '@/pages/store/addActivity.vue'),
                },
                {
                    path: '/store/:storeUserName/orderList/:activityId/:shipMethod?',
                    name: 'store/orderList',
                    meta: {
                        title: '订单列表',
                    },
                    component: () => import(/* webpackChunkName: "store" */ '@/pages/store/orderList.vue'),
                },
                {
                    path: '/store/:storeUserName/goodsStat/:shipMethod?',
                    name: 'store/goodsStat',
                    meta: {
                        title: '商品统计',
                    },
                    component: () => import(/* webpackChunkName: "store" */ '@/pages/store/goodsStat.vue'),
                },
                {
                    path: '/store/:storeUserName/goodsSaleStat/:type?',
                    name: 'store/goodsSaleStat',
                    meta: {
                        title: '商品销量统计',
                    },
                    component: () => import(/* webpackChunkName: "store" */ '@/pages/store/goodsSaleStat.vue'),
                },
                {
                    path: '/store/:storeUserName/saleStat/:type?',
                    name: 'store/saleStat',
                    meta: {
                        title: '销量统计',
                    },
                    component: () => import(/* webpackChunkName: "store" */ '@/pages/store/saleStat.vue'),
                },
                {
                    path: '/store/:storeUserName/buyerStat',
                    name: 'store/buyerStat',
                    meta: {
                        title: '我的客户',
                    },
                    component: () => import(/* webpackChunkName: "store" */ '@/pages/store/buyerStat.vue'),
                },
                {
                    path: '/store/:storeUserName/deliveryOrder/:shipMethod/:orderStatus?',
                    name: 'store/deliveryOrder',
                    meta: {
                        title: '配送订单',
                    },
                    component: () => import(/* webpackChunkName: "store" */ '@/pages/store/deliveryOrder.vue'),
                },
            ],
            // 一键代发(平台商品)
            ...[
                {
                    path: '/proxyStore/:storeUserName/home',
                    name: 'proxyStore/home',
                    meta: {
                        title: '店铺管理',
                    },
                    component: () => import(/* webpackChunkName: "proxyStore" */ '@/pages/proxyStore/home.vue'),
                },
                {
                    path: '/proxyStore/:storeUserName/settlement/:status?',
                    name: 'proxyStore/settlement',
                    meta: {
                        title: '累计提成',
                    },
                    component: () => import(/* webpackChunkName: "proxyStore" */ '@/pages/proxyStore/settlement.vue'),
                },
                {
                    path: '/proxyStore/:storeUserName/collect',
                    name: 'proxyStore/collect',
                    meta: {
                        title: '店铺管理',
                    },
                    component: () => import(/* webpackChunkName: "proxyStore" */ '@/pages/proxyStore/collect.vue'),
                },
                {
                    path: '/proxyStore/:storeUserName/edit',
                    name: 'proxyStore/edit',
                    meta: {
                        title: '门店编辑',
                    },
                    component: () => import(/* webpackChunkName: "store" */ '@/pages/store/edit.vue'),
                },
                {
                    path: '/proxyStore/:storeUserName/category',
                    name: 'proxyStore/category',
                    meta: {
                        title: '类目管理',
                    },
                    component: () => import(/* webpackChunkName: "proxyStore" */ '@/pages/proxyStore/category.vue'),
                },
                {
                    path: '/proxyStore/:storeUserName/goods',
                    name: 'proxyStore/goods',
                    meta: {
                        title: '商品管理',
                    },
                    component: () => import(/* webpackChunkName: "proxyStore" */ '@/pages/proxyStore/goods.vue'),
                },
                {
                    path: '/proxyStore/:storeUserName/selectGoods',
                    name: 'proxyStore/selectGoods',
                    meta: {
                        title: '选择商品',
                    },
                    component: () => import(/* webpackChunkName: "proxyStore" */ '@/pages/proxyStore/selectGoods.vue'),
                },
                {
                    path: '/proxyStore/:storeUserName/bill',
                    name: 'proxyStore/bill',
                    meta: {
                        title: '店铺账单',
                    },
                    component: () => import(/* webpackChunkName: "proxyStore" */ '@/pages/proxyStore/bill.vue'),
                },
                {
                    path: '/proxyStore/:storeUserName/order/list/:orderStatus?',
                    name: 'proxyStore/style/order/list',
                    meta: {
                        title: '订单列表',
                    },
                    component: () => import(/* webpackChunkName: "proxyStore" */ '@/pages/proxyStore/orderList.vue'),
                },
                {
                    path: '/proxyStore/:storeUserName/decoration',
                    name: 'proxyStore/decoration',
                    meta: {
                        title: '店铺装修',
                    },
                    component: () => import(/* webpackChunkName: "proxyStore" */ '@/pages/proxyStore/decoration.vue'),
                },
            ],
            // 旧门店
            ...[
                {
                    path: '/store-old/nearby',
                    name: 'store-old/nearby',
                    meta: {
                        title: '附近门店',
                    },
                    component: () => import(/* webpackChunkName: "store" */ '@/pages/store-old/nearby.vue'),
                },
                {
                    path: '/store-old/:storeId/home',
                    name: 'store-old/home',
                    meta: {
                        title: '门店首页',
                        footerShow: 3,
                    },
                    component: () => import(/* webpackChunkName: "store" */ '@/pages/store-old/home.vue'),
                },
                {
                    path: '/store-old/:storeId/goodsDetail/:id',
                    name: 'store-old/goodsDetail',
                    meta: {
                        title: '商品详情',
                        footerShow: 3,
                    },
                    component: () => import(/* webpackChunkName: "store" */ '@/pages/store-old/goodsDetail.vue'),
                },
                {
                    path: '/store-old/:storeId/new/:uuid',
                    name: 'store-old/new',
                    meta: {
                        title: '确认订单页',
                        footerShow: 0,
                    },
                    component: () => import(/* webpackChunkName: "store" */ '@/pages/store-old/new.vue'),
                },
            ],
        ],
        // 预览页面
        ...[
            {
                path: '/preview/goods/detail/:id',
                name: 'preview/goods/detail',
                meta: {
                    title: '商品预览',
                },
                component: () => import(/* webpackChunkName: "preview" */ '@/pages/preview/goods/detail.vue'),
            },
        ],
        // 抽奖
        ...[
            {
                path: '/lottery/turntable/:id',
                name: 'lottery/turntable',
                meta: {
                    title: '大转盘',
                },
                component: () => import(/* webpackChunkName: "preview" */ '@/pages/lottery/turntable/distribute.vue'),
            },
            {
                path: '/lottery/turntable/index/:id',
                name: 'lottery/turntable/index',
                meta: {
                    title: '大转盘',
                },
                component: () => import(/* webpackChunkName: "preview" */ '@/pages/lottery/turntable/index.vue'),
            },
            {
                path: '/lottery/turntable/rule/:id',
                name: 'lottery/turntable/rule',
                meta: {
                    title: '大转盘',
                },
                component: () => import(/* webpackChunkName: "preview" */ '@/pages/lottery/turntable/rule.vue'),
            },
            {
                path: '/lottery/giftList/:id',
                name: 'lottery/giftList',
                meta: {
                    title: '奖品列表',
                },
                component: () => import(/* webpackChunkName: "preview" */ '@/pages/lottery/turntable/giftList.vue'),
            },
        ],
        // 抽奖2
        ...[
            {
                path: '/lottery/turntable2/index/:id',
                name: 'lottery/turntable2/index',
                meta: {
                    title: '大转盘',
                },
                component: () => import(/* webpackChunkName: "preview" */ '@/pages/lottery/turntable2/index.vue'),
            },
            {
                path: '/lottery/turntable2/rule/:id',
                name: 'lottery/turntable2/rule',
                meta: {
                    title: '大转盘',
                },
                component: () => import(/* webpackChunkName: "preview" */ '@/pages/lottery/turntable2/rule.vue'),
            },
        ],
        // 其它
        ...[
            {
                path: '/other/shopClose',
                name: 'shopClose',
                meta: {
                    title: '店铺已关闭',
                    footerShow: 0,
                },
                component: () => import(/* webpackChunkName: "other" */ '@/pages/other/shopClose.vue'),
            },
            {
                path: '/activityPage/:id',
                name: 'activityPage',
                meta: {
                    title: '',
                    footerShow: 1,
                },
                component: () => import(/* webpackChunkName: "other" */ '@/pages/other/activityPage.vue'),
            },
        ],
        {
            path: '*',
            name: '404',
            component: () => import(/* webpackChunkName: "buyer" */ '@/pages/error/404.vue'),
            meta: {
                title: '404',
            },
        },
    ],
});

/**
 * 应用级的数据初始化 (在页面渲染之前)
 */
const initAppData = (() => {
    let isInitAppDataEnd = false;
    return () => {
        return new Promise((resolve, reject) => {
            if (isInitAppDataEnd) {
                resolve();
                return;
            }
            Nprogress.start();
            Promise.all([Store.dispatch('global/power/getMenuResource')])
                .then(() => {
                    isInitAppDataEnd = true;
                    // alert(111);
                    resolve();
                    Nprogress.done();
                })
                .catch(_err => {
                    isInitAppDataEnd = true;
                    // alert(222);
                    Nprogress.done();
                    reject(_err);
                });
        });
    };
})();

routerInstance.beforeEach((to, from, next) => {
    if (MeiQiaControl.isShowPanel) {
        MeiQiaControl.hidePanel();
        next(false);
        return;
    }
    // TODO 因为加载了 navigation 组件，所以如果手动删除URL中 组件生成的 keyName 参数导致 afterEach 未触发
    // 原因是 路由 replace 变换，未触发 afterEach 钩子
    Vue.prototype.$messageBox.clearQueue();
    initAppData()
        .then(() => {
            next();
        })
        .catch(() => {
            next();
        });
});
routerInstance.afterEach(() => {
    if (GlobalVar.routerEdReplace) {
        setTimeout(() => {
            window.location.reload();
        }, 20);
    }
});

export default routerInstance;
