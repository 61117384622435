<!-- Created by henian.xu on 2018/2/1. -->

<template>
    <div :class="['form',labelPosition?`label-${labelPosition}`:'']">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'XForm',
    componentName: 'XForm',
    data() {
        return {
            fields: [],
        };
    },
    props: {
        rules: {
            type: Object,
            default() {
                return {};
            },
        },
        model: {
            type: Object,
            default() {
                return {};
            },
        },
        showValidSuccess: {
            type: Boolean,
            default: false,
        },
        labelPosition: {
            type: String,
            default: 'left',
        },
    },
    methods: {
        validate() {
            if (!this.model) {
                // console.warn('[Warn][Form]model is required for validate to work!');
                throw new Error('[Form]model is required for validate to work!');
            }
            let valid = true;
            let count = 0;
            let cbFn = null;
            const promise = new Promise((resolve, reject) => {
                cbFn = () => {
                    if (++count === this.fields.length) {
                        valid ? resolve(valid) : reject(fields);
                    }
                };
            });
            // 如果需要验证的fields为空，调用验证时立刻返回callback
            if (this.fields.length === 0 && cbFn) {
                cbFn(true);
            }
            const fields = [];
            this.fields.forEach(field => {
                field
                    .validate()
                    .then(() => {
                        cbFn();
                    })
                    .catch(() => {
                        valid = false;
                        fields.push(field);
                        cbFn();
                    });
            });
            if (promise) return promise;
        },
        clearValidate() {
            this.fields.forEach(field => {
                field.clearValidate();
            });
        },
        resetFields() {
            if (!this.model) {
                if (process.env.NODE_ENV === 'production') {
                    // console.warn('[Warn][Form]model is required for resetFields to work.');
                    throw new Error('[Form]model is required for resetFields to work.');
                }
            }
            this.fields.forEach(field => {
                field.resetField();
            });
        },
    },
    created() {
        this.$on('x.form.addField', field => {
            if (field) this.fields.push(field);
        });
        this.$on('x.form.removeField', field => {
            if (field) {
                const index = this.fields.indexOf(field);
                if (index !== -1) this.fields.splice(index, 1);
            }
        });
    },
};
</script>

<style lang="scss">
</style>
