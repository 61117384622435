/**
 * Created by henian.xu on 2019/8/16.
 *
 */

import Store from '@/store';
import { Buyer } from '@/api';

function setSupplierSelect(supplier, value, isEdit) {
    const { children } = supplier;
    supplier.checked = supplier.selected = value;
    children.forEach(cart => (cart.checked = value ? (!isEdit ? cart.goodsStatus === 3 : true) : false));
}

export default {
    namespaced: true,
    state: {
        rawData: void 0, //{list,supplierMap,supplierList}
    },
    getters: {
        _rawData(state) {
            const { rawData } = state;
            if (!rawData) {
                Store.dispatch('cart/updated');
            }
            return rawData || {};
        },
        cartList(state, getters) {
            const { _rawData: { list = [] } = {} } = getters;
            return list;
        },
        supplierList(state, getters) {
            const { _rawData: { supplierList = [] } = {} } = getters;
            return supplierList;
        },
        supplierMap(state, getters) {
            const { _rawData: { supplierMap = {} } = {} } = getters;
            return supplierMap;
        },
        currentSupplier(state, getters) {
            const { supplierList } = getters;
            return supplierList.find(supplier => supplier.checked || supplier.selected);
        },
        totalAmount(state, getters) {
            const { cartList } = getters;
            return cartList.reduce((prev, item) => {
                return (prev += item.checked ? item.quantity * item.price : 0);
            }, 0);
        },
        getSelectedData: (state, getters) => () => {
            const {
                _rawData: { list = [], supplierList = [] },
            } = getters;
            return JSON.stringify({
                list: list.reduce((pre, curr) => {
                    if (!curr.checked) return pre;
                    pre.push(curr.id);
                    return pre;
                }, []),
                supplierList: supplierList.reduce((pre, curr) => {
                    if (!curr.checked && !curr.selected) return pre;
                    pre.push({
                        id: curr.id,
                        checked: curr.checked,
                        selected: curr.selected,
                    });
                    return pre;
                }, []),
            });
        },
    },
    mutations: {
        _setRawData(state, list = []) {
            const supplierMap = {};
            let firstSupplierId = 0;
            const supplierList = list.reduce((pre, cur) => {
                const { supplierId, supplierName, goodsStatus } = cur;
                if (!firstSupplierId) firstSupplierId = supplierId;
                cur.checked = supplierId === firstSupplierId && goodsStatus === 3;
                let supplier = supplierMap[supplierId];
                if (!supplier) {
                    supplier = supplierMap[supplierId] = {
                        id: supplierId,
                        supplierId,
                        supplierName,
                        checked: supplierId === firstSupplierId,
                        selected: supplierId === firstSupplierId,
                        children: [],
                    };
                    pre.push(supplier);
                }
                supplier.children.push(cur);
                return pre;
            }, []);
            state.rawData = {
                list,
                supplierMap,
                supplierList,
            };
        },
        supplierSelect(state, { supplierId, currentSupplierId, value, isEdit }) {
            const { rawData } = state;
            if (!rawData) return;
            const { supplierMap } = rawData;
            const supplier = supplierMap[supplierId];
            if (!supplier) return;
            // 取消旧的选择
            if (!isEdit && currentSupplierId && currentSupplierId !== supplierId) {
                const supplierOld = supplierMap[currentSupplierId];
                if (supplierOld) {
                    setSupplierSelect(supplierOld, false);
                }
            }
            // 设置新的选择
            const { checked } = supplier;
            const val = value !== void 0 ? !!value : !checked;
            setSupplierSelect(supplier, val, isEdit);
        },
        cartSelect(state, { cartId, currentSupplierId, value, isEdit }) {
            const { rawData } = state;
            if (!rawData) return;
            const { list, supplierMap } = rawData;
            const currentCart = list.find(cart => cart.id === cartId);
            if (!currentCart) return;
            const { checked, supplierId } = currentCart;
            currentCart.checked = value !== void 0 ? !!value : !checked;
            // 处理supplier
            const supplier = supplierMap[supplierId];
            if (!supplier) return;
            const { children } = supplier;
            supplier.selected = true;
            supplier.checked = !children.some(cart => !cart.checked);
            // 取消旧的选择
            if (!isEdit && currentSupplierId && currentSupplierId !== supplierId) {
                const supplierOld = supplierMap[currentSupplierId];
                if (supplierOld) {
                    setSupplierSelect(supplierOld, false);
                }
            }
        },
        deleteCart(state, cartId) {
            const { rawData } = state;
            if (!rawData) return;
            const { list, supplierMap, supplierList } = rawData;
            let index = -1;
            const currentCart = list.find((cart, i) => {
                index = i;
                return cart.id === cartId;
            });
            if (!currentCart) return;
            // 删除 cartList 中的 cart
            list.splice(index, 1);
            // 删除 supplier 中的 cart
            const { supplierId } = currentCart;
            const supplier = supplierMap[supplierId];
            const { children } = supplier;
            index = children.findIndex(cart => cart.id === cartId);
            if (index === -1) return;
            children.splice(index, 1);
            // 是否需要删除 supplier
            if (children.length) return;
            index = supplierList.findIndex(supplier => supplier.id === supplierId);
            supplierList.splice(index, 1);
            delete supplierMap[supplierId];
        },
        updateCart(state, cart) {
            const { rawData } = state;
            if (!rawData) return;
            const { list } = rawData;
            const currentCart = list.find(item => item.id === cart.id);
            if (!currentCart) return;
            Object.keys(cart).forEach(key => {
                if (!currentCart.hasOwnProperty(key)) return;
                currentCart[key] = cart[key];
            });
        },
        setSelected(state, { value = true, isEdit = false }) {
            const {
                rawData: { list, supplierList },
            } = state;
            list.forEach(cart => (cart.checked = value ? (!isEdit ? cart.goodsStatus === 3 : true) : false));
            supplierList.forEach(supplier => (supplier.checked = supplier.selected = !!value));
        },
        restoreSelected(state, json = '{}') {
            const data = JSON.parse(json);
            const { list: selectedList = [], supplierList: supplierSelectedList = [] } = data;
            const selectedMap = selectedList.reduce((pre, curr) => ((pre[curr] = true), pre), {});
            const supplierSelectedMap = supplierSelectedList.reduce((pre, curr) => ((pre[curr.id] = curr), pre), {});
            const {
                rawData: { list, supplierList },
            } = state;

            list.forEach(cart => (cart.checked = selectedMap[cart.id]));
            supplierList.forEach(supplier => {
                const selected = supplierSelectedMap[supplier.id];
                if (!selected) {
                    supplier.checked = supplier.selected = false;
                } else {
                    supplier.checked = selected.checked;
                    supplier.selected = selected.selected;
                }
            });
        },
    },
    actions: {
        async updated({ commit }) {
            const json = await Buyer.Mb.Cart.cartList({ currentPage: 1, pageSize: 9999 });
            const res = json.data;
            commit('_setRawData', res.data.sort((a, b) => (a.goodsStatus !== 3) - (b.goodsStatus !== 3)));
            // commit('_setRawData', res.data.filter(item => item.supplierId === 6));
            return json;
        },
        async editCart({ commit }, cartList) {
            if (!Array.isArray(cartList)) cartList = [cartList];
            await Buyer.Mb.Cart.editCart({ cartJson: JSON.stringify(cartList) });
            cartList.forEach(cart => {
                commit('updateCart', cart);
            });
        },
        async deleteCart({ commit }, ids) {
            if (!Array.isArray(ids)) ids = [ids];
            await Buyer.Mb.Cart.deleteCart({ cartIds: ids.join(',') });
            ids.forEach(id => commit('deleteCart', id));
            Store.dispatch('countInfo/updated');
        },
    },
};
