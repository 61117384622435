<!-- Created by henian.xu on 2018/9/13. -->

<template>
    <list class="goods">
        <GoodsItem
            v-for="(item) in goodsList"
            :key="item.id"
            :data="item"
            :double-row="+type === 1"
        />
    </list>
</template>

<script>
export default {
    name: 'Goods',
    data() {
        return {
            goodsList: [],
        };
    },
    props: {
        // 单列:1;双列:2;
        type: {
            type: [Number, String],
            default: 1,
        },
        ids: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    watch: {
        ids: {
            handler(val) {
                if (!val || !val.length) {
                    return;
                }
                this.$api.Buyer.Gd.Goods.goodsList({
                    ids: val.join(','),
                }).then(json => {
                    this.goodsList = json.data.data;
                });
            },
            immediate: true,
        },
    },
};
</script>

<style lang="scss">
</style>
