<!-- Created by henian.xu on 2018/8/6. -->

<template>
    <div class="page">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'Page',
    data() {
        return {};
    },
};
</script>

<style lang="scss">
</style>
