<!-- Created by henian.xu on 2017/10/12 0012. -->

<template>
    <div
        v-if="true"
        class="buyer-user-box"
    >
        <div class="inner">
            <div class="user-info">
                <Avatar
                    border
                    radius="all"
                    to="/buyer/userInfo"
                    :src="$options.filters.imgCropping(userData.userAvatar||$defaultAvatar,{width:200})"
                />
                <div class="info">
                    <div class="nickname">
                        {{ userData.nickName||'点击设置用户头像和昵称' }}
                    </div>
                    <Linker :to="`tel://${userData.mobile}`">
                        <XIcon
                            content="f026"
                        />{{ userData.mobile }}
                    </Linker>
                </div>
                <Linker
                    v-if="isDistributor || $hasPower(['buyerBalance:entrance'])"
                    class="extra ta-c"
                    to="/pocketMoney/index"
                >
                    <XIcon
                        content="pocketMoney"
                        size="60"
                        svg
                    />
                    <!--<div style="line-height: 1">
                        {{ data.purseBalance | price }}
                    </div>-->
                </Linker>
            </div>
        </div>
        <div class="data-info">
            <Linker
                class="item"
                to="/buyer/growthList"
            >
                <div class="value">
                    {{ data.totalGrowthValue || 0 }}
                </div>
                <div class="label">
                    成长值
                </div>
            </Linker>
            <Linker
                class="item"
                to="/buyer/pointList"
            >
                <div class="value">
                    {{ data.validPoint }}
                </div>
                <div class="label">
                    积分
                </div>
            </Linker>
            <Linker
                class="item"
                to="/buyer/couponList/1"
            >
                <div class="value">
                    {{ data.couponNum }}
                </div>
                <div class="label">
                    优惠券
                </div>
            </Linker>
            <Linker
                v-if="isDistributor"
                class="item"
                to="/buyer/income"
            >
                <span class="value price">{{ data.income | price }}</span>
                <div class="label">
                    总收入
                </div>
            </Linker>
        </div>
    </div>
    <div
        v-else
        class="user-box"
    >
        <div class="inner">
            <Linker
                class="content"
                to="/buyer/userInfo"
            >
                <div class="img-obj">
                    <div
                        class="inner"
                        :style="`background-image: url(${$options.filters.imgCropping(userData.userAvatar||$defaultAvatar,{width:200})});`"
                    />
                    <!--<div
                        class="brand-logo"
                        :style="`background-image: url('${userData.levelIconUrl}');`"/>-->
                </div>
                <div class="info">
                    <div class="user-name">
                        {{ userData.nickName||'点击设置用户头像和昵称' }}
                        <span
                            class="badge badge-yellow fs-small"
                            v-if="userData.levelName"
                        >{{ userData.levelName }}</span>
                    </div>
                    <div class="account">
                        会员ID:{{ userData.userName }}
                    </div>
                    <div
                        v-if="userData.mobile"
                        class="mobile"
                    >
                        <i class="f-icon tc-blue">&#xf026;</i>{{ userData.mobile }}
                    </div>
                    <!--                <div>我的零钱: <span class="price">{{ data.purseBalance }}</span></div>-->
                </div>
            </Linker>
            <Linker
                class="pocket-money-wrap"
                to="/pocketMoney/index"
            >
                <div>我的零钱: </div>
                <div class="price">
                    {{ data.purseBalance | price }}
                </div>
            </Linker>
        </div>
        <Linker
            v-if="data.totalGrowthValue || data.upgradeGrowthValue"
            class="footer"
            to="/buyer/growthList"
        >
            <span v-if="data.totalGrowthValue">我的成长值 <span class="tc-red">{{ data.totalGrowthValue }}</span></span>
            <span v-if="data.upgradeGrowthValue && data.upgradeDistributorLevelName">&nbsp;还差 <span class="tc-red">{{ data.upgradeGrowthValue }}</span> 可升级至 <span class="badge badge-second">{{ data.upgradeDistributorLevelName }}</span></span>
        </Linker>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'UserBox',
    data() {
        return {};
    },
    props: {
        data: {
            type: Object,
            default() {
                return null;
            },
        },
    },
    computed: {
        ...mapGetters('user', ['userData', 'isDistributor', 'isLogin', 'distributionIncomeMode']),
    },
};
</script>

<style lang="scss">
.user-box-header-img-obj {
    transition: height 1.5s;
    height: 0;
    line-height: 0;
    overflow: hidden;
    > img {
        width: 100%;
        height: auto;
    }
    &.active {
        height: 280/750 * 100vw;
    }
}

.user-box {
    > .inner {
        flex: 1 1 auto;
        position: relative;
        width: 100%;
        // min-height: (240vw / 750 * 100);
        /*min-height: 2.4rem;*/
        //background: $color-main url('~@/assets/images/buyer/home_bg.png') no-repeat center bottom;
        //background-size: 100% auto;
        background-color: #fff;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $color-border;
        padding: $padding;

        > .content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            //text-align: center;
            //min-height: 3.6rem;
            //padding-bottom: $padding;

            > .img-obj {
                width: 0.8rem;
                position: relative;
                display: inline-flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                //border: 2px solid #fff;
                box-shadow: 0 6px 12px -4px $gray6;
                > .inner {
                    width: 100%;
                    height: 0;
                    padding-top: 100%;
                    background: $gray1 center center;
                    background-size: cover;
                    border-radius: 50%;
                }
                > .brand-logo {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 0.5rem;
                    height: 0.5rem;
                    background: transparent center no-repeat;
                    background-size: cover;
                    border-radius: 50%;
                }
            }
            > .info {
                padding-left: $padding-small;
                line-height: 1.2;
                > .user-name {
                    /*font-size: 40px;*/
                    font-weight: bold;
                }
                > .account {
                    font-size: 90%;
                }
                > .mobile {
                    /*color: #000;*/
                    font-size: 90%;
                }
            }
        }

        > .pocket-money-wrap {
            text-align: right;
            line-height: 1.1;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-end;
        }

        .level {
            .level-icon {
                width: 1em;
                height: 1em;
                vertical-align: middle;
            }
        }
    }
    > .footer {
        display: block;
        background-color: #fff;
        padding: $padding-small $padding;
        border-bottom: 1px solid $color-border;
    }
}

.buyer-user-box {
    position: relative;
    padding: $padding-big $padding-big 0 $padding-big;
    background-color: #fff;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(160deg, #fff -300%, $color-second 150%);
        //background-color: $color-second;
        //opacity: 0.7;
    }
    > .inner {
        position: relative;
        z-index: $z-index-1;
        > .user-info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: $padding;
            > .avatar {
                flex: 0 0 auto;
                margin-right: $margin;
            }
            > .info {
                flex: 1 1 1%;
                font-weight: bold;
                //line-height: 1.2;
                > .nickname {
                    font-size: 120%;
                }
            }
            > .extra {
                flex: 0 0 auto;
            }
        }
    }
    > .data-info {
        position: relative;
        padding: $padding-big $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        color: #fff;
        margin-top: $margin;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-top-left-radius: $padding;
            border-top-right-radius: $padding;
            background-image: linear-gradient(160deg, #fff -200%, $color-main 50%);
            //background-color: $color-main;
            //opacity: 0.8;
        }
        > .item {
            position: relative;
            z-index: $z-index-1;
            flex: 1 1 1%;
            text-align: center;
            line-height: 1.2;
            > .value {
                font-size: 110%;
                font-weight: bold;
                color: #fff;
                white-space: nowrap;
            }
            > .label {
                font-size: 85%;
            }
        }
    }
}
</style>
