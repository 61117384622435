<!-- Created by henian.xu on 2018/5/7. -->

<template>
    <div class="dis-bill-item">
        <div class="header">
            <div class="label">
                {{ data.billName }}
            </div>
            <div
                :class="['extra','badge',data.billStatus===1?'badge-red':'badge-green']"
            >
                {{ data.billStatus===1?'未付款':'已付款' }}
            </div>
        </div>
        <div class="body">
            <list>
                <list-item
                    label="账单金额"
                >
                    <div 
                        class="extra price" 
                        slot="extra"
                    >
                        {{ data.billAmount }}
                    </div>
                </list-item>
                <list-item
                    v-if="+data.paymentTime"
                    label="付款时间"
                    :extra="data.paymentTime|date"
                />
                <list-item
                    v-if="data.paymentDesc"
                    label="付款说明"
                    :extra="data.paymentDesc"
                />
            </list>
        </div>
        <!--<div class="footer">footer</div>-->
    </div>
</template>

<script>
export default {
    name: 'DisBillItem',
    data() {
        return {};
    },
    props: {
        data: {
            type: Object,
            default() {
                return {};
            },
        },
    },
};
</script>

<style lang="scss">
.dis-bill-item {
    background-color: #fff;
    margin-bottom: $margin;
    > .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: $padding-small $padding;
        background-color: $gray2;
        > .label {
            font-size: 0.32rem;
            font-weight: bold;
        }
    }
}
</style>
