<!-- Created by henian.xu on 2018/4/28. -->

<template>
    <linker
        :to="linkerTo"
        class="footprint-item"
    >
        <div class="inner">
            <div
                class="img-wrap"
                :style="`background-image: url('${$options.filters.imgCropping(data.mainImageUrl||$defaultImg,{width:350})}');`"
            >
                <div
                    v-if="data.goodsStatus!==3"
                    class="sold-out"
                />
            </div>
            <div class="inner">
                <div class="name">
                    {{ data.goodsName }}
                </div>
                <!--<div class="desc">{{data.goodsSubtitle}}</div>-->
                <div
                    class="store badge badge-blue"
                    v-if="isStore"
                >
                    <i class="f-icon">&#xf006;</i> {{ data.storeName }}
                </div>
                <div class="ctrl">
                    <div />
                    <!--<div class="price">
                        {{ data.minPrice | price }}
                        <span
                            v-if="data.marketPrice"
                            class="old">{{ data.marketPrice | price }}</span>
                    </div>-->
                    <div
                        class="btn btn-g6 link fs-big"
                        @click.prevent.stop="onDelete"
                    >
                        <i class="f-icon nma-a">&#xf008;</i>
                    </div>
                </div>
            </div>
        </div>
    </linker>
</template>

<script>
import goodsItemMixin from './goodsItemMixin';

export default {
    name: 'FootprintItem',
    mixins: [goodsItemMixin],
    data() {
        return {};
    },
    props: {
        doubleRow: {
            type: Boolean,
            default: false,
        },
        shareEarn: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isStore() {
            return this.data.storeId;
        },
        linkerTo() {
            if (this.isStore) {
                return `/store/${this.isStore}/goodsDetail/${this.data.goodsId}`;
            } else {
                return `/goods/detail/${this.data.goodsId}`;
            }
        },
    },
    methods: {
        onDelete() {
            this.$messageBox.confirm('确认删除?', '温馨提示').then(() => {
                this.$api.Buyer.Mb.VisitGoods.delVisitGoods({
                    ids: this.data.id,
                }).then(json => {
                    const res = json.data;
                    this.$emit('delete');
                    this.$messageBox.tips(res.msg);
                });
            });
        },
    },
};
</script>

<style lang="scss">
.footprint-item {
    flex: 0 0 auto;
    min-width: initial;
    width: (100% / 2);
    padding: $padding/2;

    /*&:nth-child(odd) {
            padding-left: $padding;
        }
        &:nth-child(even) {
            padding-right: $padding;
        }*/
    > .inner {
        background-color: #fff;
        display: flex;
        justify-content: flex-start;
        align-items: stretch;

        padding: 0;
        height: 100%;
        flex-direction: column;

        > .img-wrap {
            position: relative;
            background: no-repeat center;
            background-size: cover;
            flex: 0 0 auto;
            border-radius: 0.05rem;
            overflow: hidden;
            border: 1px solid $color-border;

            width: 100%;
            height: 0;
            padding-top: 100%;
            margin: 0;
            max-width: inherit;
            max-height: inherit;

            > .sold-out {
                position: absolute;
                top: 0;
                left: 0;
                background: rgba(0, 0, 0, 0.5) url('~@/assets/images/comm/icon02.png') center no-repeat;
                background-size: 70%;
                width: 100%;
                height: 100%;
            }
        }
        > .inner {
            flex: 1 1 1%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: stretch;
            padding: $padding-small;

            > .name {
                /*flex: 0 0 auto;*/
                @include text-line(2);
                font-size: 0.3rem;
                line-height: 1.2;
                color: #000;
            }
            > .desc {
                @include text-line(1);
                font-size: 0.26rem;
                color: $color-text-minor;
            }
            > .store {
                margin-top: $margin-small;
                font-size: 0.26rem;
                align-self: flex-start;
            }
            > .share-earn {
                font-size: 0.26rem;
                color: $color-main;
            }
            > .extra {
                font-size: 0.2rem;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                > span {
                    display: inline-block;
                    + span {
                        margin-left: $margin-small;
                    }
                }
            }

            > .ctrl {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                > .price {
                    font-weight: bold;
                    > .old {
                        font-weight: normal;
                    }
                }
            }
        }
    }
}
</style>
