<!-- Created by henian.xu on 2019/3/13. -->

<template>
    <div class="store-order-item">
        <div class="header">
            <div class="label">
                <!--<checkbox
                    v-if="data.orderStatus===10&&($globalVar.device.isWeiXin || !$globalVar.device.isMobile)"
                    :val="data.id"
                    v-model="checked"
                />-->
                <div
                    v-if="data.isPresellOrder"
                    class="badge badge-second fs-small"
                >
                    预售
                </div>
                订单号: {{ data.orderSn }}
            </div>
            <div class="extra">
                <span class="tc-main">{{ data.orderStatusName }}</span>
                <!--<div class="badge badge-red fs-small">{{ data.shipMethod===1?'送':'取' }}</div>-->
            </div>
        </div>

        <TrackingItem
            class="bor-b nbor-t"
            v-if="isTracking"
            :data="data"
            :to="`/order/tracking/${data.orderSn}`"
        />
        <list class="bc-g1">
            <StoreGoodsItem
                v-for="(item,index) in orderDetailList"
                :key="index"
                :data="item"
            />
        </list>
        <div class="body">
            <List>
                <list-item
                    label="付款方式："
                >
                    <div
                        class="extra"
                        slot="extra"
                    >
                        <div :class="['badge',data.paymentMode===2?'badge-red':'badge-second']">
                            {{ data.paymentModeName }}
                        </div>
                    </div>
                </list-item>
                <list-item
                    v-if="data.shipMethod===1"
                    label="收货信息："
                >
                    <div
                        class="extra"
                        slot="extra"
                    >
                        <div>
                            {{ data.deliveryConsignee }}
                            <Linker
                                class="tc-blue"
                                :to="`tel://${data.deliveryPhone}`"
                            >
                                <Icon code="&#xf072;" />{{ data.deliveryPhone }}
                            </Linker>
                        </div>
                        <div>{{ data.deliveryRegionName }} {{ data.deliveryAddress }}</div>
                    </div>
                </list-item>
                <template v-if="data.shipMethod===2">
                    <list-item label="自取点：">
                        <div
                            class="extra"
                            slot="extra"
                        >
                            {{ data.stationName }}
                        </div>
                    </list-item>

                    <list-item
                        label="自取点地址:"
                        :extra="data.stationStoreRegionAddress"
                    />
                    <list-item label="自取点联系人：">
                        <div
                            class="extra"
                            slot="extra"
                        >
                            {{ data.contactFullname }}
                            <Linker
                                class="tc-blue"
                                :to="`tel://${data.contactTel}`"
                            >
                                <div class="f-icon">
                                    &#xf072;
                                </div>{{ data.contactTel }}
                            </Linker>
                        </div>
                    </list-item>
                    <list-item label="买家电话：">
                        <div
                            class="extra"
                            slot="extra"
                        >
                            <Linker
                                class="tc-blue"
                                :to="`tel://${data.pickupTel}`"
                            >
                                <div class="f-icon">
                                    &#xf072;
                                </div>{{ data.pickupTel }}
                            </Linker>
                        </div>
                    </list-item>
                </template>
                <list-item
                    label="下单人："
                    :extra="data.buyerMobile"
                >
                    <div
                        class="extra"
                        slot="extra"
                    >
                        <span>{{ data.buyerNickName }}</span>&nbsp;
                        <Linker
                            class="tc-blue"
                            :to="`tel://${data.buyerMobile}`"
                        >
                            <Icon code="&#xf072;" />{{ data.buyerMobile }}
                        </Linker>
                    </div>
                </list-item>
                <list-item
                    label="下单时间："
                    :extra="data.orderTime | moment('YYYY-MM-DD HH:mm:ss')"
                />
                <list-item
                    label="买家留言："
                    :extra="data.buyerMsg||'无'"
                />
                <list-item
                    v-if="data.orderComments"
                    label="卖家备注："
                    :extra="data.orderComments"
                />
            </List>
        </div>

        <div class="footer">
            <div />
            <div><span>共计{{ goodsQuantity }}商品 合计: </span><span class="price">{{ data.payableAmount | price }}</span><span> (含运费 <span class="fs-small">{{ $store.getters['global/currency'] }}</span>{{ data.freight | price }})</span></div>
        </div>
        <div
            v-if="(isStoreAgentSell && isCanRefund) || orderStatus === 10"
            class="footer"
        >
            <div />
            <refund-popup
                v-if="isCanRefund"
                is-shop-store
                :order-id="data.id"
                @applySuccess="onApplySuccess"
                class="btn btn-main link bor radius"
            >
                取消订单
            </refund-popup>
            <StoreOrderEditPrice
                v-if="orderStatus === 10"
                :order-id="data.id"
                :data="orderDetailList"
                @success="updateItem"
                class="btn btn-green link bor radius"
            >
                修改价格
            </StoreOrderEditPrice>

            <template v-if="isPay">
                <PayPopup
                    ref="payPopup"
                    :order-ids="orderIds"
                    :module="isStoreAgentSell ? 1 : 4"
                />
                <div
                    class="btn btn-green radius ma-l"
                    @click.stop.prevent="onPay"
                >
                    立即支付 {{ countdownStr }}
                </div>
            </template>
        </div>
        <div
            v-else-if="!isStoreAgentSell&&orderStatus<99"
            class="footer"
        >
            <div />
            <div class="ctrl">
                <div
                    class="btn btn-blue radius"
                    @click="onComment"
                >
                    卖家备注
                </div>
                <!-- 小B店主取消订单 -->
                <div
                    v-if="orderStatus<99"
                    class="btn btn-main radius"
                    @click="onCancel"
                >
                    取消订单
                </div>
                <div
                    v-if="orderStatus===30||orderStatus===40"
                    class="btn btn-green radius"
                    @click="onConfirm"
                >
                    完成订单
                </div>
                <StoreOrderShipping
                    v-if="orderStatus===20"
                    class="item"
                    :order-id="data.id"
                    @update="onUpdate"
                >
                    <div class="btn btn-second radius">
                        订单发货
                    </div>
                </StoreOrderShipping>
            </div>
        </div>
    </div>
</template>

<script>
import StoreGoodsItem from './_StoreGoodsItem';
import StoreOrderShipping from './_StoreOrderShipping';
export default {
    name: 'StoreOrderItem',
    components: { StoreGoodsItem, StoreOrderShipping },
    data() {
        return {
            orderIds: 0,
            countdownId: 0,
            countdownStr: '',
        };
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        orderDetailList() {
            return this.data.orderDetailList || [];
        },
        goodsQuantity() {
            return this.orderDetailList.reduce((prev, curr) => {
                return prev + curr.quantity;
            }, 0);
        },
        orderStatus() {
            return +this.data.orderStatus;
        },
        isStoreAgentSell() {
            return !!this.data.isStoreAgentSell;
        },
        isCanRefund() {
            return !!this.data.isCanRefund;
        },
        isTracking() {
            return (
                (this.data.orderStatus === 20 || this.data.orderStatus === 30 || this.data.orderStatus === 100) &&
                this.data.shipMethod + '' === '1'
            );
        },
        hasFooter() {
            const { orderStatus } = this;
            return orderStatus < 99;
        },
        isPay() {
            return this.orderStatus === 10 && this.data.isAnotherPay;
        },
    },
    watch: {
        countdownStr(val, oldVal) {
            if (!+val && +oldVal) {
                this.closeTime();
                this.$emit('delete');
            }
        },
    },
    methods: {
        onConfirm() {
            this.$messageBox.confirm('是否要确认该订单').then(() => {
                this.$api.Buyer.Sp.ShopStoreOrder.completeOrder({
                    orderId: this.data.id,
                }).then(res => {
                    for (const key in res.data) {
                        if (res.data.hasOwnProperty(key)) {
                            this.data[key] = res.data[key];
                        }
                    }
                    this.$messageBox.alert(res.msg);
                });
            });
        },
        onCancel() {
            if (!this.isStoreAgentSell) {
                this.shopkeeperCancelOrder();
                return;
            }
        },
        // 小B店主取消订单
        shopkeeperCancelOrder() {
            this.$messageBox
                .confirm('确定要取消该订单?', '温馨提示', {
                    cancelBtnText: '我再想想',
                    confirmBtnText: '确定取消',
                })
                .then(() => {
                    this.$api.Buyer.Sp.ShopStoreOrder.cancelOrder({
                        orderId: this.data.id,
                    }).then(json => {
                        const res = json.data;
                        this.$emit('update', res.data);
                        this.$messageBox.alert(res.msg, '温馨提示');
                    });
                });
        },
        onComment() {
            this.$messageBox
                .prompt('', '卖家备注', {
                    validCallback(action, val) {
                        return new Promise((resolve, reject) => {
                            if (action !== 'confirm') return resolve(true);
                            if (!val) {
                                reject({
                                    msg: `<div class="tc-red">不能为空</div>`,
                                });
                                return;
                            }
                            resolve(true);
                        });
                    },
                })
                .then(val => {
                    this.$api.Buyer.Sp.ShopStoreOrder.editOrder({
                        id: this.data.id,
                        orderComments: val,
                    }).then(json => {
                        this.updateItem(json.data.data);
                    });
                });
        },
        onUpdate(data) {
            this.$emit('update', data);
        },
        onApplySuccess() {
            this.data.isCanRefund = 0;
        },
        updateItem(data) {
            this.$emit('update', data);
        },

        onPay() {
            this.orderIds = this.data.id;
            this.$refs['payPopup'].open();
        },
        setSurplusTime() {
            this.getCountdown();
            this.countdownId = setInterval(() => {
                this.getCountdown();
            }, 1000);
        },
        getCountdown() {
            const data = this.$utils.Comm.getRemainTime(new Date().getTime(), this.data.orderOverTime);
            if (data.d + data.h + data.m + data.s) {
                this.countdownStr = `${+data.d ? `${data.d}天` : ''}${+data.h ? `${data.h}:` : ''}${
                    +data.m ? `${data.m}:` : '00:'
                }${+data.s ? `${data.s}` : '00'}`;
            } else {
                this.countdownStr = '';
            }
        },
        closeTime() {
            clearInterval(this.countdownId);
            this.countdownId = 0;
        },
    },
    mounted() {
        this.setSurplusTime();
    },
};
</script>

<style lang="scss">
.store-order-item {
    background-color: #fff;
    margin-top: $margin;
    &:first-child {
        margin-top: 0;
    }

    > .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: $padding;
        border-bottom: 1px solid $color-border;
        > .label {
            flex: 1 1 1%;
        }
        > .extra {
            flex: 0 0 auto;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            * + * {
                margin-left: $margin-small;
            }
        }
    }
    > .goods-wrap {
    }
    > .body {
    }
    > .footer {
        padding: $padding;
        border-top: 1px solid $color-border;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        > .ctrl {
            > .item,
            > .btn {
                margin-left: $margin-small;
            }
        }
    }
}
</style>
