<!-- Created by henian.xu on 2018/2/6. -->

<template>
    <div class="select">
        <select
            ref="select"
            v-bind="$props"
            v-model="currentValue"
            @focus="handleFocus"
            @blur="handleBlur"
            @change="handleChange"
        >
            <slot />
        </select>
    </div>
</template>

<script>
import emitter from '@/mixin/emitter';

export default {
    name: 'XSelect',
    mixins: [emitter],
    data() {
        return {
            // currentValue: this.value,
        };
    },
    props: {
        value: {
            type: [String, Number, Object],
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        form: {
            type: String,
            default: '',
        },
        id: {
            type: String,
            default: '',
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        clear: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        currentValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
                this.dispatch('formItem', 'x.form.change', [val]);
            },
        },
    },
    methods: {
        handleInput() {
            // const value = event.target.value;
            // this.currentValue = value;
            // this.$emit('input', value);
            // this.dispatch('formItem', 'x.form.change', [value]);
        },
        handleFocus() {},
        handleBlur() {
            this.dispatch('formItem', 'x.form.blur', [this.currentValue]);
        },
        handleChange() {},
    },
};
</script>

<style lang="scss">
.select {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $color-border;

    > select {
        flex: 1 1 auto;
        appearance: none;
        background-color: transparent;
        line-height: inherit;
        padding: 0 $padding-small;
    }

    &.ta-r {
        > select {
            direction: rtl;
            > option {
                direction: ltr;
            }
        }
    }
}
</style>
