<!-- Created by henian.xu on 2019/3/4. -->

<template>
    <div class="store-header">
        <div class="banner">
            <div
                class="inner"
                :style="`background-image: url('${data.storeAdImageUrl||$defaultImg}');`"
            />
        </div>
        <div class="body">
            <div
                class="logo"
                :style="`background-image: url('${data.storeLogoUrl||$defaultImg}');`"
            />
            <div class="label">
                {{ data.storeName }}
            </div>
            <div class="desc">
                <div class="level badge badge-second">
                    等级:{{ data.distributorLevelName }}
                </div>
                <!--<Linker
                    :to="`mailto://${data.contactEmail}`"
                    class="label"
                >
                    {{ data.contactEmail }}
                </Linker>
                <div class="label">
                    {{ data.storeRegionName }}
                </div>-->
            </div>
        </div>
        <linker
            to="./edit"
            class="settings f-icon"
        >
            &#xf007;
        </linker>
    </div>
</template>

<script>
export default {
    name: 'StoreHeader',
    data() {
        return {};
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>

<style lang="scss">
.store-header {
    position: relative;
    > .banner {
        > .inner {
            width: 100%;
            height: 0;
            padding-top: 25%;
            background: transparent center no-repeat;
            background-size: cover;
        }
    }
    > .body {
        $logoSize: 1.8rem !default;
        position: relative;
        padding-top: $logoSize / 2 + $padding;
        padding-bottom: $padding;
        text-align: center;
        box-shadow: 0 0.04rem 0.1rem -0.04rem rgba(0, 0, 0, 0.2);
        margin-bottom: $margin;

        > .logo {
            position: absolute;
            left: 50%;
            top: $logoSize / -2;
            margin-left: $logoSize / -2;
            width: $logoSize;
            height: $logoSize;
            background: transparent center no-repeat;
            background-size: cover;
            border-radius: 0.1rem;
            //box-shadow: 0 0.04rem 0.1rem -0.04rem rgba(0, 0, 0, 0.2);
        }

        > .desc {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            font-size: 75%;
            color: $gray6;
            > .level {
            }
        }
    }

    > .settings {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        min-width: 2em;
        min-height: 2em;
        top: $padding;
        right: $padding;
        color: #fff;
        background-color: $color-main;
        border-radius: $padding-small;
    }
}
</style>
