/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import * as Ex from './Ex'; // 订单模块
import * as Gd from './Gd'; // 商品模块
import * as Mb from './Mb'; // 会员模块
import * as Sp from './Sp'; // 店铺模块
import * as Pm from './Pm'; // 店铺模块

export default {
    Ex,
    Gd,
    Mb,
    Sp,
    Pm,
};
