/**
 * Created by henian.xu on 2018/6/11.
 *
 */

export default {
    data() {
        return {
            scrollData: {
                top: 0,
                left: 0,
                height: 0,
                width: 0,
            },
        };
    },
    computed: {
        $$container() {
            return this.$refs.scrollContainer;
        },
    },
    activated() {
        const { $$container, scrollData } = this;
        $$container.scrollTop = scrollData.top;
        $$container.scrollLeft = scrollData.left;
    },
    deactivated() {
        const { $$container, scrollData } = this;
        scrollData.top = $$container.scrollTop;
        scrollData.left = $$container.scrollLeft;
        scrollData.height = $$container.scrollHeight;
        scrollData.width = $$container.scrollWidth;
    },
};
