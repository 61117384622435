<!-- Created by henian.xu on 2018/3/9. -->

<template>
    <div
        class="query-popup"
        ref="queryPopup"
    >
        <div class="search-box">
            <i
                class="f-icon"
                v-if="isSimple"
                v-html="icon"
                @click="onSwitch"
            />
            <template v-else>
                <i
                    class="f-icon before"
                    v-html="icon"
                />
                <x-input
                    type="search"
                    :placeholder="placeholder"
                    v-model="model"
                    @keydown.enter="onSubmit($event,true)"
                />
                <div
                    class="btn btn-main"
                    v-if="showSearchBtn"
                    @click="onSubmit($event,true)"
                >
                    {{ searchBtnStr }}
                </div>
                <div
                    class="btn btn-second"
                    v-if="$slots.default && $slots.default.length"
                    @click="onSwitch"
                >
                    更多
                </div>
            </template>
        </div>

        <transition name="show">
            <div
                class="popup query"
                ref="popup"
                :style="{'top':top}"
                @click.self="onSwitch"
                v-show="popupShow"
            >
                <transition name="query-box">
                    <div
                        class="inner"
                        v-show="popupShow"
                    >
                        <div class="body">
                            <slot />
                        </div>
                        <div class="footer">
                            <div
                                class="btn btn-green fill"
                                v-if="showResetBtn"
                                @click="onReset"
                            >
                                重置
                            </div>
                            <div
                                class="btn btn-main fill"
                                @click="onSubmit"
                            >
                                查询
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </transition>
    </div>
</template>

<script>
import popup from '@/mixin/popup';

export default {
    name: 'QueryBox',
    mixins: [popup],
    data() {
        return {
            top: 0,
        };
    },
    props: {
        value: {
            type: String,
            default: '',
        },
        onQuery: {
            type: Function,
            default: null,
        },
        isSimple: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            default: '&#xf002;',
        },
        searchBtnStr: {
            type: String,
            default: '搜索',
        },
        showSearchBtn: {
            type: Boolean,
            default: false,
        },
        showResetBtn: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '',
        },
    },
    computed: {
        queryPopup() {
            return this.$refs.queryPopup;
        },
        styleTop() {
            if (!this.queryPopup) return 0;
            return this.queryPopup.offsetTop + (this.isSimple ? this.queryPopup.clientHeight : 0) + 'px';
        },
        model: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    },
    methods: {
        onSwitch() {
            this.popupShow = !this.popupShow;
        },
        show() {
            this.popupShow = true;
        },
        onSubmit($event, quick = false) {
            this.$emit('query', $event, quick);
            this.popupShow && (this.popupShow = false);
        },
        onReset() {
            this.$emit('reset');
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.top = this.styleTop;
        });
    },
};
</script>

<style lang="scss">
.query-popup {
    > .search-box {
        flex: 1 1 1%;
        align-self: stretch;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: $padding-small $padding;
        background-color: #fff;

        > .input {
            flex: 1 1 1%;
            background-color: $gray2;
            align-self: stretch;

            > input {
                padding-left: 0;
            }
        }
        > .btn {
            align-self: stretch;
            &:last-child {
                border-top-right-radius: 10em;
                border-bottom-right-radius: 10em;
            }
        }
        > .before.f-icon {
            align-self: stretch;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background-color: $gray2;
            color: $gray6;
            padding: 0 $padding-small;
            border-top-left-radius: 10em;
            border-bottom-left-radius: 10em;
            line-height: 1;
        }
        > .f-icon {
            margin: 0;
        }
    }
}

.popup.query {
    justify-content: flex-start;
    align-items: stretch;
    > .inner {
        background-color: #fff;
        width: 100%;
        max-height: 80%;
        > .footer {
            padding: $padding;

            > .btn {
                min-height: 0.8rem;
            }
        }
    }
}
</style>
