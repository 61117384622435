<!-- Created by henian.xu on 2018/4/20. -->

<template>
    <linker
        tag="div"
        :to="`../detail/${data.id}`"
        class="order-item"
    >
        <div class="header">
            <div class="label">
                <checkbox
                    v-if="$hasPower(['orderBatchPay:use'])&&data.orderStatus===10&&data.paymentStatus===50"
                    :val="data.id"
                    v-model="checked"
                    :disabled="data.paymentStatus!==50"
                />
                <div
                    v-if="data.isPresellOrder"
                    class="badge badge-second fs-small"
                >
                    预售
                </div>
                订单号: {{ data.orderSn }}
            </div>
            <div class="extra">
                <span class="tc-main">{{ data.orderStatusName }}</span>
                <!--<div class="badge badge-red fs-small">{{ data.shipMethod===1?'送':'取' }}</div>-->
            </div>
        </div>
        <div
            class="body"
            v-if="data.storeId || isTracking"
        >
            <TrackingItem
                class="bor-b"
                v-if="isTracking"
                :data="data"
                :to="`/order/tracking/${data.orderSn}`"
            />
            <list v-if="data.storeId">
                <list-item>
                    <span
                        class="badge badge-blue"
                        slot="label"
                    >
                        {{ data.storeName }}</span>
                </list-item>
            </list>
        </div>
        <div class="goods-wrap">
            <list class="bc-g1">
                <OrderGoodsItem
                    v-for="(item,index) in data.goodsList"
                    :key="index"
                    :data="item"
                />
            </list>
        </div>
        <div class="body">
            <list>
                <template v-if="data.shipMethod===1">
                    <list-item label="收货信息：">
                        <div
                            class="extra"
                            slot="extra"
                        >
                            <div>
                                {{ data.deliveryConsignee }}
                                <Linker
                                    class="tc-blue"
                                    :to="`tel://${data.deliveryPhone}`"
                                >
                                    <Icon code="&#xf072;" />{{ data.deliveryPhone }}
                                </Linker>
                            </div>
                            <div>{{ data.deliveryRegionName }} {{ data.deliveryAddress }}</div>
                        </div>
                    </list-item>
                    <!--<list-item
                        v-if="data.orderStatus===30 || data.orderStatus===100"
                        label="物流信息：">
                        <div
                            class="extra"
                            slot="extra">
                            <div>{{ data.expressName }}</div>
                            <div>{{ data.trackingNumber }}</div>
                        </div>
                    </list-item>-->
                </template>
                <template v-if="data.shipMethod===2">
                    <list-item label="门店名称：">
                        <div
                            class="extra"
                            slot="extra"
                        >
                            <div>{{ data.pickupStoreName }}</div>
                        </div>
                    </list-item>
                    <list-item label="门店地址：">
                        <div
                            class="extra"
                            slot="extra"
                        >
                            <div>{{ data.pickupStoreRegionName }} {{ data.pickupStoreDetailAddress }}</div>
                        </div>
                    </list-item>
                </template>
                <list-item
                    label="下单时间："
                    :extra="data.orderTime | moment('YYYY-MM-DD HH:mm:ss')"
                />
                <list-item
                    v-if="data.isPresellOrder"
                    label="预计发货时间："
                >
                    <div
                        class="extra"
                        slot="extra"
                    >
                        {{ data.estimateShippingTime | moment('YYYY年MM月DD日') }} 24点前发货
                    </div>
                </list-item>
                <list-item
                    v-if="data.goodsPremiumAmount"
                    label="物流保价："
                >
                    <div
                        class="extra price"
                        slot="extra"
                    >
                        {{ data.goodsPremiumAmount | price }}
                    </div>
                </list-item>
            </list>
        </div>
        <div class="footer">
            <div />
            <div><span>共计 {{ goodsQuantity }} 件商品 合计: </span><span class="price">{{ data.payableAmount | price }}</span><span> (含运费 <span class="fs-small">{{ $store.getters['global/currency'] }}</span>{{ data.freight | price }})</span></div>
        </div>
        <div
            class="footer"
            v-if="hasFooter"
        >
            <div />
            <div class="ctrl">
                <OrderEditAddress
                    class="dp-ib"
                    :data="data"
                    v-if="isEditAddress"
                />
                <div
                    v-if="data.goodsList.length === 1 && !isPay"
                    class="btn btn-main radius"
                    @click.prevent.stop="onBuyAgain"
                >
                    再次购买
                </div>
                <template v-if="isCancelOrder">
                    <div
                        v-if="orderStatus===10"
                        class="btn btn-main link bor radius"
                        @click.stop.prevent="onCancelOrder"
                    >
                        取消订单
                    </div>
                    <refund-popup
                        :order-id="data.id"
                        v-if="data.isCanRefund"
                        @applySuccess="onApplySuccess"
                        class="btn btn-main link bor radius"
                    >
                        取消订单
                    </refund-popup>
                </template>
                <template v-if="isValidIdCard">
                    <IdCardValid
                        ref="IdCardValid"
                        v-show="false"
                        :goods-send-mode="data.goodsSendMode"
                        :order-id="data.id"
                        :delivery-consignee="data.deliveryConsignee"
                        :value="data.deliveryIdCardNo"
                        @pass="onPass"
                    />
                    <div
                        class="btn btn-green radius"
                        @click.stop.prevent="$refs['IdCardValid'].open()"
                    >
                        身份认证
                    </div>
                </template>
                <pay-popup
                    class="btn btn-green radius"
                    v-if="isPay"
                    :key="data.id"
                    :module="1"
                    lazy
                    :order-ids="data.id"
                >
                    立即支付 {{ countdownStr }}
                </pay-popup>
                <linker
                    class="btn btn-second radius"
                    v-if="data.paymentMode===2"
                    :to="`/order/offlineDetail/${data.id}`"
                >
                    付款详情
                </linker>
                <div
                    v-if="+data.orderStatus === 30"
                    class="btn btn-green radius"
                    @click.stop="onCompleteOrder"
                >
                    确认收货
                </div>
                <linker
                    v-if="orderStatus===15"
                    :to="`/goods/groupBuy/${data.id}`"
                    class="btn btn-main radius"
                >
                    邀请好友参团
                </linker>
                <linker
                    v-if="data.orderSource === 5 && data.reviewStatus===10"
                    :to="`/review/new/${data.id}`"
                    class="btn btn-main radius"
                >
                    晒单评价
                </linker>
            </div>
        </div>
    </linker>
</template>

<script>
export default {
    name: 'OrderItem',
    data() {
        return {
            countdownId: 0,
            countdownStr: '',
        };
    },
    props: {
        value: {
            type: [Boolean, Array],
            default: false,
        },
        data: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    computed: {
        orderStatus() {
            return +this.data.orderStatus;
        },
        isPay() {
            return (
                this.orderStatus === 10 && this.data.paymentStatus === 50
                // && (this.$globalVar.device.isWeiXin || !this.$globalVar.device.isMobile)
            );
        },
        isCancelOrder() {
            return (this.orderStatus === 10 && this.countdownStr) || this.data.isCanRefund;
        },
        isEditAddress() {
            return this.data.shipMethod === 1 && this.orderStatus > 0 && this.orderStatus < 30;
        },
        isTracking() {
            return (
                (this.data.orderStatus === 20 || this.data.orderStatus === 30 || this.data.orderStatus === 100) &&
                this.data.shipMethod + '' === '1'
            );
        },
        checked: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        goodsQuantity() {
            return this.data.goodsList.reduce((prev, curr) => {
                return prev + curr.quantity;
            }, 0);
        },
        isValidIdCard() {
            return /*this.data.goodsSendMode === 2 &&*/ !this.data.isCheckIdCard && this.orderStatus !== 99;
        },
        hasFooter() {
            const { isCancelOrder, orderStatus, data, isValidIdCard } = this;
            return (
                isCancelOrder ||
                orderStatus === 30 ||
                orderStatus === 15 ||
                orderStatus === 99 ||
                data.paymentMode === 2 ||
                (data.orderSource === 5 && data.reviewStatus === 10) ||
                isValidIdCard
            );
        },
    },
    watch: {
        countdownStr(val, oldVal) {
            if (!+val && +oldVal) {
                this.closeTime();
                this.$emit('delete');
            }
        },
    },
    methods: {
        onCompleteOrder() {
            this.$messageBox.confirm('是否要确认该订单', '温馨提示').then(() => {
                this.$api.Buyer.Ex.Order.completeOrder({
                    orderId: this.data.id,
                }).then(json => {
                    const res = json.data;
                    for (const key in res.data) {
                        if (res.data.hasOwnProperty(key)) {
                            this.data[key] = res.data[key];
                        }
                    }
                    this.$messageBox.alert(res.msg, '温馨提示');
                });
            });
        },
        onCancelOrder() {
            switch (this.orderStatus) {
                case 10:
                    this.$messageBox
                        .confirm('确定要取消该订单?', '温馨提示', {
                            cancelBtnText: '我再想想',
                            confirmBtnText: '确定取消',
                        })
                        .then(() => {
                            this.$api.Buyer.Ex.Order.cancelOrder({
                                orderId: this.data.id,
                            }).then(json => {
                                const res = json.data;
                                /* for (const key in res.data) {
                                if (res.data.hasOwnProperty(key)) {
                                    this.data[key] = res.data[key];
                                }
                            } */
                                this.$emit('delete');
                                this.$messageBox.alert(res.msg, '温馨提示');
                            });
                        });
                    break;
                case 20:
                    break;
            }
        },
        onApplySuccess() {
            this.data.isCanRefund = 0;
        },
        getCountdown() {
            const data = this.$utils.Comm.getRemainTime(new Date().getTime(), this.data.orderOverTime);
            if (data.d + data.h + data.m + data.s) {
                this.countdownStr = `${+data.d ? `${data.d}天` : ''}${+data.h ? `${data.h}:` : ''}${
                    +data.m ? `${data.m}:` : '00:'
                }${+data.s ? `${data.s}` : '00'}`;
            } else {
                this.countdownStr = '';
            }
        },
        setSurplusTime() {
            this.getCountdown();
            this.countdownId = setInterval(() => {
                this.getCountdown();
            }, 1000);
        },
        closeTime() {
            clearInterval(this.countdownId);
            this.countdownId = 0;
        },
        onBuyAgain() {
            const { goodsList } = this.data;
            if (!goodsList || goodsList.length !== 1) return;
            const goods = goodsList[0];
            if (!goods) return;
            this.$router.push(`/goods/detail/${goods.goodsId}`);
        },
        onPass(data) {
            Object.keys(data).forEach(key => {
                if (!this.data.hasOwnProperty(key)) return;
                this.data[key] = data[key];
            });
            this.data.isCheckIdCard = 1;
            this.$messageBox.tips('身份认证成功', 3000);
        },
    },
    mounted() {
        this.setSurplusTime();
    },
};
</script>

<style lang="scss">
.order-item {
    background-color: #fff;
    margin-top: $margin;
    &:first-child {
        margin-top: 0;
    }

    > .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: $padding;
        border-bottom: 1px solid $color-border;
        > .label {
            flex: 1 1 1%;
        }
        > .extra {
            flex: 0 0 auto;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            * + * {
                margin-left: $margin-small;
            }
        }
    }
    > .goods-wrap {
    }
    > .body {
    }
    > .footer {
        padding: $padding;
        border-top: 1px solid $color-border;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .ctrl {
            > .btn {
                margin-left: $margin-small;
            }
        }
    }
}
</style>
