/**
 * Created by henian.xu on 2018/9/11.
 * comm 基类 继承用
 */
import HttpBase from '@/api/base/HttpBase';

class Comm extends HttpBase {
    constructor() {
        super();
        this.baseUrl += '/rpc/comm';
    }
}

export default new Comm();
export { Comm };
