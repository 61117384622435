<!-- Created by henian.xu on 2019/1/25. -->

<template>
    <list-item
        v-if="trackingInfoList && trackingInfoList.length"
        :class="['tracking-item',{link:isTracking}]"
        :icon="icon"
        icon-class-name="tc-main"
        :to="to"
        v-on="$listeners"
    >
        <div
            class="label"
            slot="label"
        >
            <div class="label ln-1">
                {{ trackingInfoList[0].context }}
            </div>
            <div
                v-if="data.packageInfo"
                class="desc"
            >
                包裹信息: {{ data.packageInfo }}
            </div>
            <div class="footer">
                <span v-if="data.trackingNumber">物流单号:{{ data.trackingNumber }}</span>
                <span>{{ trackingInfoList[0].time }}</span>
            </div>
        </div>
    </list-item>
    <list-item
        v-else
        class="tracking link"
        icon="&#xf052;"
        icon-class-name="tc-main"
        :to="to"
        v-on="$listeners"
    >
        <div
            class="label"
            slot="label"
        >
            <div class="tc-main ln-1">
                点击查看并保存二维码跟踪物流
            </div>
            <div class="fs-small tc-g6 desc">
                暂无物流信息
            </div>
        </div>
    </list-item>
</template>

<script>
import linkerMixin from '@/mixin/linker';

export default {
    name: 'TrackingItem',
    mixins: [linkerMixin],
    data() {
        return {};
    },
    props: {
        data: {
            type: Object,
            required: true,
            default() {
                return {};
            },
        },
    },
    computed: {
        isTracking() {
            return !!this.data.trackingNumber;
        },
        icon() {
            return this.isTracking ? '&#xf052;' : '';
        },
        trackingInfoList() {
            const { trackingList, latestTrackingInfo } = this.data;
            return trackingList || (latestTrackingInfo ? [latestTrackingInfo] : []) || [];
        },
    },
};
</script>

<style lang="scss">
.tracking-item {
    .label {
        > .label {
            color: $color-main;
            @include text-line(1);
        }
        > .desc {
            font-size: 75%;
            color: $gray6;
            @include text-line(1);
        }
        > .footer {
            font-size: 75%;
            color: $gray6;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }
}
</style>
