<!-- Created by henian.xu on 2018/8/1. -->

<template>
    <div
        class="area-map-picker"
        @click="gotoType(type)"
    >
        <slot>
            <div
                class="inner"
                v-if="value.id"
            >
                {{ regionStr }}
            </div>
            <div
                class="inner none"
                v-else
            >
                请选择
            </div>
        </slot>

        <transition-group :name="aniName">
            <Page
                style="z-index: 800;"
                ref="popupMap"
                key="map"
                v-show="popupShow === 'map'"
            >
                <!--<div class="header">
                    <div class="ctrl-left">
                        <div class="btn"></div>
                    </div>
                    <div class="label">请选择地址</div>
                    <div class="ctrl-right">
                        <div class="btn"
                             @click="gotoType('search')"
                        >
                            <i class="f-icon">&#xf002;</i>
                        </div>
                    </div>
                </div>-->
                <div class="search-box second-header">
                    <div class="inner">
                        <i class="f-icon before">&#xf002;</i>
                        <x-input
                            ref="input"
                            type="search"
                            v-model="workers"
                            @keydown.enter="onSubmit"
                        />
                    </div>
                </div>
                <container>
                    <div class="map-wrap">
                        <div
                            class="center-marker"
                            v-if="mapInited"
                        />
                        <tencentMap
                            ref="map"
                            @init="initMap"
                        />
                    </div>
                    <div class="near-wrap">
                        <list>
                            <list-item
                                v-for="(item) in nearPois"
                                :key="item.id"
                                @click="onNearItem(item)"
                            >
                                <div
                                    class="label"
                                    slot="label"
                                >
                                    <div>{{ item.name }}</div>
                                    <div class="fs-small tc-g6">
                                        {{ item.address }}
                                    </div>
                                </div>
                                <div
                                    class="extra"
                                    slot="extra"
                                    v-if="item.id === currentItem.id"
                                >
                                    <i class="f-icon tc-green">&#xf019;</i>
                                </div>
                            </list-item>
                        </list>
                    </div>
                </container>
                <div class="footer">
                    <div
                        class="btn btn-second"
                        @click="onCancel"
                    >
                        取消
                    </div>
                    <div
                        class="btn btn-main"
                        @click="onConfirm"
                    >
                        确定
                    </div>
                </div>
            </Page>
            <Page
                ref="popupSearch"
                key="search"
                v-show="popupShow === 'search'"
            >
                <!--<div class="search-box second-header">
                    <div class="inner">
                        <i class="f-icon before">&#xf002;</i>
                        <x-input
                            ref="input"
                            type="search"
                            v-model="workers"
                            @keydown.enter="onSubmit"
                        />
                    </div>
                </div>-->
                <container>
                    <list>
                        <list-item
                            v-for="(item) in searchList"
                            :key="item.id"
                            @click="onNearItem(item)"
                        >
                            <div
                                class="label"
                                slot="label"
                            >
                                <div>{{ item.name }}</div>
                                <div class="fs-small tc-g6">
                                    {{ item.address }}
                                </div>
                            </div>
                            <div
                                class="extra"
                                slot="extra"
                                v-if="item.id === currentItem.id"
                            >
                                <i class="f-icon tc-green">&#xf019;</i>
                            </div>
                        </list-item>
                    </list>
                </container>
            </Page>
        </transition-group>
    </div>
</template>

<script>
import emitter from '@/mixin/emitter';
import Popup from '@/mixin/popup';

export default {
    name: 'AreaMapPicker',
    mixins: [emitter, Popup],
    data() {
        return {
            appLevel: true,
            mapInited: false,
            nearPois: [],
            addressComponents: {},
            currentItem: {},
            nearSelectIng: false,
            searchService: null,
            autoPlace: null,
            searchList: [],
            workers: '',
        };
    },
    props: {
        value: {
            type: Object,
            default() {
                return {};
            },
        },
        type: {
            type: String,
            default() {
                return 'map';
            },
        },
    },
    computed: {
        $$popup() {
            const str = this.delayedCreate ? '' : ''; // 模板模块那里应该用这个显示与否
            const res = [this.$refs['popupMap' + str] || null, this.$refs['popupSearch' + str] || null];
            return res;
        },
        $$map() {
            return this.$refs.map;
        },
        maps() {
            return this.$$map.maps;
        },
        map() {
            return this.$$map.map;
        },
        regionStr() {
            const { addressComponents, value } = this;
            const data = value.id ? value : addressComponents;
            return `${data.province || ''}${data.city || ''}${data.district || ''}`;
        },
    },
    methods: {
        mount() {
            if (this.disabled || this.readonly) return;
            const { $$popup } = this;
            $$popup.forEach(item => {
                if (!item && item.$el) return;
                this.$$popupWrap.appendChild(item.$el || item);
            });
        },
        gotoType(type) {
            if (this.disabled) return;
            if (isNaN(type)) {
                this.popupShow = type;
            } else {
                this.$router.go(type);
            }
        },
        initMap() {
            this.mapInited = true;
            const { map, maps } = this;
            this.autoPlace = new maps.place.Autocomplete(this.$refs.input.$refs.input);
            maps.event.addListener(this.autoPlace, 'confirm', res => {
                // console.log(res);
                this.searchService.search(res.value);
            });
            this.searchService = new maps.SearchService({
                map,
            });
            this.searching();
            maps.event.addListener(map, 'center_changed', () => {
                if (this.nearSelectIng) {
                    this.nearSelectIng = false;
                    return;
                }
                this.searching();
            });
        },
        searching() {
            const { map, maps } = this;
            const geocoder = new maps.Geocoder({
                complete: this.geocoderComplete,
            });
            geocoder.getAddress(map.getCenter());
            // console.log(map.getCenter());
        },
        geocoderComplete(result) {
            const detail = result.detail;
            this.nearPois = detail.nearPois;
            this.addressComponents = detail.addressComponents;
            if (this.nearPois && this.nearPois.length) {
                this.currentItem = this.nearPois[0];
            }
            // console.log(result);
        },
        onNearItem(item) {
            this.nearSelectIng = true;
            const { map } = this;
            this.currentItem = item;
            map.setCenter(item.latLng);
        },
        onCancel() {
            this.done();
        },
        onConfirm() {
            const { latLng, id } = this.currentItem;
            let { address } = this.currentItem;
            const { regionStr, addressComponents } = this;
            const { province, city, district } = addressComponents || {};
            address = address.replace(province, '');
            address = address.replace(city, '');
            address = address.replace(district, '');
            const value = {
                id: +id,
                province,
                city,
                district,
                address: address.replace(regionStr, ''),
                lat: latLng.lat,
                lng: latLng.lng,
            };
            console.log(value);
            this.$emit('input', value);
            this.dispatch('formItem', 'x.form.change', [value]);
            this.onSwitch();
        },
    },
    beforeDestroy() {
        const { $$popup } = this;
        $$popup.forEach(item => {
            if (!item && item.$el) return;
            this.$$popupWrap.removeChild(item.$el || item);
        });
    },
};
</script>
<style lang="scss" scoped>
.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
}

.search-box {
    > .inner {
        flex: 1 1 1%;
        align-self: stretch;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: $padding;

        > .input {
            flex: 1 1 1%;
            //border: solid $color-border;
            /*border-width: 1px 1px 1px 0;*/
            background-color: $gray2;
            align-self: stretch;
            > input {
                padding-left: 0;
            }
        }
        > .btn {
            align-self: stretch;
        }
        > .before.f-icon {
            align-self: stretch;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            //border: solid $color-border;
            /*border-width: 1px 0 1px;*/
            background-color: $gray2;
            padding: 0 $padding-small;
        }
        > .f-icon {
            margin: 0;
        }
    }
}
</style>
<style lang="scss">
.area-map-picker {
    flex: 1 1 1%;
}

.map-wrap {
    flex: 0 0 auto;
    position: relative;
    height: 50vh;
    > .center-marker {
        position: absolute;
        z-index: 999999;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent url('~@/assets/images/map/centerMarker.png') center calc(50% - 20px) no-repeat;
        /*background-position: center calc(50% - 20px);*/
        background-size: 20px 40px;
        pointer-events: none;
    }
}

.near-wrap {
    flex: 1 1 1%;
    overflow-y: auto;
}
</style>
