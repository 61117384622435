/**
 * Created by henian.xu on 2019/2/28.
 *
 */

import commonMixin from './commonMixin';
export default {
    mixins: [commonMixin],
    data() {
        return {
            labelScopedSlots__: false, // true:让标签直接绑定当前组件的插槽
        };
    },
    props: {
        label: {
            type: [String, Number],
            default: undefined,
        },
        subLabel: {
            type: [String, Number],
            default: undefined,
        },
    },
    computed: {
        __labelProps() {
            const { theme, label, subLabel, __labelScopedSlots, labelScopedSlots__ } = this;
            const scopedSlots = labelScopedSlots__ ? __labelScopedSlots : {};
            return { theme, label, subLabel, scopedSlots };
        },
        __labelScopedSlots() {
            return { ...this.$scopedSlots };
        },
    },
};
