<!-- Created by henian.xu on 2018/8/1. -->

<template>
    <div class="tencent-map">
        <div
            ref="mapContainer"
            class="map-container"
            v-if="maps"
        >
            地图加载中...
        </div>
        <div 
            class="current-location"
            @click="onCurrentLocation"
        >
            <i class="f-icon">&#xf029;</i>
        </div>
    </div>
</template>

<script>
import GlobalVar from 'globalVar';
import { loadMapScript } from '@/utils';
import currentLocationImg from './img/currentLocation.png';
// import centerMarkerImg from '@/assets/images/map/centerMarker.png';

export default {
    name: 'TencentMap',
    data() {
        return {
            maps: null,
            map: null,
            currentLatLng: null,
        };
    },
    computed: {
        isInited() {
            return !!this.maps && this._isMounted;
        },
    },
    watch: {
        isInited: {
            handler(val) {
                if (val) {
                    this.$nextTick(() => {
                        this._initMap();
                    });
                }
            },
            immediate: true,
        },
    },
    methods: {
        _initMap() {
            const { mapContainer } = this.$refs;
            const { maps } = this;
            const geolocation = new maps.Geolocation(GlobalVar.mapKey, 'myapp');
            geolocation.getLocation(
                position => {
                    // console.log(position);
                    this.currentLatLng = new maps.LatLng(position.lat, position.lng);
                    this.map = new maps.Map(mapContainer, {
                        disableDefaultUI: false, // 平移控件的初始启用/停用状态。
                        // controlPosition: true,
                        // panControl: false, // 平移控件的初始启用/停用状态。
                        // zoomControl: false, // 缩放控件的初始启用/停用状态。
                        // scaleControl: false, // 滚动控件的初始启用/停用状态。
                        zoom: 16,
                        center: this.currentLatLng,
                    });
                    this._markerCurrentLocation();
                    this.$emit('init');
                },
                () => {
                    this.map = new maps.Map(mapContainer, {
                        disableDefaultUI: false, // 平移控件的初始启用/停用状态。
                        zoom: 1,
                        // center: new maps.LatLng(position.lat, position.lng),
                    });
                    this.$emit('init');
                },
                {
                    timeout: 8000,
                },
            );
            // console.log(111, maps.Geolocation);
        },
        _markerCurrentLocation() {
            const { maps, map, currentLatLng } = this;
            const marker = new maps.Marker({
                position: currentLatLng,
                icon: new maps.MarkerImage(currentLocationImg, null, null, new maps.Point(15, 15)),
                map: map,
            });
            // const marker2 = new maps.Marker({
            //     position: currentLatLng,
            //     icon: new maps.MarkerImage(centerMarkerImg),
            //     map: map,
            // });
            // console.log(marker2);
            return marker;
        },
        onCurrentLocation() {
            this.map.setCenter(this.currentLatLng);
        },
    },
    created() {
        // console.log(this._isMounted, this);
        loadMapScript().then(maps => {
            this.maps = maps;
        });
    },
    mounted() {
        // console.log(this._isMounted);
    },
};
</script>

<style lang="scss">
.tencent-map {
    position: relative;
    flex: 1 1 1%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    > .map-container {
        flex: 1 1 1%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    > .current-location {
        position: absolute;
        bottom: 0.2rem;
        right: 0.2rem;
        width: 0.8rem;
        height: 0.8rem;
        background-color: #fff;
        border: 1px solid $color-border;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 0.5rem;
    }
}
</style>
