<!-- Created by henian.xu on 2018/8/24. -->

<!--<template>
    <div
        class="button"
        :class="[size,{radius},radius,{'nbor':noBorder},theme,{plain},{link},{disabled}]"
        v-bind="linkerProps"
        v-on="disabled?{}:$listeners"
    >

        <slot
            name="inner"
            v-if="isInner"
        >
            <div
                class="inner"
            >
                <div class="label">{{ label }}</div>
                <div
                    class="sub-label"
                    v-if="subLabel"
                >{{ subLabel }}</div>
            </div>
        </slot>
        <slot v-else>{{ label }}{{ subLabel }}</slot>
    </div>
</template>-->

<script>
import linkerMixin from '@/mixin/linker';
import { Label } from '@/components/label';

export default {
    name: 'Button',
    mixins: [linkerMixin],
    extends: Label,
    data() {
        return {};
    },
    computed: {
        classes() {
            const { size, radius, noBorder, theme, plain, link, disabled } = this;
            return ['button', size, { radius }, radius, { nbor: noBorder }, theme, { plain }, { link }, { disabled }];
        },
        bindProps() {
            return this.linkerProps;
        },
        listenerProps() {
            return this.disabled ? {} : this.$listeners;
        },
    },
    props: {
        size: {
            type: String,
            default: '',
        },
        radius: {
            type: [Boolean, String],
            default: '',
        },
        noBorder: {
            type: Boolean,
            default: false,
        },
        theme: {
            type: String,
            default: '',
        },
        plain: {
            type: Boolean,
            default: false,
        },
        link: {
            type: Boolean,
            default: false,
        },
        defaultSlotAtInner: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style lang="scss">
$padding-vertical: $padding-small !default;
$padding-horizontal: $padding-big !default;

.button {
    flex: 0 0 auto;
    position: relative;
    display: inline-flex;
    justify-content: center;
    background-color: #fff;
    border: 1px solid $color-border;
    vertical-align: middle;
    white-space: nowrap;
    text-align: center;
    line-height: 1.2;

    .f-icon {
        line-height: 1;
    }

    > .inner {
        position: relative;

        > .f-icon {
            margin-bottom: $margin-small;
        }
    }

    /* 创建按钮大小 */
    @each $key, $value in $sizeList {
        $selector: '&';
        @if ($key != 'normal') {
            $selector: '&.#{$key}';
        }
        #{$selector} {
            font-size: 100% * $value;
            padding: $padding-vertical * $value $padding-horizontal * $value;

            &.radius {
                border-radius: $padding-vertical / 2 * $value;

                &.all {
                    border-radius: 100vw;
                }
            }
        }
    }

    /* 主题颜色 */
    @each $key, $value in $colors {
        color: feature-exists(custom-property);
        &.#{'' + $key} {
            $color-text: $color-text;
            @if (lightness_($value) <71) {
                $color-text: #fff;
            }
            background-color: $value;
            // border-color: mix($value, #000, 75%);
            border-color: $value;
            color: $color-text;

            &.plain {
                background-color: transparent;
                border-color: $value;
            }

            &.plain,
            &.link {
                color: $value;
            }
        }
    }

    &.plain,
    &.link {
        background-color: transparent;
    }

    &.link {
        border: none;
    }

    /* 无边框 */
    &.nbor {
        border: none;
    }

    &.disabled {
        opacity: 0.5;
        background-color: $gray4;
        color: $gray6;
        cursor: not-allowed;
    }
}

.button {
    .badge {
        position: absolute;
        z-index: $z-index-4;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
    }
}
</style>
