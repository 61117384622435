/**
 * Created by henian.xu on 2017/11/7.
 *
 */

export { default as Comm } from './comm';
export { default as Dom } from './dom';
export { default as Valid } from './valid';
import * as Decode from './Decode';
export { default as DataMap } from './dataMap';
export { loadMapScript } from './mapScript';

export { Decode };

/* 转换 css 的值 */
export function transformCssValue(val, unit = 'px') {
    const int = parseFloat(val);
    if (!int) return '';
    if (!+val) return val;
    return int + unit;
}

export const addScript = (() => {
    const head = document.head || document.getElementsByTagName('head')[0] || document.documentElement;
    return src => {
        return new Promise((resolve, reject) => {
            if (!src) return;
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = src;
            script.onload = () => {
                resolve(script);
            };
            script.onerror = e => {
                reject(e);
                head.removeChild(script);
            };
            head.insertBefore(script, head.firstChild);
        });
    };
})();

export const addStyle = (() => {
    const head = document.head || document.getElementsByTagName('head')[0] || document.documentElement;
    return href => {
        return new Promise((resolve, reject) => {
            if (!href) return;
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.type = 'text/css';
            link.href = href;
            link.onload = () => {
                resolve(link);
            };
            link.onerror = e => {
                reject(e);
                head.removeChild(link);
            };
            head.insertBefore(link, head.firstChild);
        });
    };
})();
