<!-- Created by henian.xu on 2018/6/6. -->

<template>
    <transition name="bounce">
        <div
            ref="popup"
            class="popup dialog"
            v-show="popupShow"
            @click.self="onMask"
        >
            <div class="inner">
                <slot name="header">
                    <div
                        class="header"
                        v-if="label"
                    >
                        <div class="label">
                            {{ label }}
                        </div>
                    </div>
                </slot>
                <slot name="body">
                    <div class="body">
                        <slot />
                    </div>
                </slot>
                <slot name="footer" />
            </div>
        </div>
    </transition>
</template>

<script>
import popup from '@/mixin/popup';

export default {
    name: 'XDialog',
    mixins: [popup],
    data() {
        return {
            appLevel: true,
            isUseRouter: false,
        };
    },
    props: {
        label: {
            type: String,
            default: '',
        },
        bodyContent: {
            type: String,
            default: '',
        },
        maskClick: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        onMask() {
            if (!this.maskClick) return;
            this.close();
        },
    },
};
</script>

<style lang="scss">
.dialog {
    &.popup {
        > .inner {
            background-color: #fff;
            width: 80%;
            max-height: 80vh;
            border-radius: 0.05rem;
        }
    }
}
</style>
