<template>
    <div
        v-if="isCommand"
        class="linker"
        @click="onCommand"
    >
        <slot />
    </div>
    <router-link
        v-else
        v-bind="linkerProps"
    >
        <slot />
    </router-link>
</template>

<script>
import linkerMixin from '@/mixin/linker';

export default {
    name: 'Linker',
    mixins: [linkerMixin],
    data() {
        return {};
    },
    props: {
        to: {
            type: [String, Object],
            required: true,
        },
    },
};
</script>

<style scoped>
</style>
