<!-- Created by henian.xu on 2018/8/7. -->

<template>
    <div class="store-cart-goods-item">
        <div class="info">
            <div class="label">
                {{ data.goodsName }}
            </div>
            <div 
                class="sku" 
                v-if="data.specDesc"
            >
                {{ data.specDesc }}
            </div>
        </div>
        <div class="price">
            {{ data.price }}
        </div>
        <!--<shelves-add-cart type="sku" :sku="item"></shelves-add-cart>-->
        <StoreAddCart
            is-sku-data
            :data="data"
        />
    </div>
</template>

<script>
import StoreAddCart from './_storeAddCart';

export default {
    name: 'StoreCartGoodsItem',
    components: { StoreAddCart },
    data() {
        return {};
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss">
.store-cart-goods-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $padding 0;
    + .item {
        border-top: 1px solid $color-border;
    }

    > .info {
        flex: 1 1 auto;
        > .label {
            @include text-line(2);
        }
        > .sku {
            display: inline-block;
            border: 1px solid $color-main;
            padding: 0 $padding-small;
            border-radius: $padding-small;
            color: $color-main;
            margin-top: $margin-small;
        }
    }

    > .price {
        padding: $padding;
    }
    > .price,
    > .shelves-add-cart {
        flex: 0 0 auto;
    }
}
</style>
