/**
 * Created by henian.xu on 2017/10/24.
 *
 */
import { Store } from './_Store';

class ShopStoreStat extends Store {
    constructor() {
        super();
        this.baseUrl += '/shopStoreStat';
    }

    /**
     * 订单销量统计
     * @param type 1天视图 2月视图
     * @param currentPage
     * @param pageSize
     * @returns {*}
     */
    orderSaleStatList({ type, currentPage, pageSize }) {
        return super.get(`/orderSaleStatList`, {
            type,
            currentPage,
            pageSize,
        });
    }

    /**
     * 商品销量统计
     * @param type  1天视图 2月视图
     * @param statBeginDate
     * @param statEndDate
     * @param statBeginDateMonth
     * @param statEndDateMonth
     * @param currentPage
     * @param pageSize
     * @returns {*}
     */
    goodsSaleStatList({
        type,
        statBeginDate,
        statEndDate,
        statBeginDateMonth,
        statEndDateMonth,
        currentPage,
        pageSize,
    }) {
        return super.get(`/goodsSaleStatList`, {
            type,
            statBeginDate,
            statEndDate,
            statBeginDateMonth,
            statEndDateMonth,
            currentPage,
            pageSize,
        });
    }

    /**
     * 买家统计
     * @param currentPage
     * @param pageSize
     * @returns {*}
     */
    buyerOrderStatList({ currentPage, pageSize } = {}) {
        return super.get('/buyerOrderStatList', {
            currentPage,
            pageSize,
        });
    }
}

export default new ShopStoreStat();
