<!-- Created by henian.xu on 2019/5/15. -->

<template>
    <div class="proxy-store-store-style-item">
        <div class="header">
            <Checkbox
                v-model="model"
                type="radio"
                :val="data.id"
            >
                <div class="label">
                    风格一
                </div>
            </Checkbox>
        </div>
        <div class="body">
            <ImgList :data="['','','','','','','']" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProxyStoreStyleItem',
    data() {
        return {};
    },
    props: {
        /* eslint-disable vue/require-prop-types */
        /* eslint-disable vue/require-default-prop */
        value: {},
        data: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    },
};
</script>

<style lang="scss">
.proxy-store-store-style-item {
    padding: $padding-small;
    background-color: #fff;
    + .store-style-item {
        margin-top: $margin;
    }
    > .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: $padding-small;
        > .checkbox {
            flex: 0 0 auto;
        }
        > .label {
            flex: 1 1 1%;
        }
    }
    > .body {
        padding: $padding-small;
    }
}
</style>
