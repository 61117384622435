/**
 * Created by henian.xu on 2018/4/19.
 *
 */

import { DataMap, Comm } from '@/utils';
import { Ex } from '@/api/buyer/Ex/Ex';

class Order extends Ex {
    constructor() {
        super();
        this.baseUrl += '/order';
    }

    /**
     * 确认订单(立即购买入口)
     * @param skuId
     * @param quantity
     * @param uuid
     * @returns {Promise<any>}
     */
    buyNowConfirm({ orderType, groupBuyId, skuId, quantity, uuid } = {}) {
        return super
            .get(`/buyNowConfirm`, {
                orderType,
                groupBuyId,
                skuId,
                quantity,
                uuid,
            })
            .then(json => {
                const res = json.data.data;
                Comm.makeDataMap(res, DataMap.order);
                return Promise.resolve(json);
            })
            .catch(_err => {
                return Promise.reject(_err);
            });
    }

    /**
     * 确认订单(购物车入口)
     * @param cartJson
     * @param uuid
     * @returns {Promise<any>}
     */
    cartConfirm({ cartJson, uuid } = {}) {
        return super
            .get(`/cartConfirm`, {
                cartJson,
                uuid,
            })
            .then(json => {
                const res = json.data.data;
                Comm.makeDataMap(res, DataMap.order);
                return Promise.resolve(json);
            })
            .catch(_err => {
                return Promise.reject(_err);
            });
    }

    /**
     * 创建订单
     * @param usePoint
     * @param couponId
     * @param buyerMsg
     * @param uuid
     * @param deliveryAddressId
     * @param pickupDate
     * @param storeServiceTimeId
     * @param shipMethod
     * @returns {AxiosPromise}
     */
    createOrder({
        usePoint,
        couponId,
        giftCardId,
        buyerMsg,
        uuid,
        deliveryConsignee,
        deliveryPhone,
        deliveryRegionId,
        deliveryAddress,
        deliveryPostcode,
        pickupDate,
        storeServiceTimeId,
        shipMethod,
        premiumSkuIds,
        deliveryIdCardNo,
        sendConsignor,
        sendTel,
        subscriberName,
        subscriberIdCardNo,
    } = {}) {
        return super.post(`/createOrder`, {
            usePoint,
            couponId,
            giftCardId,
            buyerMsg,
            uuid,
            // 送货上门
            deliveryConsignee,
            deliveryPhone,
            deliveryRegionId,
            deliveryAddress,
            deliveryPostcode,
            // 门店自取
            pickupDate,
            storeServiceTimeId,
            shipMethod,
            premiumSkuIds,
            deliveryIdCardNo,
            sendConsignor,
            sendTel,
            subscriberName,
            subscriberIdCardNo,
        });
    }

    /**
     * 是否已付款
     * @param paymentId
     * @returns {AxiosPromise}
     */
    isPay({ paymentId } = {}) {
        return super.get(`/isPay`, {
            paymentId,
            _isHandleError: true,
        });
    }

    toPay({ orderIds } = {}) {
        return super.get(`/toPay`, {
            orderIds,
            _isRepeat: true,
        });
    }

    payforOffline({ orderIds, payer, payVoucher, paymentComments } = {}) {
        return super.post(`/payforOffline`, {
            orderIds,
            payer,
            payVoucher,
            paymentComments,
        });
    }

    getShopOfflineInfo() {
        return super.get(`/getShopOfflineInfo`);
    }

    /**
     * 付款单详情
     * @param orderId
     * @returns {AxiosPromise}
     */
    offlinePaymentDetail({ orderId }) {
        return super.get(`/offlinePaymentDetail`, {
            orderId,
        });
    }

    /**
     * 订单列表
     * @param orderStatus           // 订单状态 10待付款 20待发货 30已发货 99已取消 100已完成
     * @param searchKeyword
     * @param currentPage
     * @param pageSize
     * @returns {Promise<any>}
     */
    orderList({ reviewStatus, orderStatus, searchKeyword, currentPage, pageSize } = {}) {
        Comm.required('orderStatus', orderStatus);
        return super
            .get(`/orderList`, {
                reviewStatus,
                orderStatus,
                searchKeyword,
                currentPage,
                pageSize,
            })
            .then(json => {
                const res = json.data.data;
                Comm.makeDataMap(res, DataMap.order);
                return Promise.resolve(json);
            });
    }

    /**
     * 订单详情
     * @param orderId
     * @returns {Promise<any>}
     */
    orderDetail({ orderId } = {}) {
        Comm.required('orderId', orderId);
        return super
            .get(`/orderDetail`, {
                orderId,
            })
            .then(json => {
                const res = json.data.data;
                Comm.makeDataMap(res, DataMap.order);
                return Promise.resolve(json);
            });
    }

    /**
     * 订单收货
     * @param orderId
     * @returns {Promise<any>}
     */
    completeOrder({ orderId } = {}) {
        Comm.required('orderId', orderId);
        return super
            .post(`/completeOrder`, {
                orderId,
            })
            .then(json => {
                const res = json.data.data;
                Comm.makeDataMap(res, DataMap.order);
                return Promise.resolve(json);
            });
    }

    /**
     * 取消订单
     * @param orderId
     * @returns {Promise<any>}
     */
    cancelOrder({ orderId } = {}) {
        Comm.required('orderId', orderId);
        return super
            .post(`/cancelOrder`, {
                orderId,
            })
            .then(json => {
                const res = json.data.data;
                Comm.makeDataMap(res, DataMap.order);
                return Promise.resolve(json);
            });
    }

    matchDeliveryInfo({ deliveryInfo } = {}) {
        Comm.required('deliveryInfo', deliveryInfo);
        return super
            .get(`/matchDeliveryInfo`, {
                deliveryInfo,
            })
            .then(json => {
                const res = json.data.data;
                Comm.makeDataMap(res, DataMap.dis2);
                return Promise.resolve(json);
            });
    }

    /**
     * 获得订单付款信息
     * @returns {Promise<any>}
     */
    getOrderPayInfo({ orderIds } = {}) {
        // this.$nprogress.start();
        return super
            .get(`/getOrderPayInfo`, { orderIds })
            .then(json => {
                // this.$nprogress.done();
                return Promise.resolve(json);
            })
            .catch(_err => {
                // this.$nprogress.done();
                return Promise.reject(_err);
            });
    }

    editOrderDelivery({
        orderId,
        deliveryConsignee,
        deliveryPhone,
        deliveryRegionId,
        deliveryAddress,
        deliveryPostcode,
    }) {
        return super.post('/editOrderDelivery', {
            orderId,
            deliveryConsignee,
            deliveryPhone,
            deliveryRegionId,
            deliveryAddress,
            deliveryPostcode,
        });
    }

    getOrderTrackingInfo({ orderSn, trackingNumber } = {}) {
        return super.get(`/getOrderTrackingInfo`, { orderSn, trackingNumber });
    }
}

export default new Order();
export { Order };
