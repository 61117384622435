<!-- Created by henian.xu on 2018/4/18. -->

<template>
    <div
        :class="['list-item',{'link':!!to},{'no-arrow':noArrow},{'pointer':pointer}]"
        :is="!!to?'linker':'div'"
        v-bind="$attrs"
        v-on="listeners"
        :to="to"
    >
        <slot name="icon">
            <i
                class="icon f-icon"
                :class="iconClassName"
                v-if="icon"
                v-html="icon"
            />
        </slot>
        <slot name="label">
            <template v-if="subLabel">
                <div class="label">
                    <div v-if="label">
                        {{ label }}
                    </div>
                    <div :class="['fs-small tc-g6 lh-1em',subLabelClassName]">
                        {{ subLabel }}
                    </div>
                </div>
            </template>
            <template v-else>
                <div
                    class="label"
                    v-if="label"
                >
                    {{ label }}
                </div>
            </template>
        </slot>
        <slot name="extra">
            <div
                class="extra"
                v-if="extra"
            >
                {{ extra }}
            </div>
        </slot>
    </div>
</template>

<script>
export default {
    name: 'ListItem',
    data() {
        return {};
    },
    props: {
        icon: {
            type: String,
            default: '',
        },
        iconClassName: {
            type: String,
            default: '',
        },
        /* eslint-disable vue/require-prop-types */
        /* eslint-disable vue/require-default-prop */
        label: {},
        subLabel: {},
        subLabelClassName: {
            type: String,
            default: '',
        },
        extra: {},
        to: {
            type: String,
            default: '',
        },
        noArrow: {
            type: Boolean,
            default: false,
        },
        pointer: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
            };
        },
    },
};
</script>

<style lang="scss">
.list-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: $padding;
    background-color: #fff;
    border-top: 1px solid $color-border;
    &.pointer {
        cursor: pointer;
    }

    &:first-child {
        border-top: 0;
    }
    > .icon {
        flex: 0 0 auto;
        font-size: 0.5rem;
        margin-right: $margin-small;
    }
    > .label {
        white-space: nowrap;
        order: 1;
        flex: 1 1 1%;
    }
    > .extra {
        flex: 0 1 auto;
        order: 2;
        text-align: right;
        word-break: break-all;
        padding-left: $padding-small;
    }
    &.reverse {
        > .label {
            order: 2;
        }
        > .extra {
            order: 1;
            padding-left: 0;
            padding-right: $padding-small;
        }
    }
}

a.list-item,
.list-item.link {
    position: relative;
    &:after {
        order: 100;
        @include make-icon;
        content: '\f012';
        color: $gray5;
        float: right;
        font-size: 80%;
        width: 1em;
        margin-left: $margin-small;
        text-align: center;
    }
    &.no-arrow {
        &:after {
            display: none;
        }
    }
}
</style>
