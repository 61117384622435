/**
 * Created by henian.xu on 2017/11/10.
 *
 */

// const Linker = (resolve) => require(['@c/linker'], resolve);
// const Linker = r => require.ensure([], () => r(require('@c/linker')), 'components');

const requireComponent = require.context(
    './',
    true,
    /index\.(vue|js)$/,
    // 找到components文件夹下以.vue命名的文件
);

function registerComponent(Vue, component) {
    if (component.noTag) {
        Vue.prototype[`$${component.names}`] = component;
    } else {
        Vue.component(component.name, component);
    }
}

export default {
    install(Vue) {
        requireComponent.keys().forEach(fileName => {
            // 只会获取二级目录下的文件
            if (fileName.split('/').length !== 3) return;
            const components = requireComponent(fileName);
            for (const key in components) {
                if (components.hasOwnProperty(key)) {
                    registerComponent(Vue, components[key]);
                }
            }
        });
    },
};
