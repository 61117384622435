<!-- Created by henian.xu on 2018/4/28. -->

<template>
    <linker
        :to="`${shareEarn?'/distributor':''}/goods/detail/${data.id}`"
        class="goods-item"
        :class="{'double-row':doubleRow}"
    >
        <div class="inner">
            <div
                class="img-wrap"
                :style="`background-image: url('${$options.filters.imgCropping(data.img||$defaultImg,{width:350})}');`"
            >
                <div
                    v-if="data.goodsStatus!==3"
                    class="sold-out"
                />
                <div
                    class="share-earn"
                    v-if="data.maxDistributionAmount && $hasPower(['distributorEntrance:entrance','distributionIncomeUpgradeMsg:use'],{'&&':true})"
                >
                    最多赚取: <span class="price">{{ data.maxDistributionAmount | price }}</span>
                </div>
            </div>
            <div class="inner">
                <div class="name">
                    {{ data.name }}
                </div>
                <div class="desc">
                    {{ data.desc }}
                </div>
                <!--<div
                    class="share-earn"
                    v-if="data.eachLevelPriceList && data.eachLevelPriceList.length">
                    最多赚取: <span class="price">{{ data.eachLevelPriceList[0].levelIncomePrice | price }}</span>
                </div>-->
                <div
                    v-if="groupBuy"
                    class="group-buy"
                >
                    <div class="group-buy-nums">
                        {{ activityGoodsDetail.groupBuyNums }}人拼团价
                    </div>
                    <div class="price">
                        {{ data.activityMinPrice | price }}
                    </div>
                </div>
                <countdown
                    v-if="isPresellGoods"
                    v-model="countdown"
                    :start-timestamps="data.beginPresellTime"
                    :end-timestamps="data.presellEndTime"
                />
                <div class="ctrl">
                    <!-- 价格 -->
                    <div>
                        <span
                            v-if="groupBuy"
                            class="single-buy"
                        >
                            单买价:{{ data.minPrice | price }}
                        </span>
                        <span
                            v-else
                            class="price"
                        >
                            {{ data.minPrice | price }}
                            <span
                                v-if="data.marketPrice"
                                class="old"
                            >{{ data.marketPrice | price }}</span>
                        </span>
                        <span
                            v-if="data.RMBPrice"
                            class="fs-small tc-g6"
                        >&nbsp;≈<span class="price-rmb">{{ data.RMBPrice | price }}</span></span>
                        <span
                            v-if="$hasPower(['onlyDistributorBuy:use','distributionIncomeUpgradeMsg:use'],{'&&':true}) && !(2 & $store.state.user.userData.type)"
                            class="rise-tips"
                        >升级权益查看分销价</span>
                    </div>
                    <!--<div
                        class="btn btn-main small radius all"
                        v-if="shareEarn">
                        分享赚
                    </div>-->
                    <div
                        v-if="groupBuy"
                        class="btn btn-main small radius all"
                    >
                        去开团
                    </div>
                    <template v-else-if="!data.isPresellGoods && $hasPower(['cart:use'])">
                        <BuyPopup
                            v-if="data.showStock"
                            class="btn btn-main link fs-big"
                            type="cart"
                            :data="data"
                        >
                            <i class="f-icon nma-a">&#xf004;</i>
                        </BuyPopup>
                        <div
                            class="btn btn-g6 link fs-big"
                            v-else
                        >
                            <i class="f-icon nma-a">&#xf004;</i>
                        </div>
                    </template>

                    <div
                        v-if="goodsSales"
                        class="stock tc-g6"
                    >
                        销量：{{ goodsSales }}
                    </div>
                </div>
            </div>
        </div>
    </linker>
</template>

<script>
import goodsItemMixin from './goodsItemMixin';

export default {
    name: 'GoodsItem',
    mixins: [goodsItemMixin],
    data() {
        return {
            countdown: {},
        };
    },
    props: {
        doubleRow: {
            type: Boolean,
            default: false,
        },
        shareEarn: {
            type: Boolean,
            default: false,
        },
        groupBuy: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        activityGoodsDetail() {
            return this.data.activityGoodsDetail || {};
        },
        // 是否预售
        isPresellGoods() {
            return !!this.data.isPresellGoods;
        },
        // 是否预售中
        isPreselling() {
            return this.isPresellGoods ? +this.countdown.status === 2 : true;
        },
        // 最多赚取
        maxEarn() {
            const list = [...this.data.eachLevelPriceList].concat();
            list.sort((a, b) => a.levelIncomePrice - b.levelIncomePrice);
            return list.length ? list[0].levelIncomePrice : 0;
        },
        // 销量
        goodsSales() {
            return this.data.hasOwnProperty('goodsSales') ? this.data.goodsSales + '' : '';
        },
    },
};
</script>

<style lang="scss">
.goods-item {
    display: block;
    flex: 1 1 1%;
    min-width: 100%;
    //border: 1px solid $color-border;
    + .goods-item {
        > .inner {
            //border-top: 1px solid $color-border;
            margin-top: $margin;
        }
    }
    > .inner {
        background-color: #fff;
        padding: $padding;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        //box-shadow: 0 2px 20px -10px #000;
        box-shadow: 0 0.04rem 0.2rem -0.12rem rgba(0, 0, 0, 0.2);

        > .img-wrap {
            position: relative;
            background: no-repeat center;
            background-size: cover;
            flex: 0 0 auto;
            width: (240/750 * 100vw);
            height: (240/750 * 100vw);
            max-width: 2.4rem;
            max-height: 2.4rem;
            margin-right: $margin;
            border-radius: 0.1rem;
            overflow: hidden;
            //border: 1px solid $color-border;

            > .sold-out {
                position: absolute;
                top: 0;
                left: 0;
                background: rgba(0, 0, 0, 0.5) url('~@/assets/images/comm/icon02.png') center no-repeat;
                background-size: 70%;
                width: 100%;
                height: 100%;
            }
            > .share-earn {
                font-size: 0.22rem;
                position: absolute;
                bottom: 0;
                right: 0;
                left: 0;
                background-color: $color-main;
                color: #fff;
                padding: $padding-small/2 $padding-small;
                border-bottom-left-radius: 0.05rem;
                border-bottom-right-radius: 0.05rem;
                > .price {
                    color: #fff;
                    &:before {
                        font-size: 80%;
                    }
                }
            }
        }
        > .inner {
            flex: 1 1 1%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: stretch;
            > .name {
                /*flex: 0 0 auto;*/
                @include text-line(2);
                font-size: 0.3rem;
                line-height: 1.2;
                color: #000;
            }
            > .desc {
                @include text-line(1);
                font-size: 0.26rem;
                color: $color-text-minor;
            }
            > .share-earn {
                font-size: 0.26rem;
                color: $color-main;
            }
            > .group-buy {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                font-size: 0.26rem;
                > .group-buy-nums {
                    background-color: $color-main;
                    color: #fff;
                    padding: 0 $padding-small;
                    border-top-left-radius: 10em;
                    border-bottom-left-radius: 10em;
                }
                > .price {
                    padding: 0 $padding-small;
                    background-color: $gray1;
                    border-top-right-radius: 10em;
                    border-bottom-right-radius: 10em;
                }
            }
            > .extra {
                font-size: 0.2rem;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                > span {
                    display: inline-block;
                    + span {
                        margin-left: $margin-small;
                    }
                }
            }

            > .ctrl {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                > .price {
                    font-weight: bold;
                    > .old {
                        font-weight: normal;
                    }
                }
                > .single-buy {
                    font-size: 0.26rem;
                    color: $color-text-minor;
                }
                .rise-tips {
                    font-size: 80%;
                    display: inline-block;
                    padding: 0 $padding-small;
                    margin-left: $margin-small;
                    line-height: 1.6;
                    border: 1px solid $color-border;
                    border-radius: 5px;
                    color: $color-text-minor;
                }
            }
        }
    }

    /* 双排 */
    &.double-row {
        flex: 0 0 auto;
        min-width: initial;
        width: 50%;
        padding: $padding/2;

        + .goods-item {
            > .inner {
                border-top: 0;
                margin: 0;
            }
        }
        &:nth-child(odd) {
            padding-left: $padding;
        }
        &:nth-child(even) {
            padding-right: $padding;
        }
        > .inner {
            padding: 0;
            height: 100%;
            flex-direction: column;
            border-radius: 0.1rem;

            > .img-wrap {
                width: 100%;
                height: 0;
                padding-top: 100%;
                margin: 0;
                max-width: inherit;
                max-height: inherit;
            }

            > .inner {
                padding: $padding-small;
            }
        }
    }
}
</style>
