/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import * as Rs from './Rs'; // 系统资源模块
import * as Ms from './Ms'; // 系统资源模块

export default {
    Rs,
    Ms,
};
