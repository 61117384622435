<!-- Created by henian.xu on 2018/12/28. -->

<template>
    <div class="distributor-pay">
        <div
            class="out-show"
            @click.stop.prevent="gotoType(type)"
        >
            <slot />
        </div>

        <transition-group name="show">
            <div
                v-show="popupShow === 'type'"
                ref="popupType"
                key="type"
                class="popup pay"
                @click.self="close"
            >
                <transition name="popup-down">
                    <div
                        v-show="popupShow === 'type'"
                        class="inner"
                    >
                        <div class="header">
                            <div class="label">
                                请选择支付方式
                            </div>
                        </div>
                        <div class="body">
                            <List>
                                <ListItem
                                    v-for="(item,index) in payMethods"
                                    :key="index"
                                    :icon-class-name="item.iconClassName"
                                    :icon="item.icon"
                                    :label="item.label"
                                    sub-label-class-name="tc-red"
                                    :sub-label="item.exportStr"
                                    :extra="item.extra"
                                    @click="action(item.action,item.params)"
                                >
                                    <i
                                        v-if="item.iconUrl"
                                        :class="['icon',item.iconClassName]"
                                        slot="icon"
                                    >
                                        <img :src="item.iconUrl">
                                    </i>
                                </ListItem>
                            </List>
                        </div>
                    </div>
                </transition>
            </div>

            <!-- credit -->
            <div
                v-show="popupShow === 'credit'"
                ref="popupCredit"
                key="credit"
                class="popup pay"
                @click.self="close"
            >
                <transition name="popup-down">
                    <div class="inner">
                        <div class="header">
                            <div class="label">
                                请选择银行卡
                            </div>
                        </div>
                        <div class="body">
                            <List>
                                <ListItem
                                    v-for="item in stripeCardInfoList"
                                    :key="item.id"
                                    :to="`/pay/stripe/${module}/${orderIds}?cardInfoId=${item.id}`"
                                    :label="item.cardNo"
                                >
                                    <i
                                        class="icon visa-icon"
                                        slot="icon"
                                    >
                                        <img src="~@/assets/images/icon/visa-icon.png">
                                    </i>
                                </ListItem>
                                <ListItem
                                    :to="`/pay/stripe/${module}/${orderIds}`"
                                    label="使用新卡"
                                    icon="&#xf015;"
                                    icon-class-name="tc-blue"
                                />
                            </List>
                        </div>
                    </div>
                </transition>
            </div>
        </transition-group>
    </div>
</template>

<script>
import popupMixin from '@/mixin/popup';
import payMixin from '@/mixin/pay';
export default {
    name: 'DistributorPayPopup',
    mixins: [popupMixin, payMixin],
    data() {
        return {};
    },
    props: {
        type: {
            type: String,
            default: 'type', // credit
        },
    },
    methods: {
        gotoType(type) {
            if (this.disabled) return;
            if (isNaN(type)) {
                this.popupShow = type;
            } else {
                this.$router.go(type);
            }
        },
    },
};
</script>

<style lang="scss">
.distributor-pay {
    &,
    > .out-show {
        /*flex: 1 1 1%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        > .button {
            flex: 1 1 1%;
        }*/
    }
}

.pay.popup {
    justify-content: flex-end;
    align-items: stretch;
    > .inner {
        width: 100%;
        background-color: #fff;
    }
}
</style>
