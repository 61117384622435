import { render, staticRenderFns } from "./_ProxyStoreStyleItem.vue?vue&type=template&id=3359b1fc&"
import script from "./_ProxyStoreStyleItem.vue?vue&type=script&lang=js&"
export * from "./_ProxyStoreStyleItem.vue?vue&type=script&lang=js&"
import style0 from "./_ProxyStoreStyleItem.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports