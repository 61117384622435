/**
 * Created by henian.xu on 2018/7/14.
 * axios 拦截
 */

import Vue from 'vue';
import Router from '@/router';
import GlobalVar from 'globalVar';
import { Session } from '@/store/storage';

const requestMap = {}; // 当前请求映射表
// const hasMessage401 = false;

/**
 * 重复请求处理器
 * @param request
 */
function repeatRequestHandler(request) {
    if (request._isRepeat) return;
    const { url, _isCancelBefore: isCancelBefore } = request;
    const cancel = requestMap[url];
    if (cancel) {
        if (isCancelBefore) {
            cancel({
                msg: '因重复而取消1',
                url,
                isCancelBefore,
            });
            requestMap[url] = request._cancel;
        } else {
            request._cancel({
                msg: '因重复而取消2',
                url,
                isCancelBefore,
            });
        }
    } else {
        // 新的请求
        requestMap[url] = request._cancel;
    }
}

/**
 * 响应完成 处理重复请求映射表
 * @param response
 */
function repeatRequestFinish(response) {
    const { config } = response;
    if (config && config.url) {
        delete requestMap[config.url];
    }
}

function httpStatusCodeHandler(error) {
    const { response, config } = error;
    if (typeof response !== 'object') return;
    const { status, data } = response;
    switch (status) {
        case 401:
            // GlobalVar.routerEdReplace = true;
            if (Router.currentRoute.path === '/buyer/login') return;
            console.log(1231, Router.currentRoute.path);
            Router.replace('/buyer/login');
            break;
        case 402: // 服务不可用
            Router.replace(`/other/shopClose?msg=${data.msg}`);
            break;
        case 403: // 无权限访问系统模块
            if (data.code === 4031) {
                switch (+GlobalVar.shopData.distributorRegisterMode) {
                    case 1:
                        Router.replace(`/distributor/register`);
                        break;
                    case 2:
                        Router.replace(`/distributor/cardList`);
                        break;
                    default:
                        Router.replace(`/distributor/platformRegister`);
                        break;
                }
            } else if (data.code === 4032) {
                // 门店信息不可用
                Router.replace(`/store/${Session.get('__storeUserName__')}/edit`);
            } else if (data.code === 4033) {
                // 门店开店
                Router.replace(`/distributor/registerStore`);
            }
            break;
        // case 500:
        // console.error(_error.response.data);
        // TODO:需要提供好的界面展现  Router.push('/500')
        // break;
        default:
            if (config.url === '/rpc/buyer/mb/buyer/error') return;
            Vue.prototype.$http.post('/rpc/buyer/mb/buyer/error', { error: JSON.stringify(data) });
            GlobalVar.isDebugger &&
                Vue.prototype.$messageBox.alert(
                    `<div class="ta-c"><div>服务器有个错误，</div><div>已发邮箱给管理员了。</div><div>我们会第一时间修复！</div></div>`,
                );
            break;
    }
}

/**
 * 请求成功拦截
 * @param request
 * @returns {*}
 */
export function requestSuccess(request) {
    // 自定义请求拦截逻辑，可以处理权限，请求发送监控等
    // ...
    repeatRequestHandler(request);
    return request;
}

/**
 * 请求失败拦截
 * @param error
 * @returns {Promise<never>}
 */
export function requestFail(error) {
    // 自定义发送请求失败逻辑，断网，请求发送监控等
    // ...
    return Promise.reject(error);
}

/**
 * 响应成功拦截
 * @param response
 * @returns {Promise<any>}
 */
export function responseSuccess(response) {
    repeatRequestFinish(response);
    const { config, data: result } = response;
    // 接口错误提示信息全局统一拦截提示
    if (!result.success) {
        if (config._isHandleError) return Promise.resolve(response);
        if (result.code === 5001) {
            Router.replace('/order/list/10');
        } else {
            Vue.prototype.$messageBox.alert(result.msg, '温馨提示', {
                callback() {
                    switch (result.code) {
                        // case 5001: // 订单超时
                        case 4001: // 商品已下架或不存在 无法访问商品
                        case 6001: // 订单中的商品已全部申请售后了 无法再申请售后
                            Router.go(-1);
                            break;
                    }
                },
            });
        }
        return Promise.reject(response);
    } else {
        return Promise.resolve(response);
    }
}

/**
 * 响应失败拦截
 * @param error
 * @returns {Promise<never>}
 */
export function responseFail(error) {
    repeatRequestFinish(error);
    httpStatusCodeHandler(error);
    return Promise.reject(error);
}
