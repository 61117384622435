/**
 * Created by henian.xu on 2017/10/24.
 *
 */
import { Rs } from '@/api/comm/Rs/Rs';

class Sms extends Rs {
    constructor() {
        super();
        this.baseUrl += '/sms';
    }

    /**
     * 发送短信验证码
     * @param mobile
     * @returns {AxiosPromise}
     */
    sendVerificationCode({ countryCodeId, mobile, account, jCaptcha, _isHandleError } = {}) {
        return super.post(`/sendVerificationCode`, {
            countryCodeId,
            sendMobile: mobile || account,
            jCaptcha,
            _isHandleError,
        });
    }
}

export default new Sms();
