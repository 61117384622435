// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import './entry';
import './utils/flex.js'; // 高清解决方案
import '@/assets/style/app.scss';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './custom'; // 一些 vue 自定的杂项
import plugins from '@/plugins'; // 注册全局组件
import packages from '@/packages'; // 注册全局组件
import components from '@/components'; // 注册全局组件

Vue.use(plugins);
Vue.use(packages);
Vue.use(components);
Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
