<!-- Created by henian.xu on 2018/4/18. -->

<template>
    <linker
        :is="(isLimit&&isSelling&&!isSoldOut)||!isLimit?'linker':'div'"
        :to="`/goods/event/limit/detail/${data.id}`"
        :class="['goods-limit-item',{'mobile-device':isMobile}]"
    >
        <div
            class="img-wrap"
            :style="`background-image: url('${data.img||$defaultImg}');`"
        >
            <!--<div class="sold-out"
                v-if="isSoldOut"
            ></div>-->
        </div>
        <div class="inner">
            <div class="name">
                {{ data.name }}
            </div>
            <div class="desc">
                {{ data.desc }}
            </div>
            <div
                class="extra"
                v-if="!isSelling && isLimit"
            >
                <span>限量：{{ data.limitQuantity }} 件</span>
            </div>
            <progress-bar
                v-else
                v-model="data.sellQuantity"
                :max-value="data.limitQuantity"
            >
                <div
                    class="label"
                    slot="label"
                >
                    仅剩 {{ data.limitQuantity - data.sellQuantity }} 件
                </div>
            </progress-bar>
            <countdown
                v-if="isLimit"
                v-model="countdown"
                :start-timestamps="data.startTimestamps"
                :end-timestamps="data.endTimestamps"
            />
            <div class="ctrl">
                <div class="price">
                    {{ data.maxSkuPrice }}
                    <span
                        v-if="data.marketPrice"
                        class="old"
                    >{{ data.marketPrice }}</span>
                </div>
                <template
                    v-if="isLimit"
                >
                    <div
                        class="btn btn-main radius"
                        v-if="isSelling&&!isSoldOut"
                    >
                        马上抢
                    </div>
                    <div
                        class="btn btn-g6 radius"
                        v-else
                    >
                        马上抢
                    </div>
                </template>
                <div
                    v-else
                    class="btn btn-main radius"
                >
                    立即购买
                </div>
            </div>
        </div>
    </linker>
</template>

<script>
import goodsItemMixin from './goodsItemMixin';

export default {
    name: 'GoodsLimitItem',
    mixins: [goodsItemMixin],
    data() {
        return {
            countdown: {},
        };
    },
    computed: {
        isMobile() {
            return this.$globalVar.device.isMobile || this.$globalVar.isWeiXin;
        },
        isLimit() {
            // data.activityTimeType: 1:限时；99：永久
            return this.data.activityTimeType === 1;
        },
        isSelling() {
            return this.countdown.status === 2;
        },
        isSoldOut() {
            return this.data.sellQuantity >= this.data.limitQuantity;
        },
    },
};
</script>

<style lang="scss">
.goods-limit-item {
    background-color: #fff;
    padding: $padding;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    border-top: 1px solid $color-border;
    > .img-wrap {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        flex: 0 0 auto;
        width: (280/750 * 100vw);
        height: (280/750 * 100vw);
        max-width: 2.8rem;
        max-height: 2.8rem;
        margin-right: $margin;
        border-radius: 0.05rem;
        overflow: hidden;

        > .sold-out {
            background: rgba(0, 0, 0, 0.5) url('~@/assets/images/comm/icon03.png') center no-repeat;
            background-size: 70%;
            width: 100%;
            height: 100%;
        }
    }
    > .inner {
        flex: 1 1 1%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        > .name {
            /*flex: 0 0 auto;*/
            @include text-line(2);
            font-size: 0.3rem;
            line-height: 1.2;
            color: #000;
        }
        > .desc {
            @include text-line(1);
            font-size: 0.26rem;
            color: $color-text-minor;
        }
        > .extra {
            font-size: 0.2rem;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            > span {
                display: inline-block;
                + span {
                    margin-left: $margin-small;
                }
            }
        }

        > .ctrl {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            > .price {
                font-weight: bold;
                > .old {
                    font-weight: normal;
                }
            }
        }
    }

    &.mobile-device {
        .countdown {
            .inner {
                > .countdown-item {
                    width: 0.3rem;
                    height: 0.3rem;
                    line-height: 0.3rem;
                    font-size: 0.22rem;
                }
            }
        }
    }
}
</style>
