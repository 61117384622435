<!-- Created by henian.xu on 2018/2/2. -->

<template>
    <transition :name="aniName">
        <div
            :class="['popup message-box',$type]"
            v-show="show"
            @click.self="onPopup('cancel')"
        >
            <div
                class="inner"
                v-if="$type==='tips'"
            >
                <div class="body">
                    {{ message }}
                </div>
            </div>
            <template v-else-if="$type==='action'">
                <transition name="popup-down">
                    <div
                        class="inner"
                        v-show="show"
                    >
                        <div class="body">
                            <div
                                v-for="item in actionList"
                                :key="item.id"
                                class="item"
                                :style="{
                                    color: item.color,
                                    backgroundColor: item.backgroundColor
                                }"
                                @click="onAction(item)"
                            >
                                {{ item.name }}
                            </div>
                        </div>
                    </div>
                </transition>
            </template>
            <div
                class="inner"
                v-else
            >
                <div
                    class="header"
                    v-if="title"
                >
                    <div class="label">
                        {{ title }}
                    </div>
                </div>
                <div :class="['body',{'flex':$type==='alert'||$type==='confirm'}]">
                    <div
                        class="message"
                        v-html="message"
                    />
                    <XInput
                        class="bor-a"
                        v-if="showInput"
                        v-model="inputValue"
                        type="textarea"
                        :placeholder="placeholder"
                        :auto-size="inputAutoSize"
                    />
                    <div
                        class="message"
                        v-html="desc"
                    />
                    <div
                        class="error"
                        v-if="errorText"
                        v-html="errorText"
                    />
                </div>
                <div class="footer">
                    <div
                        :class="['btn fill',cancelBtnCls]"
                        v-if="showCancelBtn"
                        @click="handleAction('cancel')"
                        @keydown.enter="handleAction('cancel')"
                    >
                        {{ cancelBtnText }}
                    </div>
                    <div
                        :class="['btn fill',confirmBtnCls]"
                        v-if="showConfirmBtn"
                        @click="handleAction('confirm')"
                        @keydown.enter="handleAction('confirm')"
                    >
                        {{ confirmBtnText }}{{ delayConfirm>0?`(${delayConfirm})`:'' }}
                    </div>
                </div>
                <div
                    class="close-btn"
                    @click="handleAction('close')"
                >
                    <i class="f-icon">&#xf01a;</i>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'MessageBox',
    data() {
        return {
            show: false,
            showCancelBtn: false,
            cancelBtnText: '取消',
            showConfirmBtn: true,
            confirmBtnText: '确认',

            errorText: '',
            desc: '',

            showInput: false,
            inputValue: '',
            placeholder: '请输入',
            inputAutoSize: { maxRows: 4 },

            closeTime: 2000,
            closeTimer: 0,

            delayConfirm: 0,
            delayConfirmTimer: 0,

            isClickOtherClose: true,
            validCallback: null,

            action: '',
            actionList: [],
            currentItem: {},
            showCloseBtn: false,
            callback: null,
        };
    },
    computed: {
        aniName() {
            const { $type } = this;
            return $type !== 'action' ? 'bounce' : 'show';
        },
        cancelBtnCls() {
            return 'btn-second';
        },
        confirmBtnCls() {
            if (this.delayConfirm) {
                return 'btn-g5';
            }
            return 'btn-main';
        },
    },
    watch: {
        show(val) {
            if (this.$type === 'tips') {
                if (val) {
                    this.closeTimer = setTimeout(() => {
                        this.action = 'close';
                        this.doClose();
                    }, this.closeTime);
                } else {
                    clearTimeout(this.closeTimer);
                    this.closeTimer = 0;
                }
            } else if (this.delayConfirm > 0) {
                if (val) {
                    this.delayConfirmTimer = setInterval(() => {
                        this.delayConfirm--;
                        if (this.delayConfirm <= 0) {
                            clearInterval(this.delayConfirmTimer);
                            this.delayConfirmTimer = 0;
                        }
                    }, 1000);
                } else {
                    clearInterval(this.delayConfirmTimer);
                    this.delayConfirmTimer = 0;
                }
            }
        },
    },
    methods: {
        onPopup(action) {
            if (!this.isClickOtherClose) return;
            this.handleAction(action);
        },
        validateAction(action) {
            this.errorText = '';
            return new Promise(resolve => {
                if (!this.validCallback) return resolve(true);
                this.$nprogress.start();
                this.validCallback(action, this.inputValue)
                    .then(val => {
                        this.$nprogress.done();
                        return resolve(val);
                    })
                    .catch(_err => {
                        this.$nprogress.done();
                        this.errorText = _err.msg;
                    });
            });
        },
        handleAction(action) {
            this.validateAction(action).then(() => {
                if (action === 'confirm' && this.delayConfirm > 0) return;
                this.action = action;
                this.doClose();
            });
        },
        onAction(item) {
            this.currentItem = item;
            this.action = 'action';
            this.doClose();
        },
        doClose() {
            this.show = false;
            this.callback(this.action, this);
        },
    },
};
</script>

<style lang="scss" scoped>
.popup-wrap {
    > .popup {
        &.message-box {
            z-index: $z-index-8;
        }
    }
}

.popup {
    .inner {
        position: relative;
        min-width: 60%;
        max-width: 90%;
        min-height: 3rem;
        max-height: 80%;
        width: auto;
        background-color: #fff;
        border-radius: 0.05rem;
        overflow: hidden;

        > .body.flex {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: $padding-big * 1.5;
        }
        > .close-btn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            position: absolute;
            top: 0.25rem;
            right: 0.25rem;

            width: 0.5rem;
            height: 0.5rem;
            font-size: 0.3rem;
        }
    }

    &.tips {
        /*pointer-events: none;*/
        background-color: rgba(0, 0, 0, 0);
        > .inner {
            width: auto;
            min-width: 50vw;
            max-width: 70vw;
            min-height: auto;
            background-color: rgba(0, 0, 0, 0.7);
            color: #fff;
            > .body {
                text-align: center;
            }
        }
    }

    &.action {
        //background-color: rgba(0, 0, 0, 0.1);
        justify-content: flex-end;
        align-items: stretch;
        > .inner {
            max-width: initial;
            min-height: initial;
            background-color: transparent;
            > .body {
                > .item {
                    padding: $padding;
                    border-radius: $padding-small;
                    background-color: #fff;
                    text-align: center;

                    + .item {
                        margin-top: $margin;
                    }
                }
            }
        }
    }
}
</style>
