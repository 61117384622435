<template>
    <!--:class="{'device-pc-nvw':$globalVar.device.dpr<=1 && $globalVar.device.width>=500}"-->
    <div
        id="app"
        :class="{'device-pc-nvw':!isMobile}"
    >
        <!--<pre style="color: #fff;overflow: auto;">
            {{$globalVar.device}}
        </pre>-->
        <div
            class="page-header"
            v-if="!isMobile"
        >
            <div class="ctrl-left">
                <a
                    href="javascript:history.go(-1);"
                    class="btn"
                ><i class="f-icon">&#xf011;</i></a>
            </div>
            <div class="label">
                {{ pageTitle || $route.query.pageTitle || $route.meta.title }}
            </div>
            <div class="ctrl-right">
                <a
                    href="javascript:;"
                    class="btn"
                ><!--<i class="f-icon">&#xf003;</i>--></a>
            </div>
        </div>
        <SecondHeader />
        <div
            class="pages"
            v-show="!$globalVar.routerEdReplace"
        >
            <transition :name="transitionName">
                <navigation>
                    <router-view />
                </navigation>
            </transition>
            <div class="popup-wrap">
                <div
                    class="page notice-popup"
                    v-if="noticeData.isShowNotice"
                    :is="noticeData.shopNoticeLink?'Linker':'div'"
                    :to="noticeData.shopNoticeLink"
                    :style="`background-image: url('${noticeData.shopNoticeImageUrl}');`"
                >
                    <div
                        class="close-btn"
                        @click.stop.prevent="noticeData.isShowNotice = 0"
                    >
                        <i class="f-icon">&#xf01a;</i>
                    </div>
                </div>
            </div>
        </div>
        <footer-main />
        <quickNavigation />
    </div>
</template>

<script>
import pageMixin from '@/mixin/layout/page';

export default {
    name: 'App',
    mixins: [pageMixin],
    data() {
        return {
            transitionName: '',
            pageTitle: 'asdf',
        };
    },
    head() {
        const meta = this.$route.meta;
        this.pageTitle = '';
        return {
            title: `${meta.title || ''}${meta.subTitle ? ` - ${meta.subTitle}` : ''}`,
        };
    },
    computed: {
        isMobile() {
            return this.$globalVar.device.isMobile || this.$globalVar.isWeiXin;
        },
        noticeData() {
            return this.$globalVar.noticeData || {};
        },
    },
    created() {
        this.$navigation.on('forward', (to, from) => {
            if (from.route.path === '/') {
                this.transitionName = '';
            } else {
                this.transitionName = 'left';
            }
        });
        this.$navigation.on('replace', () => {
            this.transitionName = '';
        });

        this.$navigation.on('back', () => {
            this.transitionName = 'right';
        });

        if (!this.isMobile) {
            document.body.style.overflowX = 'auto';
        }
        this.$bus.$on('pageTitle', val => {
            // console.log(val);
            this.pageTitle = val;
        });
    },
};
</script>

<style lang="scss">
.device-pc-nvw {
    width: 500px !important;
    margin: 0 auto;
    .page {
        > .container {
            &::-webkit-scrollbar {
                width: 8px;
                height: 8px;
                border-radius: 8px;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 8px;
                background: #757575;
            }
            &::-webkit-scrollbar-button {
                display: none;
            }
            &::-webkit-scrollbar-track {
                background: #e1e1e1;
                //background: var(--md-theme-demo-light-scrollbar-background-on-background-variant,#e1e1e1);
            }
        }
    }
}
</style>
<style lang="scss" scoped>
.notice-popup {
    background: rgba(0, 0, 0, 0.5) center no-repeat;
    background-size: contain;

    > .close-btn {
        position: absolute;
        top: 0.1rem;
        right: 0.1rem;
        width: 0.66rem;
        height: 0.66rem;
        border-radius: 0.1rem;
        background: rgba(0, 0, 0, 0.5);
        color: rgba(255, 255, 255, 0.5);
        font-size: 0.5rem;
        line-height: 1;
        padding: 0.08rem;
    }
}
</style>
