/**
 * Created by henian.xu on 2017/11/22.
 * 全局公用变量/常量
 */

// 更新 index.html 写入变量
import { Session } from '@/store/storage';
import GlobalVar from 'globalVar';
import { loadMapScript } from '@/utils';
import $wx from '@/custom/weixin';
// import wx from '@/custom/weixin';

import power from './power';

export default {
    namespaced: true,
    modules: { power },
    state: {
        globalVar: GlobalVar,
        shipMethodMap: {
            '1': {
                1: '送货上门',
                2: '跨境直邮',
                3: '跨境直邮',
                4: '保税发货',
            },
            '2': '门店自取',
        },
        location: Session.get('location') || null,
    },
    getters: {
        countryData(state) {
            return state.globalVar.countryData;
        },
        currency(state) {
            return state.globalVar.countryData.currency;
        },
        moduleConfig(state) {
            const { moduleConfig } = state.globalVar;
            return Object.keys(moduleConfig).reduce((prev, curr) => {
                prev[curr] = isNaN(moduleConfig[curr]) ? moduleConfig[curr] : +moduleConfig[curr];
                return prev;
            }, {});
        },
    },
    mutations: {
        setLocation(state, data) {
            state.location = data;
            Session.set('location', data);
        },
    },
    actions: {
        getLocation({ commit }) {
            return new Promise((resolve, reject) => {
                $wx.getLocation()
                    .then(res => {
                        const value = {
                            lat: res.latitude,
                            lng: res.longitude,
                        };
                        commit('setLocation', value);
                        resolve(value);
                    })
                    .catch(() => {
                        loadMapScript().then(maps => {
                            new maps.Geolocation(GlobalVar.mapKey, 'myapp').getLocation(
                                position => {
                                    const value = {
                                        lat: position.lat,
                                        lng: position.lng,
                                    };
                                    commit('setLocation', value);
                                    resolve(value);
                                },
                                () => {
                                    reject(new Error('没有得到当前坐标'));
                                },
                                {
                                    timeout: 8000,
                                },
                            );
                        });
                    });
            });
        },
    },
};
