<!-- Created by henian.xu on 2019/3/4. -->

<template>
    <div
        v-if="tiling"
        class="selector tiling"
    >
        <div
            :class="['item',{active:isSelected(item.id)}]"
            v-for="(item,index) in data"
            :key="item.id || index"
            @click="onItem(item)"
        >
            {{ item.name }}
            <!--<sapn
                class="extra"
                slot="extra"
                v-show="isSelected(item.id)"
            ><i class="f-icon tc-green">&#xf017;</i></sapn>-->
        </div>
    </div>
    <div
        v-else
        class="selector"
    >
        <div
            :class="['out-show',{readonly},{disabled}]"
            @click="onSwitch"
        >
            <div class="inner">
                {{ outShow || placeholder }}
            </div>
            <i
                class="f-icon"
                v-if="!readonly && !disabled"
            >&#xf010;</i>
        </div>
        <!-- popup -->
        <transition name="show">
            <div
                ref="popup"
                class="popup down selector-popup"
                v-show="popupShow"
                @click.self="onClose"
            >
                <transition name="popup-down">
                    <div
                        class="inner"
                        v-show="popupShow"
                    >
                        <div
                            class="header"
                            v-if="label"
                        >
                            <div class="label">
                                {{ label }}
                            </div>
                        </div>
                        <div class="body npa-a">
                            <List>
                                <template v-for="(item,index) in data">
                                    <ListItem
                                        :key="item.id || index"
                                        :label="item.name"
                                        :sub-label="item.desc"
                                        @click="onItem(item)"
                                    >
                                        <div
                                            class="extra"
                                            slot="extra"
                                            v-show="isSelected(item.id)"
                                        >
                                            <i class="f-icon tc-green">&#xf017;</i>
                                        </div>
                                    </ListItem>
                                </template>
                            </List>
                        </div>
                    </div>
                </transition>
            </div>
        </transition>
    </div>
</template>

<script>
import popupMixin from '@/mixin/popup';
export default {
    name: 'Selector',
    mixins: [popupMixin],
    data() {
        return {
            appLevel: true,
        };
    },
    props: {
        value: {
            type: [Number, String, Array],
            default: 0,
            required: true,
        },
        label: {
            type: String,
            default: '',
        },
        data: {
            type: Array,
            default: () => [],
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        bitwise: {
            type: Boolean,
            default: false,
        },
        tiling: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '请选择',
        },
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(val) {
                const { multiple, bitwise, value } = this;
                if (!multiple) {
                    this.$emit('input', val);
                } else if (!bitwise) {
                    const value_ = [...value];
                    const index = value_.indexOf(val);
                    if (index === -1) {
                        value_.push(val);
                    } else {
                        value_.splice(index, 1);
                    }
                    this.$emit('input', value_);
                } else {
                    if (+value & +val) {
                        this.$emit('input', +value ^ +val);
                    } else {
                        this.$emit('input', +value | +val);
                    }
                }
            },
        },
        dataMap() {
            return this.data.reduce((p, c) => ((p[c.id] = c), p), {});
        },
        outShow() {
            const { multiple, bitwise, model, dataMap, data } = this;
            if (!data.length) {
                return '';
            } else if (!multiple) {
                return (dataMap[model] || {}).name;
            } else if (!bitwise) {
                return model.reduce((p, c) => (p.push(dataMap[c].name), p), []).join(',');
            } else {
                // TODO 这个算法太慢了应该优化
                return Object.values(dataMap)
                    .reduce((p, c) => {
                        if (!(+model & c.id)) return p;
                        p.push(dataMap[c.id].name);
                        return p;
                    }, [])
                    .join(',');
            }
        },
    },
    methods: {
        isSelected(id) {
            const { multiple, bitwise, model } = this;
            if (!multiple) {
                return model + '' === id + '';
            } else if (!bitwise) {
                return model.some(item => item + '' === id + '');
            } else {
                return +model & id;
            }
        },
        onItem(item) {
            if (this.disabled || this.readonly) return;
            this.model = item.id;
            if (!this.tiling && !this.multiple) this.close();
        },
        onClose() {
            this.close();
        },
    },
    created() {
        const { multiple, bitwise, model } = this;
        if (!multiple) {
            if (Array.isArray(model)) throw new Error('<Selector> 单选时 model 应该为 String/Number');
        } else if (!bitwise) {
            if (!Array.isArray(model)) throw new Error('<Selector> 多选时 model 应该为数组类型');
        } else {
            if (Array.isArray(model)) throw new Error('<Selector> 按位多选时 model 应该为 String/Number');
            if (isNaN(+model)) throw new Error('<Selector> 按位多选时 id 不能为非数字字符串');
        }
    },
};
</script>

<style lang="scss">
.selector {
    flex: 1 1 1%;
    padding: 0 $padding-small;
    > .out-show {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        &.readonly,
        &.disabled {
            //background-color: $gray1;
            //color: $gray6;
        }
    }

    &.tiling {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        > .item {
            border: 1px solid $color-border;
            border-radius: 0.1rem;
            padding: 0 $padding-small;
            + .item {
                margin-left: $margin-small;
            }

            &.active {
                color: $color-green;
                border-color: $color-green;
                /*&:after {
                    content: '\f017';
                    @include make-icon();
                }*/
            }
        }
    }
}
</style>
