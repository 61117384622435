/**
 * Created by henian.xu on 2017/10/24.
 *
 */
import { Ms } from '@/api/comm/Ms/Ms';

class UserMessage extends Ms {
    constructor() {
        super();
        this.baseUrl += '/userMessage';
    }

    /**
     * 团队日志
     * @param beginTime
     * @param endTime
     * @param currentPage
     * @param pageSize
     * @returns {AxiosPromise<any>}
     */
    userMessageList({ beginTime, endTime, currentPage, pageSize } = {}) {
        return super.get(`/userMessageList`, { beginTime, endTime, currentPage, pageSize });
    }
}

export default new UserMessage();
