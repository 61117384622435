/**
 * Created by henian.xu on 2017/10/24.
 *
 */
import { Sp } from '@/api/buyer/Sp/Sp';

class ShopStore extends Sp {
    constructor() {
        super();
        this.baseUrl += '/store';
    }

    /**
     * 门店列表
     * @param storeRegionId
     * @param lat
     * @param lng
     * @param currentPage
     * @param pageSize
     * @returns {AxiosPromise}
     */
    shopStoreList({ storeRegionId, lat, lng } = {}) {
        return super.get(`/shopStoreList`, {
            storeRegionId,
            lat,
            lng,
        });
    }

    /**
     * 根据买家送货地址 id 获取附近门店列表
     * @param buyerDeliveryAddressId
     * @returns {AxiosPromise}
     */
    findNearbyStoreList({ id } = {}) {
        return super.get(`/findNearbyStoreList`, {
            buyerDeliveryAddressId: id,
        });
    }

    /**
     * 获取门店信息接口
     * @param id
     * @returns {AxiosPromise}
     */
    shopStoreInfo({ id } = {}) {
        return super.get(`/shopStoreInfo`, {
            id,
        });
    }
}

export default new ShopStore();
