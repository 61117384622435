<!-- Created by henian.xu on 2021/7/10. -->

<template>
    <div
        class="gift-card-item"
        v-on="$listeners"
    >
        <div class="header">
            <div
                v-if="isSelect"
                class="checkbox-wrap"
            >
                <checkbox
                    type="radio"
                    :val="data.id"
                    :stop-propagation="false"
                    v-model="checked"
                />
            </div>
            <div class="content">
                <div class="label">
                    {{ data.cardName }}
                </div>
                <div class="face-value">
                    面值: <span class="price">{{ data.totalAmount }}</span>
                </div>
            </div>
            <div class="extra">
                <div class="over">
                    余额: <span class="price">{{ data.totalAmount-data.useAmount }}</span>
                </div>
                <!--                <div class="time">
                    time
                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GiftCardItem',
    data() {
        return {};
    },
    props: {
        value: {
            type: [Number, String],
            default: 0,
        },
        data: {
            type: Object,
            default: () => ({}),
        },
        isSelect: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        checked: {
            get() {
                return this.value;
            },
            set(val) {
                console.log(111111, val);
                this.$emit('input', +val);
            },
        },
    },
};
</script>

<style lang="scss">
.gift-card-item {
    padding: $padding;
    background-color: #fff;

    > .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .checkbox-wrap {
            flex: 0 0 auto;
        }
        > .content {
            flex: 1 1 1%;
            > .label {
                font-weight: bold;
            }
        }
        > .extra {
            flex: 0 0 auto;
        }
    }

    + .gift-card-item {
        margin-top: $margin;
    }
}
</style>
