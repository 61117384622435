/**
 * Created by henian.xu on 2018/9/12.
 *
 */

import { Comm } from '@/utils';

let count = 0; // 临时 Id 计数

// 新旧数据转换适配器
const componentConvert = component => {
    const { componentsType } = component;
    if (componentsType === 'page') {
        component.componentType = 'Page';
        const { data } = component;
        component.props = {
            backgroundColor: data.bc,
            title: data.title,
        };
        delete component.componentMargin;
        delete data.bc;
    } else if (componentsType === 'title') {
        component.componentType = 'Divider';
        component.props = {
            // type: component.type,
            label: component.text,
        };
        delete component.data;
        delete component.text;
        delete component.type;
        delete component.label;
    } else if (componentsType === 'AD') {
        component.componentType = 'Ad';
        const oldData = component.data.map(item => ({
            src: item.src || item.imgUrl,
            href: item.href,
            label: item.label,
        }));
        component.props = {
            margin: component.margin,
            type: component.type,
            data: oldData,
        };
        delete component.data;
        delete component.margin;
        delete component.type;
    } else if (componentsType === 'toolBar') {
        component.componentType = 'Toolbar';
        const oldData = component.data.map(item => ({
            src: item.src || item.imgUrl,
            href: item.href,
            label: item.label,
        }));
        component.props = {
            data: oldData,
        };
        delete component.data;
    } else if (componentsType === 'goods') {
        component.componentType = 'Goods';
        const oldData = component.data.map(item => item);
        component.props = {
            type: component.type,
            ids: component.ids,
            data: oldData,
        };
        delete component.data;
        delete component.ids;
        delete component.type;
    } else if (componentsType === 'goodsList') {
        component.componentType = 'GoodsList';
        const oldData = component.data.map(item => item);
        component.props = {
            type: component.type,
            count: component.count,
            orderBy: `${component.orderBy.type || ''},${component.orderBy.ASC || ''}`,
            data: oldData,
        };
        delete component.data;
        delete component.ids;
        delete component.count;
        delete component.type;
        delete component.orderBy;
    } else if (componentsType === 'ADCube') {
        component.componentType = 'AdCube';
        const oldData = component.data.map(item => ({
            src: item.src || item.imgUrl,
            href: item.href,
            label: item.label,
        }));
        component.props = {
            border: component.border,
            reverse: component.mirroring,
            data: oldData,
        };
        delete component.data;
        delete component.type;
        delete component.mirroring;
        delete component.border;
    } else if (componentsType === 'magicCube') {
        component.componentType = 'MagicCube';
        const oldData = component.data.map(item => ({
            src: item.src || item.imgUrl,
            href: item.href,
            label: item.label,
        }));
        component.props = {
            column: component.column,
            data: oldData,
        };
        delete component.data;
        delete component.type;
        delete component.column;
    }
    delete component.componentsType;
};

function parseDecorationJson(json) {
    const componentList = JSON.parse(json);
    componentList.forEach(component => {
        component.id = ++count;
        componentConvert(component);
        const { componentType, props } = component;
        if (!props) return;
        if (Array.isArray(props.data)) {
            props.data.forEach(item => {
                item.id = Comm.getKey();
            });
        }
        switch (componentType) {
            case 'GoodsList':
                console.log(props);
                break;
        }
    });
    return componentList;
}

// 状态对象
export default {
    data() {
        return {
            decorationJson: '',
            decorationData: [],
        };
    },
    computed: {
        componentList() {
            return this.decorationData.filter(item => {
                if (item.componentType === 'Page' || !item.props) return;
                if (item.componentType === 'goods' && (!item.props.ids || !item.props.ids.length)) return;
                return item;
            });
        },
    },
    methods: {
        setDecoration(json) {
            this.decorationJson = json;
            this.decorationData = parseDecorationJson(json);
        },
    },
};
