<!-- Created by henian.xu on 2019/2/28. -->

<template>
    <div
        :class="classes"
        :style="styles"
    >
        <slot>{{ label }}</slot>
    </div>
</template>

<script>
import labelMixin from '../mixins/labelMixin';
export default {
    name: 'Badge',
    mixins: [labelMixin],
    data() {
        return {};
    },
    computed: {
        size_() {
            const { size } = this;
            const int = parseInt(size);
            if (isNaN(int)) return '';
            if (!+size) return size;
            return int + 'px';
        },
        styles() {
            const { size_ } = this;
            if (!size_) return {};
            return {
                fontSize: size_,
            };
        },
        classes() {
            const { theme, size_, size } = this;
            return ['x-badge', theme, { [size]: !size_ }];
        },
    },
};
</script>

<style lang="scss">
.x-badge {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: $color-danger;
    color: #fff;
    border-radius: 100vw;
    line-height: 1.5;
    min-width: 1.5em;
    padding: 0 $padding-small;

    @each $key, $value in $colorList {
        &.#{$key + ''} {
            $color-text: $color-text;
            @if (lightness_($value) <71) {
                $color-text: #fff;
            }
            background-color: $value;
            color: $color-text;
        }
    }
    /* 尺寸大小 */
    @each $key, $value in $sizeList {
        &.#{$key + ''} {
            font-size: $value * 100%;
        }
    }
}
</style>
