/**
 * Created by henian.xu on 2018/4/24.
 *
 */

import router from '../router';
import store from '../store';

import Navigation from './navigation';
import VueLazyload from 'vue-lazyload';
import loadingIcon from '@/assets/images/comm/loading.jpg';
import defaultImg from '@/assets/images/comm/default.jpg';
import Meta from 'vue-meta';

export default {
    install(Vue) {
        Vue.use(Meta, {
            keyName: 'head', // the component option name that vue-meta looks for meta info on.
            // attribute: 'data-vue-meta', // the attribute name vue-meta adds to the tags it observes
            // ssrAttribute: 'data-vue-meta-server-rendered', // the attribute name that lets vue-meta know that meta info has already been server-rendered
            // tagIDKeyName: 'vmid', // the property name that vue-meta uses to determine whether to overwrite or append a tag
        });
        Vue.use(Navigation, { router, store });
        Vue.use(VueLazyload, {
            preLoad: 1.3,
            error: defaultImg,
            loading: loadingIcon,
            attempt: 1,
        });
    },
};
