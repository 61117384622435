/**
 * Created by henian.xu on 2017/10/1.
 *
 */

import Vue from 'vue';
import Store from '@/store';

function hasPower(dataMap, { value, modifiers }) {
    if (!dataMap || dataMap['*']) return true;
    let res = false;
    for (let i = 0, item; (item = value[i++]); ) {
        res = !!dataMap[item];
        if (!res) {
            const itemArr = item.split(':');
            const itemLng = itemArr.length - 1;
            for (let n = 0; n < itemLng; n++) {
                itemArr.length--;
                const key = itemArr.join(':') + ':*';
                res = !!dataMap[key];
                if (res) break;
            }
        }
        if (modifiers['&&']) {
            if (!res) break;
        } else {
            if (res) break;
        }
    }
    return res;
}

/**
 * 权限
 * @param value
 * @param modifiers
 * @returns {boolean}
 */
Vue.prototype.$hasPower = (value = [], modifiers = {}) => {
    const dataMap = Store.getters['global/power/dataMap'];
    return hasPower(dataMap, { value, modifiers });
};

/**
 * FIX 已用 vue-meta 代替
 * 注册一个指令 来设置页面 标题
 */
/*Vue.directive('title', (el, binding, vnode) => {
    // 当绑定元素插入到 DOM 中。
    const { value } = binding;
    const { context } = vnode;
    const { _inactive } = context || {};
    // console.log(value, _inactive);
    if (!value || _inactive) return;
    document.title = value;
});*/
