/**
 * Created by henian.xu on 2019/2/28.
 * 字符串解码
 */
let t = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/',
    a = [
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        62,
        -1,
        -1,
        -1,
        63,
        52,
        53,
        54,
        55,
        56,
        57,
        58,
        59,
        60,
        61,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        -1,
        -1,
        -1,
        -1,
        -1,
    ];
export function uniEncode(t) {
    let e = [];
    for (let n = 0; n < t.length; n++) e[n] = ('00' + t.charCodeAt(n).toString(16)).slice(-4);
    return '\\u' + e.join('\\u');
}
export function uniDecode(t) {
    t = t = t.replace(/(\\)?\\u/gi, '%u').replace('%u0025', '%25');
    let e = (t = unescape(t.toString().replace(/%2B/g, '+'))).match(/(%u00([0-9A-F]{2}))/gi);
    if (e)
        for (let n = 0; n < e.length; n++) {
            let a = e[n].substring(1, 3);
            Number('0x' + a) >= 128 && (t = t.replace(e[n], a));
        }
    return (t = unescape(t.toString().replace(/%2B/g, '+')));
}
export function base64Encode(e) {
    let n, a, i, r, s, o;
    for (i = e.length, a = 0, n = ''; a < i; ) {
        if (((r = 255 & e.charCodeAt(a++)), a == i)) {
            (n += t.charAt(r >> 2)), (n += t.charAt((3 & r) << 4)), (n += '==');
            break;
        }
        if (((s = e.charCodeAt(a++)), a == i)) {
            (n += t.charAt(r >> 2)),
                (n += t.charAt(((3 & r) << 4) | ((240 & s) >> 4))),
                (n += t.charAt((15 & s) << 2)),
                (n += '=');
            break;
        }
        (o = e.charCodeAt(a++)),
            (n += t.charAt(r >> 2)),
            (n += t.charAt(((3 & r) << 4) | ((240 & s) >> 4))),
            (n += t.charAt(((15 & s) << 2) | ((192 & o) >> 6))),
            (n += t.charAt(63 & o));
    }
    return n;
}
export function base64Decode(t) {
    let e, n, i, r, s, o, l;
    for (o = t.length, s = 0, l = ''; s < o; ) {
        do {
            e = a[255 & t.charCodeAt(s++)];
        } while (s < o && -1 == e);
        if (-1 == e) break;
        do {
            n = a[255 & t.charCodeAt(s++)];
        } while (s < o && -1 == n);
        if (-1 == n) break;
        l += String.fromCharCode((e << 2) | ((48 & n) >> 4));
        do {
            if (61 == (i = 255 & t.charCodeAt(s++))) return l;
            i = a[i];
        } while (s < o && -1 == i);
        if (-1 == i) break;
        l += String.fromCharCode(((15 & n) << 4) | ((60 & i) >> 2));
        do {
            if (61 == (r = 255 & t.charCodeAt(s++))) return l;
            r = a[r];
        } while (s < o && -1 == r);
        if (-1 == r) break;
        l += String.fromCharCode(((3 & i) << 6) | r);
    }
    return l;
}
export function utf8Encode(t) {
    let e, n, a, i;
    for (e = '', a = t.length, n = 0; n < a; n++)
        (i = t.charCodeAt(n)) >= 1 && i <= 127
            ? (e += t.charAt(n))
            : i > 2047
                ? ((e += String.fromCharCode(224 | ((i >> 12) & 15))),
                  (e += String.fromCharCode(128 | ((i >> 6) & 63))),
                  (e += String.fromCharCode(128 | ((i >> 0) & 63))))
                : ((e += String.fromCharCode(192 | ((i >> 6) & 31))),
                  (e += String.fromCharCode(128 | ((i >> 0) & 63))));
    return e;
}
export function utf8Decode(t) {
    let e, n, a, i, r, s;
    for (e = '', a = t.length, n = 0; n < a; )
        switch ((i = t.charCodeAt(n++)) >> 4) {
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
                e += t.charAt(n - 1);
                break;
            case 12:
            case 13:
                (r = t.charCodeAt(n++)), (e += String.fromCharCode(((31 & i) << 6) | (63 & r)));
                break;
            case 14:
                (r = t.charCodeAt(n++)),
                    (s = t.charCodeAt(n++)),
                    (e += String.fromCharCode(((15 & i) << 12) | ((63 & r) << 6) | ((63 & s) << 0)));
        }
    return e;
}
export function utf16to8(t) {
    return t.replace(/\\x/g, '%');
}
export function utf8to16(t) {
    return t.replace(/%/g, '\\x');
}
