<!-- Created by henian.xu on 2018/8/25. -->

<template>
    <div
        class="grid-group"
        :class="[columns&&`columns-${columns}`,{border,'grid-line':gridLine}]"
    >
        <div class="inner">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'GridGroup',
    componentName: 'XGridGroup',
    data() {
        return {};
    },
    props: {
        columns: {
            type: Number,
            default: 3,
            validator(val) {
                return val === ~~val;
            },
        },
        gridLine: {
            type: Boolean,
            default: true,
        },
        border: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss">
.grid-group {
    overflow: hidden;
    &.border {
        border: 1px solid $color-border;
    }

    &.grid-line {
        .grid {
            border: solid $color-border;
            border-width: 1px 1px 1px 0;
        }
    }

    > .inner {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: wrap;
        margin: -1px;
        //margin-top: -1px;
        //margin-left: -1px;
    }
}
</style>
