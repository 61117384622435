/**
 * Created by henian.xu on 2017/11/27.
 *
 */

import { getRouteKey } from '../utils';

let recoverData = {};
if (window.sessionStorage.VUE_RECOVER_DATA) {
    recoverData = JSON.parse(window.sessionStorage.VUE_RECOVER_DATA);
}

function getContainer(vc) {
    const container = vc.$refs.container;
    if (!container) {
        if (vc.$children.length) {
            return getContainer(vc.$children[0]);
        } else {
            throw new Error('container 节点找不到');
        }
    } else {
        return container;
    }
}

export default {
    data() {
        return {
            recoverData,
        };
    },
    computed: {},
    watch: {
        $route(val, oldVal) {
            // oldVal
            this.cacheNode(getRouteKey(oldVal, this.keyName));
            this.recoverNode(getRouteKey(val, this.keyName));
            // val
        },
    },
    methods: {
        cacheNode(key) {
            const vnode = this.cache[key];
            if (!vnode || !vnode.child) return;
            const pageNode = vnode.child;
            const $container = getContainer(pageNode); // pageNode.$refs.container;
            const $body = $container.$el || $container;
            if (!key) return;
            this.recoverData[key] = {
                scrollHeight: $body && $body.scrollHeight,
                scrollTop: $body && $body.scrollTop,
            };
            this.saveRecoverData();
        },
        recoverNode(key) {
            const vnode = this.cache[key];
            if (!vnode || !vnode.child) return;
            const pageNode = vnode.child;
            const $container = getContainer(pageNode); // pageNode.$refs.container;
            const $body = $container.$el || $container;
            const data = this.recoverData[key];
            if (!data || !key) return;
            this.$nextTick(() => {
                $body.scrollTop = data.scrollTop;
            });
            delete this.recoverData[key];
            this.saveRecoverData();
        },
        saveRecoverData() {
            window.sessionStorage.VUE_RECOVER_DATA = JSON.stringify(this.recoverData);
        },
    },
};
