<!-- Created by henian.xu on 2019/5/14. -->

<!--<template>
    <div class="category-tab-nav">CategoryTabNav</div>
</template>-->

<script>
export default {
    name: 'CategoryTabNav',
    inject: ['Tab'],
    data() {
        return {};
    },
    props: {
        panels: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        currentIndex: {
            get() {
                return this.Tab.currentIndex;
            },
            set(val) {
                this.Tab.currentIndex = val;
            },
        },
    },
    methods: {
        onItem($event, panel, index) {
            this.currentIndex = index;
            // console.log($event, panel, index);
        },
    },
    render() {
        const { currentIndex, panels, onItem } = this;
        return (
            <div class="category-tab-nav beautify-scrollbar">
                {panels.map((panel, index) => {
                    panel.index = index;
                    return (
                        <div
                            class={['item', { active: currentIndex === index }]}
                            key={panel._uid}
                            ref="item"
                            refInFor={true}
                            onClick={$event => onItem($event, panel, index)}
                        >
                            {panel.label}
                        </div>
                    );
                })}
            </div>
        );
    },
};
</script>

<style lang="scss">
.category-tab-nav {
    flex: 0 0 auto;
    min-width: 6em;
    background-color: $gray2;
    overflow-scrolling: touch;
    > .item {
        text-align: center;
        line-height: $formItemHeight;
        padding: $padding-small 0;
        &.active {
            color: $color-main;
            background-color: #fff;
        }
    }
}
</style>
