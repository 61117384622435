/**
 * Created by henian.xu on 2018/6/5.
 *
 */

import Store from '@/store';
import { Buyer } from '@/api';

export default {
    namespaced: true,
    state: {
        countInfoData: void 0,
        storeCountInfoData: {},
    },
    getters: {
        rawOrderCount(state) {
            const { countInfoData } = state;
            if (!countInfoData) {
                Store.dispatch('countInfo/updated');
            }
            return countInfoData || {};
        },
        orderCount(state, getters) {
            const obj = {};
            const orderCount = getters.rawOrderCount.orderCount;

            for (const key in orderCount) {
                if (orderCount.hasOwnProperty(key)) {
                    if (orderCount[key] > 999) {
                        obj[key] = `+999`;
                    } else {
                        obj[key] = orderCount[key];
                    }
                }
            }

            return obj;
        },
        cartCount(state, getters) {
            return getters.rawOrderCount.cartCount;
        },
        storeOrderCount(state) {
            const obj = {};
            const { orderCount } = state.storeCountInfoData;

            for (const key in orderCount) {
                if (orderCount.hasOwnProperty(key)) {
                    if (orderCount[key] > 999) {
                        obj[key] = `+999`;
                    } else {
                        obj[key] = orderCount[key];
                    }
                }
            }

            return obj;
        },
        unAgentGoodsNum(state) {
            return state.storeCountInfoData.unAgentGoodsNum;
        },
    },
    mutations: {
        _setCountInfoData(state, data) {
            state.countInfoData = data;
        },
        _setStoreCountInfoData(state, data) {
            state.storeCountInfoData = data;
        },
    },
    actions: {
        updated({ commit }) {
            return Buyer.Mb.Buyer.countInfo().then(json => {
                const res = json.data;
                commit('_setCountInfoData', res.data);
            });
        },
        updatedStore({ commit }) {
            return Buyer.Sp.ShopStoreOrder.getOrderCountInfo().then(json => {
                const res = json.data;
                commit('_setStoreCountInfoData', res.data);
            });
        },
    },
};
