<!-- Created by henian.xu on 2018/5/10. -->

<template>
    <div class="upload">
        <div
            :class="['item',`column-${column}`]"
            v-for="(item,index) in files"
            :key="index"
            @click="onItem"
        >
            <div
                class="inner"
                :style="`background-image: url('${$options.filters.imgCropping(item.icon||((item.active||item.error)?item.blob:item.address||$defaultImg),{width:400})}');`"
            >
                <div
                    v-if="!isDisabled"
                    class="del"
                    @click="onDel(item,index)"
                >
                    <i class="f-icon">&#xf01a;</i>
                </div>

                <div
                    class="error"
                    v-if="item.error && item.error !== 'compressing'"
                >
                    <div
                        v-if="/(timeout|network)/.test(item.error)"
                        @click="onRetry(item)"
                    >
                        点击重试
                    </div>
                    <i
                        class="f-icon tc-red fs-more"
                        v-else
                        @click="onRetry(item)"
                    >&#xf041;
                    </i>
                </div>
                <div
                    class="progress"
                    v-else-if="item.active || item.error === 'compressing'"
                >
                    <div class="inner" />
                    <div class="label">
                        {{ +item.progress }}%
                    </div>
                </div>
            </div>
        </div>
        <div
            :class="['item','add',column&&`column-${column}`,{'hide':!showAddItem}]"
        >
            <vupload
                :input-id="id"
                class="inner"
                :headers="$globalVar.reqHeaders"
                ref="upload"
                name="file"
                :post-action="action"
                v-model="Vfiles"
                @input-filter="inputFilter"
                @input-file="inputFile"
                :size="size"
                :accept="accept"
            >
                <i class="f-icon">&#xf013;</i>
            </vupload>
        </div>
    </div>
</template>

<script>
import emitter from '@/mixin/emitter';
import vueUploadComponent from 'vue-upload-component';
import Compressorjs from 'compressorjs';
import * as icons from './icons';

export default {
    name: 'Upload',
    components: { vupload: vueUploadComponent },
    mixins: [emitter],
    data() {
        return {
            id: 'up' + this.$utils.Comm.getKey(),
            Vfiles: [],
            files: [],
            isCompressing: false,
        };
    },
    props: {
        value: {
            type: Array,
            default() {
                return [];
            },
        },
        action: {
            type: String,
            required: true,
        },
        size: {
            type: Number,
            default: 8 * 1024 * 1024,
        },
        autoCompress: {
            type: Number,
            default: 2 * 1024 * 1024,
        },
        accept: {
            type: String,
            default: '',
        },
        extensions: {
            type: [Array, String, RegExp],
            default: '',
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        maxFiles: {
            type: Number,
            default: 0,
        },
        column: {
            type: Number,
            default: 0,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        $$upload() {
            return this.$refs.upload;
        },
        filesMap() {
            const obj = {};
            this.files.forEach(item => {
                obj[item.id] = item;
            });
            return obj;
        },
        sizeName() {
            if (this.size < 1024) {
                return this.size + ' b';
            } else if (this.size < 1024 * 1024) {
                return this.size / 1024 + ' kb';
            } else if (this.size < 1024 * 1024 * 1024) {
                return this.size / 1024 / 1024 + ' mb';
            } else if (this.size < 1024 * 1024 * 1024 * 1024) {
                return this.size / 1024 / 1024 / 1024 + ' gb';
            }
            return '';
        },
        isDisabled() {
            const { disabled, readonly } = this;
            return disabled || readonly;
        },
        showAddItem() {
            const { maxFiles, files, isDisabled } = this;
            return !isDisabled && (!maxFiles || maxFiles > files.length);
        },
    },
    watch: {
        value: {
            handler(val) {
                const { files } = this;
                files.forEach(item => {
                    if (!item.active) return;
                    this.$$upload.remove(item.file);
                });
                files.splice(0, files.length);
                val.forEach(item => {
                    files.push({
                        ...item,
                    });
                });
            },
            immediate: true,
        },
    },
    methods: {
        inputFilter(newFile, oldFile) {
            if (!newFile || typeof newFile !== 'object' || oldFile) return;
            if (
                newFile.file &&
                newFile.type.substr(0, 6) === 'image/' &&
                this.autoCompress > 0 &&
                this.autoCompress < newFile.size
            ) {
                newFile.error = 'compressing';
                this.isCompressing = true;
                const self = this;
                console.log(newFile.file.size);
                new Compressorjs(newFile.file, {
                    strict: true,
                    checkOrientation: true,
                    maxWidth: undefined,
                    maxHeight: undefined,
                    minWidth: 0,
                    minHeight: 0,
                    width: undefined,
                    height: undefined,
                    quality: 0.7,
                    mimeType: '',
                    convertSize: Infinity, //5000000,
                    success(file) {
                        console.log(file.size);
                        self.isCompressing = false;
                        self.$$upload.update(newFile, { error: '', file, size: file.size, type: file.type });
                    },
                    error(err) {
                        self.isCompressing = false;
                        self.$$upload.update(newFile, { error: err.message || 'compress' });
                    },
                });
            }
        },
        inputFile(newFile, oldFile) {
            // 添加文件
            if (newFile && !oldFile) {
                this.onAddFile(newFile);
            }

            // 更新文件
            if (newFile && oldFile) {
                // 开始上传
                if (newFile.active && newFile.active !== oldFile.active) {
                    this.onUploadStart(newFile);

                    // 限定最小字节
                    // if (newFile.size >= 0 && newFile.size < 100 * 1024) {
                    //     newFile = this.upload.update(newFile, {error: 'size'});
                    // }
                }

                // 上传进度
                if (newFile.progress !== oldFile.progress) {
                    this.onProgress(newFile, newFile.progress);
                }

                // 上传错误
                if (newFile.error !== oldFile.error && oldFile.error !== 'compressing') {
                    this.onError(newFile, newFile.error);
                }

                // 上传成功
                if (newFile.success !== oldFile.success) {
                    this.onSuccess(newFile, newFile.response);
                }
            }

            // 删除文件
            if (!newFile && oldFile) {
                // 自动删除 服务器上的文件
                if (oldFile.success && oldFile.response.id) {
                    // $.ajax({
                    //   type: 'DELETE',
                    //   url: '/file/delete?id=' + oldFile.response.id,
                    // });
                }
            }

            // 自动上传
            if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
                if (!this.$$upload.active) {
                    this.$$upload.active = true;
                }
            }
        },
        keepOneFile() {
            const { files, maxFiles, $$upload } = this;
            if (maxFiles === 1 && files.length) {
                const item = files[0];
                if (item.active) $$upload.update(item.file, { active: false });
                files.splice(0, 1);
            }
        },
        onAddFile(file) {
            this.keepOneFile();
            // 创建 blob 字段 用于图片预览
            if (!file.blob) {
                file.blob = '';
                const URL = window.URL || window.webkitURL;
                if (URL && URL.createObjectURL) {
                    file.blob = URL.createObjectURL(file.file);
                }
            }
            // console.log('添加文件', file);
            if (!this.filesMap[file.id]) {
                file.icon = this.getIcon(file.name);
                console.log('icon', file.name, file.icon);
                this.files.push({
                    id: file.id,
                    active: file.active,
                    blob: file.blob,
                    progress: file.progress,
                    name: '',
                    address: '',
                    error: '',
                    msg: '',
                    success: '',
                    icon: this.getIcon(file.name),
                    file: file,
                });
            }
        },
        onUploadBefore() {},
        onUploadStart(file) {
            // console.log('开始上传', file.active, file);
            this.replaceFile(file);
        },
        onProgress(file) {
            // console.log('上传进度', progress, file);
            this.replaceFile(file, 'progress');
        },
        onSuccess(file, response) {
            console.log('上传成功', response, file);
            // this.files.push(response.data);
            const { success, msg } = response;
            if (!success) {
                file.error = msg;
            }
            const data = {
                ...file,
                ...response.data,
                msg,
            };
            this.replaceFile(data);
            this.replaceValue();
        },
        onError(file, error) {
            console.log('上传错误', error, file);
            this.replaceFile(file);
        },
        replaceValue() {
            const value = this.files.reduce((pre, curr) => {
                if (
                    (!curr.active && curr.success) ||
                    (!curr.hasOwnProperty('active') && !curr.hasOwnProperty('success'))
                ) {
                    pre.push({
                        name: curr.name,
                        address: curr.address,
                        error: curr.error,
                        msg: curr.msg,
                        icon: curr.icon,
                    });
                }
                return pre;
            }, []);
            this.$emit('input', value);
        },
        replaceFile(file, key) {
            const currFile = this.filesMap[file.id];
            if (key) {
                if (currFile.hasOwnProperty(key)) {
                    currFile[key] = file[key];
                } else {
                    throw new Error('key 是无效的');
                }
            } else {
                for (const key in currFile) {
                    if (key === 'id' || key === 'file' || !currFile.hasOwnProperty(key)) continue;
                    currFile[key] = file[key];
                }
            }
            currFile['file'] = file;
        },
        onRetry(item) {
            // 内置的错误标识 size, extension, abort, server, denied, timeout, network
            // 可重试的 错误
            if (/(timeout|network)/.test(item.error)) {
                this.$$upload.add({ file: item.file.file, id: item.id });
                return;
            }
            switch (item.error) {
                case 'size':
                    this.$messageBox.tips(`超过指定大小(${this.sizeName})`);
                    break;
                case 'extension':
                    this.$messageBox.tips('文件类型错误');
                    break;
                case 'abort':
                    this.$messageBox.tips('用户终止');
                    break;
                case 'server':
                case 'denied':
                    this.$messageBox.tips('服务器错误，请联系管理员');
                    break;
                default:
                    this.$messageBox.tips(item.error);
                    break;
            }
        },
        onDel(item, index) {
            if (item.active) {
                this.$$upload.update(item.file, { active: false });
            }
            this.files.splice(index, 1);
            this.replaceValue();
        },
        getIcon(name) {
            // const reg = /\.[^\.]+$/;
            const fileExt = name.replace(/.+\./, '').toLowerCase();
            // .exec(importUrl)
            if (/jpg|jpeg|png|gif|webp/.test(fileExt)) {
                // 排除图片格式
                return;
            } else if (icons[name]) {
                return icons[name];
            } else if (/xls|xlsx|xltx|xltm|xlsb|xlam|xlsm/.test(fileExt)) {
                return icons.xls;
            }
            return icons.other;
        },
        selectFile() {
            const $input = this.$$upload.$el.querySelector(`#${this.id}`);
            if ($input) $input.click();
        },
        onItem() {
            this.selectFile();
        },
    },
    beforeDestroy() {
        const { files } = this;
        const len = files.length;
        for (let i = len - 1; i >= 0; i--) {
            const item = files[i];
            if (!item.active) return;
            this.$$upload.remove(item.file);
        }
        files.splice(0, len);
    },
};
</script>

<style lang="scss">
.upload {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: $padding/2;
    /*background-color: #fff;*/

    > .item {
        flex: 0 0 auto;
        width: 100%;
        line-height: 0;
        padding: $padding/2;

        @for $i from 1 through 10 {
            &.column-#{$i} {
                width: 100% / $i;
            }
        }

        > .inner {
            line-height: $line-height;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            box-shadow: 0 0.05rem 0.1rem 0 rgba(0, 0, 0, 0.3);

            position: relative;
            width: 100%;
            height: 0;
            padding-top: 50%;
            padding-bottom: 50%;
            border-radius: 0.1rem;

            > .del {
                position: absolute;
                z-index: 300;
                top: -0.15rem;
                right: -0.15rem;
                color: $color-red;
                font-size: 0.4rem;
                line-height: 0.4rem;
                width: 0.4rem;
                text-align: center;
                border-radius: 50%;
                background-color: #fff;
            }

            > .progress {
                position: absolute;
                z-index: 100;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border-radius: 0.1rem;
                background: rgba(0, 0, 0, 0.5);

                > .inner {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 0.8rem;
                    height: 0.8rem;
                    margin-top: -0.4rem;
                    margin-left: -0.4rem;
                    box-sizing: border-box;
                    border: solid 5px transparent;
                    border-top-color: $color-main;
                    border-left-color: $color-main;
                    border-radius: 50%;

                    animation: nprogress-spinner 400ms linear infinite;
                }

                > .label {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    font-size: 0.26rem;
                    color: #fff;
                }
            }

            > .error {
                position: absolute;
                z-index: 200;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border-radius: 0.1rem;
                background: rgba(0, 0, 0, 0.5);
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                color: #fff;
            }
        }

        &.add {
            // 为了解决在 iphone 6  ios:12.3.1 切换 display: none; 时会导致整屏不显示的问题
            &.hide {
                position: absolute;
                left: -200vw;
                top: -200vh;
            }

            > .inner {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                background-color: $gray2;
                box-shadow: none;

                &:before {
                    display: inline-block;
                    content: '';
                    width: 0;
                    height: 0;
                    padding-top: 100%;
                    vertical-align: middle;
                }

                > .f-icon {
                    vertical-align: middle;
                    color: $gray6;
                    font-size: 20px;
                }
            }
        }
    }
}
</style>
