<!-- Created by henian.xu on 2018/7/8. -->

<template>
    <div
        class="shop-picker"
        @click.stop.prevent="onSwitch"
    >
        <template v-if="!label">
            <div
                class="shop-select"
                v-if="!value.id"
            >
                <i class="f-icon add">&#xf015;</i>
                <span>选择门店</span>
                <i class="f-icon">&#xf012;</i>
            </div>
            <div
                class="shop-info"
                v-else
            >
                <i class="f-icon">&#xf00a;</i>
                <div class="inner">
                    <div class="header">
                        <div class="name">
                            {{ value.storeName }}
                        </div>
                    </div>
                    <div class="body">
                        <dl>
                            <dt>姓名：</dt>
                            <dd>{{ value.contactFullname }}</dd>
                        </dl>
                        <dl>
                            <dt>电话：</dt>
                            <dd>
                                <a
                                    :href="`tel:${value.contactTel}`"
                                    class="tc-blue"
                                >{{ value.contactTel }}</a>
                            </dd>
                        </dl>
                        <dl>
                            <dt>地址：</dt>
                            <dd>
                                {{ value.storeDetailAddress }}<br>{{ value.storeRegionName }}
                            </dd>
                        </dl>
                    </div>
                </div>
            </div>
        </template>
        <div
            v-else
            v-html="label"
        />
        <transition :name="aniName">
            <Page
                class="bc-g2"
                ref="popup"
                v-show="popupShow"
            >
                <div class="header">
                    <div class="ctrl-left">
                        <div
                            class="btn"
                            @click="$router.go(-1);"
                        >
                            <i class="f-icon">&#xf011;</i>
                        </div>
                    </div>
                    <div class="label">
                        选择门店
                    </div>
                    <div class="ctrl-right">
                        <div class="btn" />
                    </div>
                </div>
                <region-picker
                    ref="regionPicker"
                    class="second-header"
                    @input="onRegionInput"
                    max-lv="3"
                    p-id="0"
                    :query-data="{isRelatedShopStore:1}"
                    v-model="storeRegionId"
                />
                <container>
                    <list v-if="shopList.length">
                        <shop-item
                            v-for="(item) in shopList"
                            :key="item.id"
                            :data="item"
                            @click="onItem(item)"
                        />
                    </list>
                    <div
                        class="ta-c tc-g6 pa-a"
                        v-else
                    >
                        请选择区域筛选门店
                    </div>
                </container>
            </Page>
        </transition>
    </div>
</template>

<script>
import popup from '@/mixin/popup';
import emitter from '@/mixin/emitter';
import { mapState } from 'vuex';

export default {
    name: 'ShopPicker',
    mixins: [popup, emitter],
    data() {
        return {
            appLevel: true,
            shopList: [],
            storeRegionId: '',
        };
    },
    props: {
        value: {
            type: Object,
            default() {
                return {};
            },
        },
        label: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState('global', ['location']),
        $$regionPicker() {
            return this.$refs.regionPicker;
        },
    },
    watch: {
        popupShow(val) {
            if (val) {
                console.log(this.location);
                if (!this.shopList.length) {
                    if (this.location) {
                        this.getListData();
                    } else {
                        // this.$$regionPicker.open();
                    }
                }
            }
        },
    },
    methods: {
        getListData() {
            return this.$api.Buyer.Sp.Store.shopStoreList({
                storeRegionId: this.storeRegionId,
                ...this.location,
            }).then(json => {
                const res = json.data.data;
                this.shopList = res.shopStoreList;
                this.storeRegionId = res.storeRegionId;
                return Promise.resolve(json);
            });
        },
        onItem(item) {
            this.$emit('input', item);
            this.popupShow = false;
        },
        onRegionInput() {
            this.shopList = [];
            this.getListData();
        },
    },
};
</script>

<style lang="scss">
.shop-picker {
    flex: 1 1 auto;
}

.shop-select {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    // border-bottom: 1px solid $color-border;
    padding: $padding;

    > span {
        flex: 1 1 auto;
        display: inline-block;
        padding-left: $padding;
    }

    > .f-icon {
        font-size: 120%;
        width: 1em;
        text-align: center;
        color: $gray6;

        &.add {
            font-size: 0.5rem;
            color: $color-blue;
        }
    }
}

.shop-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #fff url('~@/assets/images/comm/address-border.png') left bottom repeat-x;
    background-size: auto 4px;
    padding: $padding;
    //margin-bottom: $margin-big;

    > .f-icon {
        align-self: flex-start;
        width: 0.4rem;
        font-size: 0.4rem;
        text-align: center;
    }
    > .inner {
        width: 100%;
        //flex-grow: 1;
        padding-left: $padding;
        > .header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            line-height: 2;
            font-size: 0.26rem;
        }
        > .body {
            dl {
                @include clearfix;
                dt {
                    float: left;
                    text-align: justify;
                    width: 5em;
                    height: $body-font-size * $line-height;
                    overflow: hidden;
                    &:after {
                        content: '';
                        display: inline-block;
                        padding-left: 100%;
                        height: 0;
                    }
                }
                dd {
                    overflow: hidden;
                }
            }
        }
    }
}
</style>
