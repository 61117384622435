<!-- Created by henian.xu on 2018/6/25. -->

<template>
    <div
        class="icon"
        :class="column && 'column'"
        v-bind="linkerProps"
        v-on="$listeners"
    >
        <slot name="code">
            <div
                class="icon-wrap"
                v-if="mark"
            >
                <i
                    class="f-icon"
                    :class="[iconCls,{url}]"
                    :style="url && `background-image: url('${url}');`"
                    v-html="codeStr"
                />
                <span
                    class="mark"
                >{{ mark }}</span>
            </div>
            <i
                v-else
                class="f-icon"
                :class="[iconCls,{url}]"
                :style="url && `background-image: url('${url}');`"
                v-html="codeStr"
            />
        </slot>
        <slot name="label">
            <div
                v-if="label"
                class="label"
                :class="[labelCls]"
                v-html="label"
            />
        </slot>
    </div>
</template>

<script>
import linkerMixin from '@/mixin/linker';

export default {
    name: 'Icon',
    mixins: [linkerMixin],
    data() {
        return {};
    },
    props: {
        code: {
            type: String,
            default: '',
        },
        url: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        column: {
            type: Boolean,
            default: false,
        },
        iconCls: {
            type: String,
            default: '',
        },
        labelCls: {
            type: String,
            default: '',
        },
        mark: {
            type: [Number, String],
            default: '',
        },
    },
    computed: {
        codeStr() {
            if (!this.url) return this.code;
            return '&#xf001;';
        },
    },
};
</script>

<style lang="scss">
.icon {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    line-height: 1;

    > .icon-wrap {
        position: relative;
        > .mark {
            position: absolute;
            left: 50%;
            top: 0;
            transform: translateY(-50%);
            padding: 0 0.5em;
            background-color: $color-main;
            color: #fff;
            font-size: 85%;
            line-height: $line-height;
            //border-radius: $body-font-size * 0.7 * $line-height/2;
            border-radius: 100vw;
            white-space: nowrap;
        }
    }

    .f-icon {
        line-height: 1;
        background: no-repeat center center;
        background-size: cover;
    }

    > .label {
        line-height: 1.2;
        // line-height: $line-height;
        max-width: 100%;
        @include text-line(1);
    }

    &.column {
        flex-direction: column;
        > .label {
            padding-top: $padding-small;
        }
    }
}
</style>
