<!-- Created by henian.xu on 2019/5/16. -->

<template>
    <div class="proxy-store-sku-editor">
        <div class="sku-editor-tips">
            <span>商品信息</span>
            <span>商品加价金额</span>
        </div>
        <div
            class="item"
            v-for="item in data"
            :key="item.id"
        >
            <div class="img-obj">
                <div
                    class="inner"
                    :style="`background-image: url('${item.specImageUrl||$defaultImg}');`"
                />
            </div>
            <div class="label">
                <div>
                    {{ item.specDesc }}
                </div>
                <div class="price">
                    {{ item.price }}
                </div>
            </div>
            <div class="ctrl">
                <!--<span class="tc-price ma-r">{{ $globalVar.countryData.currency }}</span>-->
                <InputNumber
                    :min="0"
                    v-model="formModel[item.id]"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProxyStoreSkuEditor',
    data() {
        return {
            formModel: {},
        };
    },
    props: {
        value: {
            type: String,
            default: '',
        },
        data: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        valueList() {
            if (!this.model) return [];
            return JSON.parse(this.model);
        },
        dataMap() {
            return this.data.reduce((p, c) => ((p[c.skuId] = c), p), {});
        },
        formModelJson() {
            const { formModel, dataMap } = this;
            return JSON.stringify(
                Object.keys(formModel).reduce((p, c) => {
                    p.push({
                        skuId: c,
                        addPrice: formModel[c],
                        price: dataMap[c].price,
                    });
                    return p;
                }, []),
            );
        },
    },
    watch: {
        data: {
            handler(val) {
                this.formModel = val.reduce((p, c) => ((p[c.skuId] = c.addPrice || 0), p), {});
                console.log(333, this.formModel, val);
            },
            immediate: true,
        },
        formModelJson: {
            handler(val) {
                this.model = val;
            },
            immediate: true,
        },
    },
};
</script>

<style lang="scss">
.sku-editor-tips {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: $color-red;
}
.proxy-store-sku-editor {
    flex: 1 1 1%;
    > .item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        + .item {
            margin-top: $margin;
        }
        > .img-obj {
            flex: 0 0 auto;
            width: 1rem;
            margin-right: $margin-small;
            > .inner {
                width: 100%;
                height: 0;
                padding-top: 100%;
                background: center no-repeat;
                background-size: cover;
            }
        }
        > .label {
            flex: 1 1 1%;
        }
        > .ctrl {
            flex: 0 0 auto;
        }
    }
}
</style>
