<!-- Created by henian.xu on 2018/2/5. -->

<template>
    <div
        :class="['address-select',{'empty':!addrPath}]"
        @click.self="onClick"
    >
        {{ addrPath||'请选择区域' }}

        <!-- popup -->
        <transition :name="aniName">
            <Page
                class="bc-g2"
                style="z-index: 800;"
                ref="popup"
                v-show="popupShow"
            >
                <breadcrumb
                    class="second-header"
                    :data="breadcrumbData"
                    @clickItem="onBreadcrumbItem"
                >
                    <div
                        class="item"
                        v-show="breadcrumbSelectShow"
                    >
                        请选择
                    </div>
                </breadcrumb>
                <container>
                    <list>
                        <list-item
                            v-for="(item) in itemList"
                            :key="item.id"
                            :label="item.name"
                            @click="onItem(item)"
                        >
                            <div
                                class="extra"
                                slot="extra"
                                v-show="item.id === currItem.id"
                            >
                                <i class="f-icon tc-green">&#xf017;</i>
                            </div>
                        </list-item>
                    </list>
                </container>
            </Page>
        </transition>
    </div>
</template>

<script>
import mixinPopup from '@/mixin/popup';
import emitter from '@/mixin/emitter';

export default {
    name: 'RegionPicker',
    mixins: [mixinPopup, emitter],
    data() {
        return {
            appLevel: true,
            itemList: [],
            currItem: {},
            breadcrumbData: [],
            breadcrumbSelectShow: true,
            isEmitInput: false,
        };
    },
    props: {
        value: {
            type: [Number, String],
            default: '',
        },
        pId: {
            type: String,
            default: '',
        },
        maxLv: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        queryData: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    computed: {
        _popup() {
            return this.$refs['popup'];
        },
        addrPath() {
            return this.breadcrumbData
                .reduce((path, item) => {
                    return path + '>' + item.name;
                }, '')
                .replace(/^>/, '');
        },
    },
    watch: {
        value(val) {
            if (this.isEmitInput) {
                this.isEmitInput = false;
                return;
            }
            if (val) {
                this.loadAllArea();
            } else {
                this.breadcrumbData = [];
                this.getChild();
            }
            this.dispatch('formItem', 'x.form.change', [this.currItem.id]);
        },
    },
    methods: {
        onClick() {
            if (this.disabled) return;
            this.popupShow = !this.popupShow;
            this.$$popupWrap.appendChild(this.$$popup.$el);
        },
        getChild({ pId = this.pId, lv, maxLv = this.maxLv } = {}) {
            this.itemList = [];
            this.$api.Comm.Rs.Region.getChild({
                queryData: this.queryData,
                pId,
                lv,
                maxLv,
            }).then(json => {
                const result = json.data;
                if (!result.data.length) {
                    this.breadcrumbSelectShow = false;
                    if (this.popupShow) this.doClose();
                    return;
                }
                this.breadcrumbSelectShow = true;
                this.itemList = result.data;
            });
        },
        loadAllArea(pId = this.pId) {
            this.$api.Comm.Rs.Region.loadAllArea({
                queryData: this.queryData,
                pId,
                id: this.value,
            }).then(json => {
                const result = json.data;
                this.breadcrumbData = [];
                result.data.forEach((item, index) => {
                    let addr;
                    for (let i = 0; i < item.length; i++) {
                        addr = item[i];
                        if (addr.isSelect) {
                            addr.active = true;
                            this.breadcrumbData.push(addr);
                            break;
                        }
                    }
                    if (result.data.length - 1 === index) {
                        this.currItem = addr;
                        this.itemList = item;
                    }
                    this.breadcrumbSelectShow = false;
                });
            });
        },
        doClose() {
            this.popupShow = false;
            this.isEmitInput = true;
            this.$emit('input', this.currItem.id);
            this.dispatch('formItem', 'x.form.change', [this.currItem.id]);
        },
        onBreadcrumbItem(item, index) {
            if (!this.breadcrumbSelectShow && this.breadcrumbData.length - 1 === index) return;
            this.currItem = index - 1 >= 0 ? this.breadcrumbData[index - 1] : {};
            this.breadcrumbData.splice(index, this.breadcrumbData.length - index);
            this.getChild({ pId: this.currItem.id });
        },
        onItem(item) {
            if (this.currItem.id === item.id) return;
            if (!this.breadcrumbSelectShow) {
                this.breadcrumbData.pop();
                this.breadcrumbData.push({
                    ...item,
                    active: true,
                });
            } else {
                this.breadcrumbData.push({
                    ...item,
                    active: true,
                });
            }
            this.currItem = item;
            this.getChild({ pId: item.id });
        },
        getNamePath() {
            return this.addrPath.replace(/>/g, ' ');
        },
    },
    created() {
        if (this.value) {
            this.loadAllArea();
        } else {
            this.getChild();
        }
    },
    mounted() {},
};
</script>

<style lang="scss">
.address-select {
    padding: 0 $padding-small;
    flex: 1 1 auto;
    &.empty {
        opacity: 0.75;
    }
}

.second-header.address-select {
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 $padding;
}
</style>
