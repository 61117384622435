/**
 * Created by henian.xu on 2018/4/19.
 *
 */

const dis = {
    name: 'distributorName',
    regionName: 'distributorRegionName',
    regionId: 'distributorRegionId',
    mobile: 'distributorTel',
};
const dis2 = {
    name: 'deliveryConsignee',
    mobile: 'deliveryPhone',
    regionId: 'deliveryRegionId',
    address: 'deliveryAddress',
    msg: 'buyerMsg',
};

const address = {
    mobile: 'phone',
};

const goods = {
    id: 'goodsId',
    img: 'mainImageUrl', // 商品主图
    imgList: 'goodsImageList', // 商品列表图
    detailImageList: 'goodsDetailImageList', // 商品详情图
    name: 'goodsName', // 商品名称
    desc: 'goodsSubtitle', // 商品描述
    // price: 'goodsPrice', // 商品价格
    // marketPrice: 'marketPrice', // 市场价
    startTimestamps: 'activityBeginTime', // 开始时间
    endTimestamps: 'activityEndTime', // 结束时间
    limitQuantity: 'totalSalableQuantity', // 限量
    sellQuantity: 'totalSellQuantity', // 已抢数量
};
const point = {};
const order = {
    pointCase: {
        key: 'buyerBuyPointCase',
        children: point,
    },
    address: {
        key: 'buyerDeliveryAddress',
        children: address,
    },
    goodsList: {
        key: 'orderDetailList',
        children: {
            ...goods,
            id: 'id',
        },
    },
};
const cartGoods = {
    ...goods,
};
delete cartGoods.id;
const refund = {
    goodsList: {
        key: 'orderDetailList',
        children: {
            ...goods,
            id: 'id',
        },
    },
};
const buyer = {
    name: 'fullName',
};

const adSwiper = {
    img: 'imgUrl',
};

export default {
    goods,
    order,
    cartGoods,
    dis,
    dis2,
    address,
    refund,
    buyer,
    adSwiper,
};
