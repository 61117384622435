<!-- Created by henian.xu on 2018/8/25. -->

<!--<template>
    <div
        class="grid"
        v-bind="linkerProps"
        :style="styles"
    >
        &lt;!&ndash;:class="span&&`span-${span}`"&ndash;&gt;
        <slot name="icon">
            <i
                class="icon f-icon"
                :class="iconClassName"
                v-if="icon"
                v-html="icon"/>
        </slot>
        <slot name="label">
            <div
                class="label"
                v-if="label">{{ label }}</div>
        </slot>
        <slot name="sub-label">
            <div
                class="sub-label"
                v-if="subLabel">{{ subLabel }}</div>
        </slot>
    </div>
</template>-->

<script>
import linkerMixin from '@/mixin/linker';
import { Label } from '@/components/label';

export default {
    name: 'Grid',
    componentName: 'XuGrid',
    mixins: [linkerMixin],
    extends: Label,
    data() {
        return {};
    },
    props: {
        span: {
            type: Number,
            default: 0,
        },
        label: {
            type: String,
            default: '',
        },
        subLabel: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
            default: '',
        },
        iconClassName: {
            type: String,
            default: '',
        },
    },
    computed: {
        classes() {
            return ['grid', { disabled: this.disabled }];
        },
        styles() {
            if (!this.span) return {};
            const res = (100 / this.$parent.columns) * this.span;
            return {
                width: (res > 100 ? 100 : res) + '%',
            };
        },
        bindProps() {
            return this.linkerProps;
        },
        listenerProps() {
            const { disabled, $listeners, isCommand } = this;
            return disabled
                ? {}
                : {
                      ...$listeners,
                      ...(!isCommand
                          ? {}
                          : {
                                click: (...agr) => {
                                    this.onCommand(agr);
                                    $listeners.click && $listeners.click(agr);
                                },
                            }),
                  };
        },
    },
    methods: {},
};
</script>

<style lang="scss">
.grid-group {
    @for $i from 1 through 10 {
        &.columns-#{$i} > .inner > .grid {
            flex: 0 0 auto;
            width: 100% / $i;
        }
    }
    .grid {
        flex-direction: column;
        justify-content: flex-start;
        padding: $padding-big $padding;

        //border: solid $color-border;
        //border-width: 1px 1px 1px 0;
        margin-top: -1px;
        /*border-width: 1px 0 0 1px;*/
        text-align: center;

        > .f-icon {
            font-size: 160%;
            margin: 0 0 ($margin-small) 0;
        }

        > .icon {
            .mark {
                font-size: 50%;
            }
        }
        > .label {
            //margin-top: $margin-small / 2;
        }
        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
}
</style>
