<!-- Created by henian.xu on 2019/8/8. -->

<template>
    <div class="proxy-store-batch-edit-goods">
        <div
            class="out-show"
            @click="onSwitch"
        >
            <slot />
        </div>

        <transition name="show">
            <div
                v-show="popupShow"
                ref="popup"
                class="popup down selector-popup"
                @click.self="onSwitch"
            >
                <transition name="popup-down">
                    <div
                        class="inner"
                        v-show="popupShow"
                    >
                        <div class="body">
                            <XForm
                                ref="form"
                                :model="formModel"
                                :rules="formRules"
                            >
                                <FormItem
                                    v-if="(!type||type===1)&&catSelectList.length"
                                    key="goodsCatIds"
                                    label="分类:"
                                    prop="goodsCatIds"
                                >
                                    <Selector
                                        :data="catSelectList"
                                        placeholder="请选择店铺分类,可以多选"
                                        multiple
                                        v-model="formModel.goodsCatIds"
                                    />
                                </FormItem>
                                <template v-if="!type||type===2">
                                    <FormItem
                                        label="加价类型:"
                                        prop="addPriceType"
                                    >
                                        <Selector
                                            :data="[{id:1,name:'固定加价'},{id:2,name:'按比例加价'}]"
                                            tiling
                                            v-model="formModel.addPriceType"
                                        />
                                    </FormItem>
                                    <FormItem
                                        prop="addPrice"
                                        label="加价:"
                                    >
                                        <XInput v-model="formModel.addPrice" />
                                        <span v-if="formModel.addPriceType===2">%</span>
                                    </FormItem>
                                </template>
                                <FormItem
                                    v-if="isAdd"
                                    key="desc"
                                >
                                    <span class="tc-red">批量添加只能使用默认风格</span>
                                </FormItem>
                            </XForm>
                        </div>
                        <div class="footer">
                            <Button
                                label="确定"
                                theme="main"
                                @click="()=>{onSubmit()}"
                            />
                        </div>
                    </div>
                </transition>
            </div>
        </transition>
    </div>
</template>

<script>
import popupMixin from '@/mixin/popup';
export default {
    name: 'ProxyStoreBatchEditGoods',
    mixins: [popupMixin],
    data() {
        return {
            formModel: {
                goodsCatIds: [],
                addPriceType: 1, //1:固定加价2:按比例加价
                addPrice: 0,
            },
            formRules: {
                goodsCatIds: {
                    type: 'array',
                    min: 1,
                    required: true,
                    message: '不能为空',
                },
            },
        };
    },
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
        catSelectList: {
            type: Array,
            default: () => [],
        },
        // 1:修改类目;2:修改价格;0:都有
        type: {
            type: Number,
            default: 0,
        },
        isAdd: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(val) {
                if (typeof val !== 'object' || Array.isArray(val)) return;
                this.$emit('input', val);
            },
        },
    },
    methods: {
        async onSubmit() {
            await this.$refs['form'].validate();
            const { formModel } = this;
            await this.done();
            this.model = {
                ...formModel,
                addPrice: +formModel.addPrice || 0,
                // goodsCatIds: formModel.goodsCatIds.join(','),
            };
            this.$refs['form'].resetFields();
        },
    },
};
</script>

<style lang="scss">
.proxy-store-batch-edit-goods {
    flex: 1 1 1%;
    > .out-show {
        min-height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        > .button {
            flex: 1 1 1%;
        }
    }
}
</style>
