/**
 * Created by henian.xu on 2017/11/8.
 * 监控路由
 */

import Routes from './routes';
import { getRouteKey } from './utils';

export default (bus, store, moduleName, keyName) => {
    const isStore = !!store;
    if (isStore) {
        // 注册store
        store.registerModule(moduleName, {
            state: {
                routes: Routes,
            },
            mutations: {
                // 前进/添加的
                'navigation/FORWARD': (state, { /*to, from,*/ name }) => {
                    state.routes.push(name);
                },
                // 退后删除的
                'navigation/BACK': (state, { /*to, from,*/ count }) => {
                    state.routes.splice(state.routes.length - count, count);
                },
                // 替换取代的
                'navigation/REPLACE': (state, { /*to, from,*/ name }) => {
                    state.routes.splice(Routes.length - 1, 1, name);
                },
                // 更新恢复的
                'navigation/REFRESH': (/*state, { to, from }*/) => {},
                // 清空重置的
                'navigation/RESET': state => {
                    state.routes.splice(0, state.routes.length);
                },
            },
        });
    }

    /**
     * 保存导航session
     * @param routes
     */
    function setSession(routes) {
        window.sessionStorage.VUE_NAVIGATION = JSON.stringify(routes);
    }

    /**
     * 前进/添加的
     * @param name
     * @param toRoute
     * @param fromRoute
     */
    const forward = (name, toRoute, fromRoute) => {
        const to = { route: toRoute };
        const from = { route: fromRoute };
        const routes = isStore ? store.state[moduleName].routes : Routes;
        // if from does not exist, it will be set null
        from.name = routes[routes.length - 1] || null;
        to.name = name;
        isStore ? store.commit('navigation/FORWARD', { to, from, name }) : routes.push(name);
        setSession(routes);
        bus.$emit('forward', to, from);
    };
    /**
     * 退后删除的
     * @param count
     * @param toRoute
     * @param fromRoute
     */
    const back = (count, toRoute, fromRoute) => {
        const to = { route: toRoute };
        const from = { route: fromRoute };
        const routes = isStore ? store.state[moduleName].routes : Routes;
        from.name = routes[routes.length - 1];
        to.name = routes[routes.length - 1 - count];
        isStore ? store.commit('navigation/BACK', { to, from, count }) : routes.splice(Routes.length - count, count);
        setSession(routes);
        bus.$emit('back', to, from);
    };
    /**
     * 替换取代的
     * @param name
     * @param toRoute
     * @param fromRoute
     */
    const replace = (name, toRoute, fromRoute) => {
        const to = { route: toRoute };
        const from = { route: fromRoute };
        const routes = isStore ? store.state[moduleName].routes : Routes;
        // if from does not exist, it will be set null
        from.name = routes[routes.length - 1] || null;
        to.name = name;
        isStore ? store.commit('navigation/REPLACE', { to, from, name }) : routes.splice(Routes.length - 1, 1, name);
        setSession(routes);
        bus.$emit('replace', to, from);
    };
    /**
     * 更新恢复的
     * @param toRoute
     * @param fromRoute
     */
    const refresh = (toRoute, fromRoute) => {
        const to = { route: toRoute };
        const from = { route: fromRoute };
        const routes = isStore ? store.state[moduleName].routes : Routes;
        to.name = from.name = routes[routes.length - 1];
        isStore && store.commit('navigation/REFRESH', { to, from });
        bus.$emit('refresh', to, from);
    };
    /**
     * 清空重置的
     */
    const reset = () => {
        isStore ? store.commit() : Routes.splice(0, Routes.length);
        setSession([]);
        bus.$emit('reset');
    };

    const record = (toRoute, fromRoute, replaceFlag) => {
        const name = getRouteKey(toRoute, keyName);
        if (replaceFlag) {
            // 替换取代的
            replace(name, toRoute, fromRoute);
        } else {
            const toIndex = Routes.lastIndexOf(name);
            if (toIndex === -1) {
                // 前进添加的
                forward(name, toRoute, fromRoute);
            } else if (toIndex === Routes.length - 1) {
                // 更新恢复的
                refresh(toRoute, fromRoute);
            } else {
                // 退后删除的
                back(Routes.length - 1 - toIndex, toRoute, fromRoute);
            }
        }
    };

    return {
        record,
        reset,
    };
};
