<!-- Created by henian.xu on 2019/3/5. -->

<template>
    <div
        class="store-goods-item"
        v-bind="linkerProps"
        v-on="$listeners"
    >
        <div class="img-obj">
            <div
                class="inner"
                :style="`background-image: url('${data.mainImageUrl||data.specImageUrl||$defaultImg}');`"
            />
        </div>
        <div class="inner">
            <div class="body">
                <div class="label">
                    {{ data.goodsName }}
                </div>

                <div
                    class="sku"
                    v-if="data.specDesc"
                >
                    <span>{{ data.specDesc }}</span>
                </div>
            </div>
            <div class="footer">
                <div
                    class="edit-price"
                    v-if="isEditPrice"
                >
                    <span class="badge badge-main fs-small">进货价</span>&nbsp;<span class="price">{{ data.rawPrice | price }}</span>&nbsp;
                    <InputNumber
                        :min="data.rawPrice"
                        v-model="data.finalPrice"
                        @minusdisabled="()=>{$messageBox.tips('不能低于进货价',3000)}"
                    />
                </div>
                <div
                    v-else
                    class="price"
                >
                    {{ data.finalPrice | price }}
                </div>
                <div v-if="data.quantity">
                    x {{ data.quantity }}
                </div>
                <refund-popup
                    v-if="!onCanRefund && data.isCanRefund"
                    is-shop-store
                    :order-detail-id="data.id"
                    class="btn btn-main small link bor radius all"
                >
                    申请售后
                </refund-popup>
            </div>
        </div>
    </div>
</template>

<script>
import linkerMixin from '@/mixin/linker';
export default {
    name: 'StoreGoodsItem',
    mixins: [linkerMixin],
    data() {
        return {};
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
        onCanRefund: {
            type: Boolean,
            default: false,
        },
        isEditPrice: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss">
.store-goods-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    background-color: #fff;
    padding: $padding;
    + .store-goods-item {
        margin-top: $margin;
    }
    > .img-obj {
        flex: 0 0 auto;
        width: 1.6rem;
        margin-right: $margin;
        > .inner {
            width: 100%;
            padding-top: 100%;
            background: transparent center no-repeat;
            background-size: cover;
        }
    }
    > .inner {
        flex: 1 1 1%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;

        > .body {
            flex: 1 1 1%;

            > .label {
                @include text-line(2);
            }
            > .sku {
                > span {
                    display: inline-block;
                    font-size: 0.22rem;
                    line-height: 1.5;
                    padding: 0 $padding-small;
                    border: 1px solid $color-main;
                    color: $color-main;
                    border-radius: (0.22rem * 1.5)/2;
                }
            }
        }
        > .footer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }
}
</style>
