<script>
import QRCode from 'qrcode';
import visaIcon from '@/assets/images/icon/visa-icon.png';

export default {
    data() {
        return {
            device: this.$globalVar.device,
            orderIdsChange: false,
            payData: {},
            payInfo: {},
            currentAction: null,
        };
    },
    props: {
        orderIds: {
            type: [Number, String],
            required: true,
        },
        lazy: {
            type: Boolean,
            default: false,
        },
        // 1:订单;2:充值卡;3:分销高;
        module: {
            type: Number,
            required: true,
        },
    },
    computed: {
        paymentType() {
            return +this.payData.paymentType;
        },
        isOnline() {
            return this.payData.isOnline;
        },
        isOffline() {
            return this.payData.isOffline;
        },

        stripeCardInfoList() {
            return this.payData.stripeCardInfoList;
        },

        currency() {
            return this.$store.getters['global/currency'];
        },
        paymentPoundageMap() {
            const { payData, currency } = this;
            const { paymentPoundageMap } = payData;
            Object.keys(paymentPoundageMap || []).forEach(key => {
                const item = paymentPoundageMap[key];
                paymentPoundageMap[key] = {
                    // paymentPoundage: 1,
                    // paymentPoundageRate: 1,
                    ...item,
                    exportStr: `需额外收取 ${item.paymentPoundageRate}% 手续费，此次共计手续费 ${currency}${
                        item.paymentPoundage
                    }`,
                };
            });
            return paymentPoundageMap || {};
        },

        payMethods() {
            const res = [];
            let i = 0;
            let type = 2 ** i;
            const { payData, paymentType, paymentPoundageMap, isOnline, isOffline /*device*/ } = this;
            const weixinObj = {
                icon: '&#xf080;',
                iconClassName: 'tc-green',
                label: '微信支付',
            };
            const alipayObj = {
                icon: '&#xf085;',
                iconClassName: 'tc-blue',
                label: '支付宝支付',
            };
            while (isOnline && type <= paymentType) {
                const exportStr = paymentPoundageMap[type] ? paymentPoundageMap[type].exportStr : '';
                switch (type) {
                    //微信支付
                    case 2 & paymentType: {
                        res.push({
                            ...weixinObj,
                            paymentType: type,
                            action: 'weixinPay',
                            exportStr,
                        });
                        break;
                    }
                    // 通联支付
                    case 8 & paymentType: {
                        res.push({
                            ...weixinObj,
                            paymentType: type,
                            action: 'allinPay',
                            exportStr,
                        });
                        break;
                    }
                    // alphaPay
                    case 16 & paymentType: {
                        res.push({
                            ...weixinObj,
                            paymentType: type,
                            action: 'alphaPayWeixin',
                            exportStr,
                        });
                        res.push({
                            ...alipayObj,
                            paymentType: type,
                            action: 'alphaPayAlipay',
                            exportStr,
                        });
                        break;
                    }
                    // alipay
                    case 32 & paymentType: {
                        res.push({
                            ...alipayObj,
                            paymentType: type,
                            action: 'alipay',
                            exportStr,
                        });
                        break;
                    }
                    // 中投科信 umb
                    case 128 & paymentType: {
                        res.push({
                            ...weixinObj,
                            paymentType: type,
                            action: 'umbPayWeixin',
                            exportStr,
                        });
                        res.push({
                            ...alipayObj,
                            paymentType: type,
                            action: 'umbPayAlipay',
                            exportStr,
                        });
                        break;
                    }
                    // hantePay
                    case 256 & paymentType: {
                        res.push({
                            ...weixinObj,
                            paymentType: type,
                            action: 'hantePayWeixin',
                            exportStr,
                        });
                        res.push({
                            ...alipayObj,
                            paymentType: type,
                            action: 'hantePayAlipay',
                            exportStr,
                        });
                        break;
                    }
                    // citconPay
                    case 512 & paymentType: {
                        res.push({
                            ...weixinObj,
                            paymentType: type,
                            action: 'citconPayWeixin',
                            exportStr,
                        });
                        res.push({
                            ...alipayObj,
                            paymentType: type,
                            action: 'citconPayAlipay',
                            exportStr,
                        });
                        break;
                    }
                    // supayTech
                    case 16384 & paymentType: {
                        res.push({
                            ...weixinObj,
                            paymentType: type,
                            action: 'supayTechPayWeixin',
                            exportStr,
                        });
                        /*res.push({
                            ...alipayObj,
                            paymentType: type,
                            action: 'supayTechPayAlipay',
                            exportStr,
                        });*/
                        break;
                    }
                    // stripe
                    case 64 & paymentType: {
                        res.push({
                            label: '信用卡快捷支付',
                            paymentType: type,
                            iconClassName: 'visa-icon',
                            iconUrl: visaIcon,
                            action: 'stripePay',
                            exportStr,
                        });
                        break;
                    }
                    // 零钱支付
                    case 1024 & paymentType: {
                        res.push({
                            icon: '&#xf03c;',
                            iconClassName: payData.isPurseBalance ? 'tc-blue' : '',
                            label: `零钱(剩余: ${payData.purseBalance})` + (payData.isPurseBalance ? '' : '余额不足'),
                            extra: payData.isPurseBalance ? '' : '点击充值',
                            action: 'pocketMoneyPay',
                            exportStr,
                        });
                        break;
                    }
                }
                type = 2 ** ++i;
            }
            if (isOffline) {
                res.push({
                    icon: '&#xf084;',
                    iconClassName: 'tc-blue',
                    label: '线下支付',
                    action: 'offlinePay',
                });
            }
            return res.sort((a, b) => {
                const aa = a.paymentType === 64 ? a.paymentType + 512 : a.paymentType;
                const bb = b.paymentType === 64 ? b.paymentType + 512 : b.paymentType;
                return aa - bb;
                /*if (a.paymentType === 64) {
                    return a.paymentType + 513 - b.paymentType;
                }
                return a.paymentType - b.paymentType;*/
            });
        },

        paymentPoundage() {
            return this.payInfo.paymentPoundage || {};
        },
    },
    watch: {
        orderIds: {
            handler(val, oldVal) {
                if (!val || val === oldVal) return;
                if (this.lazy) {
                    this.orderIdsChange = true;
                } else {
                    this.toPay();
                }
            },
            immediate: true,
        },
    },
    methods: {
        gotoResult(payment) {
            // payment.type 就是 module
            this.$router.replace(`/pay/payResult/${payment.module}/${payment.paymentSn}`);
        },
        toPay() {
            const { module, orderIds } = this;
            return this.$api.Buyer.Ex.Pay.toPay({ module, orderIds }).then(json => {
                this.orderIdsChange = false;
                this.payData = json.data.data;
                return Promise.resolve(json);
            });
        },
        action(method, data) {
            this[method](data).then(payment => {
                if (!payment) return;
                console.log(payment);
                this.gotoResult(payment);
            });
        },
        // 扫码支付
        payByScanCode(method, tipsName = '微信') {
            const { module, orderIds } = this;
            return new Promise((resolve, reject) => {
                this.$api.Buyer.Ex.Pay[method]({ module, orderIds }).then(json => {
                    const payment = json.data.data;
                    this.creatQRCode(payment.QRCodeUrl).then(url => {
                        this.$messageBox
                            .confirm(
                                `<div class="ta-c">
                                    <div>请用${tipsName}扫一扫支付订单<br>支付成功后可点击下方【已支付】<br>按钮完成本次支付</div>
                                </div>
                                <img src="${url}" width="100%"/>
                                <div class="ta-c">订单金额：<span class="price">${payment.paymentAmount}</span></div>
                                `,
                                `${tipsName}支付`,
                                {
                                    isClickOtherClose: false,
                                    confirmBtnText: '已支付',
                                    validCallback: this.validPayByScanCode(payment),
                                },
                            )
                            .then(() => resolve(payment.payment))
                            .catch(reject);
                    });
                });
            });
        },
        // 扫码支付校验
        validPayByScanCode(payment) {
            const { module } = this;
            return action => {
                return new Promise((resolve, reject) => {
                    if (action !== 'confirm') return resolve(true);
                    this.$api.Buyer.Ex.Pay.isPay({
                        module,
                        paymentId: payment.paymentId,
                    }).then(json => {
                        const res = json.data;
                        res.success ? resolve(true) : reject({ msg: `<div class="tc-red">${res.msg}</div>` });
                    });
                });
            };
        },
        // 创建二维码
        creatQRCode(text) {
            return new Promise((resolve, reject) => {
                QRCode.toDataURL(
                    text || 'QRCodeUrl 为空',
                    {
                        margin: 2,
                        scale: 30,
                        errorCorrectionLevel: 'H',
                    },
                    (error, url) => (error ? reject(error) : resolve(url)),
                );
            });
        },
        // 微信支付
        chooseWXPay(method) {
            const { module, orderIds } = this;
            return new Promise((resolve, reject) => {
                this.$api.Buyer.Ex.Pay[method]({ module, orderIds })
                    .then(json => {
                        const payment = json.data.data;
                        this.$wx
                            .chooseWXPay(payment.payInfo)
                            .then(() => resolve(payment.payment))
                            .catch(reject);
                    })
                    .catch(reject);
            });
        },
        // 外链支付
        gotoHref(href) {
            /*return this.done().then(() => {
            });*/
            return new Promise(resolve => {
                window.location.href = href;
                resolve();
            });
        },
        // 去收银台
        async gotoCheckoutCounter(data) {
            this.currentAction = data;
            const { module, orderIds } = this;
            const { paymentType } = data;
            const res = await this.$api.Buyer.Ex.Pay.getOrderPayInfo({
                module,
                orderIds,
                paymentType,
            });
            this.payInfo = res.data.data;
            this.done().then(() => {
                this.gotoType('checkoutCounter');
            });
            /*return this.done().then(() => {
                this.$router.push(`/pay/checkoutCounter/${paymentType}/${action}/${this.orderIds}/${this.module}`);
            });*/
        },
        /**-- 支付方式 --**/
        // 原生微信支付
        weixinPay(data) {
            if (this.popupShow !== 'checkoutCounter') {
                return this.gotoCheckoutCounter(data);
            } else if (this.device.isWeiXin) {
                return this.chooseWXPay('payforWeixin');
            } else {
                return this.payByScanCode('payforWeixinByScanCode');
            }
        },
        // allinPay 通关支付
        allinPay(data) {
            if (this.popupShow !== 'checkoutCounter') {
                return this.gotoCheckoutCounter(data);
            } else if (this.device.isWeiXin) {
                return this.chooseWXPay('payforAllinPayWeixin');
            } else {
                return this.payByScanCode('payforAllinPayWeixinByScanCode');
            }
        },
        // alphaPay
        alphaPayWeixin(/*data*/) {
            if (this.device.isWeiXin) {
                return this.gotoHref(
                    `/rpc/buyer/ex/pay/payforAlphaPayWeixin?orderIds=${this.orderIds}&module=${this.module}`,
                );
            } else {
                return this.payByScanCode('payforAlphaPayWeixinByScanCode');
            }
        },
        alphaPayAlipay(/*data*/) {
            if (this.device.isMobile) {
                return this.gotoHref(
                    `/rpc/buyer/ex/pay/payforAlphaPayAlipay?orderIds=${this.orderIds}&module=${this.module}`,
                );
            } else {
                return this.payByScanCode('payforAlphaPayAlipayByScanCode', '支付宝');
            }
        },
        // hantePay
        hantePayWeixin(data) {
            if (this.popupShow !== 'checkoutCounter') {
                return this.gotoCheckoutCounter(data);
            } else if (this.device.isWeiXin) {
                return this.gotoHref(
                    `/rpc/buyer/ex/pay/payforHantePayWeixin?orderIds=${this.orderIds}&module=${this.module}`,
                );
            } else {
                return this.payByScanCode('payforHantePayWeixinByScanCode');
            }
        },
        hantePayAlipay(data) {
            if (this.popupShow !== 'checkoutCounter') {
                return this.gotoCheckoutCounter(data);
            } else if (this.device.isMobile) {
                return this.gotoHref(
                    `/rpc/buyer/ex/pay/payforHantePayAlipay?orderIds=${this.orderIds}&module=${this.module}`,
                );
            } else {
                return this.gotoHref(
                    `/rpc/buyer/ex/pay/payforHantePayAlipayByScanCode?orderIds=${this.orderIds}&module=${this.module}`,
                );
                //return this.payByScanCode('payforHantePayAlipayByScanCode', '支付宝');
            }
        },
        // citconPay
        citconPayWeixin(data) {
            if (this.popupShow !== 'checkoutCounter') {
                return this.gotoCheckoutCounter(data);
            } else if (this.device.isWeiXin) {
                return this.gotoHref(
                    `/rpc/buyer/ex/pay/payforCitconPayWeixin?orderIds=${this.orderIds}&module=${this.module}`,
                );
            } else {
                return this.payByScanCode('payforCitconPayWeixinByScanCode');
            }
        },
        citconPayAlipay(data) {
            if (this.popupShow !== 'checkoutCounter') {
                return this.gotoCheckoutCounter(data);
            } else if (this.device.isMobile) {
                return this.gotoHref(
                    `/rpc/buyer/ex/pay/payforCitconPayAlipay?orderIds=${this.orderIds}&module=${this.module}`,
                );
            } else {
                return this.payByScanCode('payforCitconPayAlipayByScanCode', '支付宝');
            }
        },
        // 中投科信 umb
        umbPayWeixin(data) {
            if (this.popupShow !== 'checkoutCounter') {
                return this.gotoCheckoutCounter(data);
            } else if (this.device.isWeiXin) {
                return this.chooseWXPay('payforUmbPayWeixin');
            } else {
                return this.payByScanCode('payforUmbPayWeixinByScanCode');
            }
        },
        umbPayAlipay(data) {
            if (this.popupShow !== 'checkoutCounter') {
                return this.gotoCheckoutCounter(data);
            } else if (this.device.isMobile) {
                return this.gotoHref(
                    `/rpc/buyer/ex/pay/payforUmbPayAlipay?orderIds=${this.orderIds}&module=${this.module}`,
                );
            } else {
                return this.payByScanCode('payforUmbPayAlipayByScanCode', '支付宝');
            }
        },
        // supayTech
        supayTechPayWeixin(data) {
            if (this.popupShow !== 'checkoutCounter') {
                return this.gotoCheckoutCounter(data);
            } else if (this.device.isWeiXin) {
                return this.gotoHref(
                    `/rpc/buyer/ex/pay/payforSupayTechPayWeixin?orderIds=${this.orderIds}&module=${this.module}`,
                );
            } else {
                return this.payByScanCode('payforSupayTechPayWeixinByScanCode');
            }
        },
        supayTechPayAlipay(data) {
            if (this.popupShow !== 'checkoutCounter') {
                return this.gotoCheckoutCounter(data);
            } else {
                return this.gotoHref(
                    `/rpc/buyer/ex/pay/payforSupayTechPayAlipay?orderIds=${this.orderIds}&module=${this.module}`,
                );
            }
        },
        // alipay
        alipay(data) {
            if (this.popupShow !== 'checkoutCounter') {
                return this.gotoCheckoutCounter(data);
            } else {
                return this.gotoHref(`/rpc/buyer/ex/pay/payforAlipay?orderIds=${this.orderIds}&module=${this.module}`);
            }
        },
        // stripe
        stripePay() {
            return new Promise((resolve /*, reject*/) => {
                if (!this.stripeCardInfoList.length) {
                    this.$router.push(`/pay/stripe/${this.module}/${this.orderIds}`);
                } else {
                    this.gotoType('credit');
                }
                resolve();
            });
        },
        // 零钱支付
        pocketMoneyPay() {
            // 余额不足返回
            return new Promise((resolve, reject) => {
                if (!this.payData.isPurseBalance) {
                    this.$router.push('/pocketMoney/prepaid');
                    return reject();
                }
                this.$api.Buyer.Ex.Pay.payforBalance({
                    module: this.module,
                    orderIds: this.orderIds,
                }).then(json => {
                    this.done().then(() => resolve(json.data.data.payment));
                });
            });
        },
        offlinePay() {
            return new Promise(resolve => {
                this.$router.push(
                    `/order/offlinePay/${this.module}/${this.orderIds}?paymentAmount=${this.payData.paymentAmount}`,
                );
                resolve();
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.visa-icon {
    width: 0.8rem;
    padding: $padding 0;

    > img {
        width: 100%;
        height: auto;
    }
}
</style>
<style lang="scss">
$color-border: mix($color-border, #000, 80%);
.checkout-counter {
    .logo-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: $padding;
        margin-top: $margin;
        > .log {
            width: 2rem;
            border-radius: 100vw;
        }
        > .label {
            margin-top: $margin-small;
            font-size: 160%;
            color: $color-text-minor;
            line-height: 1.2;
        }
        > .desc {
            line-height: 1.2;
            color: $color-text-minor;
        }
    }
    .checkout-counter-list {
        padding: $padding $padding-big;
        //margin-top: $margin-small;
        > .item {
            padding: $padding;
            border-bottom: 1px solid $color-border;

            &:first-child {
                border-top: 1px solid $color-border;
            }

            + .item {
            }
            > .label {
                color: $color-text-minor;
            }
            > .content {
                font-size: 120%;
            }
        }
    }
    > .ctrl {
        padding: $padding-big;
    }
}
</style>
