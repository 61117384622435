<!-- Created by henian.xu on 2018/12/27. -->

<template>
    <Linker
        class="distributor-card"
        :to="`/distributor/cardDetail/${data.id}`"
    >
        <div class="inner">
            <div class="header">
                <div class="label">
                    {{ data.levelName }}
                </div>
            </div>
            <div class="img-obj">
                <div
                    class="inner"
                    :style="`background-image: url('${data.levelCardUrl || $defaultImg}');`"
                />
            </div>
            <div class="footer">
                <div class="price">
                    {{ data.agentFee | price }}
                </div>
                <div class="btn btn-main fs-small radius">
                    立即入驻
                </div>
            </div>
        </div>
    </Linker>
</template>

<script>
import pageMixin from '@/mixin/layout/page';
export default {
    name: 'DistributorCard',
    mixins: [pageMixin],
    data() {
        return {};
    },
    props: {
        data: {
            type: Object,
            default() {
                return {};
            },
            required: true,
        },
    },
};
</script>

<style lang="scss">
.distributor-card {
    flex: 0 0 auto;
    padding: 0 $padding;
    margin: $margin 0;
    display: block;
    > .inner {
        position: relative;
        background-color: #fff;
        border-radius: 0.08rem;
        box-shadow: 0 2px 10px 2px rgba(60, 60, 60, 0.2);
        overflow: hidden;
        > .header {
            position: absolute;
            top: 0;
            /*right: 0;*/
            left: 0;
            padding: $padding-small $padding * 2 $padding-small $padding;
            font-weight: bold;
            /*background-color: rgba(0, 0, 0, 0.4);*/
            background-color: $color-main;
            border-bottom-right-radius: 10em;
            color: #fff;
            overflow: hidden;
        }
        > .img-obj {
            width: 100%;
            > .inner {
                width: 100%;
                height: 0;
                padding-top: 45%;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
        > .footer {
            padding: $padding;
            background-color: $gray1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }
}
</style>
