<!-- Created by henian.xu on 2018/8/8. -->

<template>
    <div 
        class="page-footer main gray"
    >
        <StoreCart
            class="btn self-flex"
            ref="storeCart"
        >
            <div class="inner">
                <i 
                    class="f-icon fs-super"
                    :class="totalQuantity&&'tc-main'"
                >&#xf004;</i>
                <div 
                    class="mark" 
                    v-if="totalQuantity"
                >
                    {{ totalQuantity }}
                </div>
            </div>
        </StoreCart>
        <div class="desc">
            <div 
                class="price fs-big fw-bold"
                v-if="totalAmount"
            >
                {{ totalAmount | price }}
            </div>
            <div 
                class="tc-g6"
                v-else
            >
                未选购商品
            </div>
            <div class="tc-g6 fs-small">
                {{ distribution }}
            </div>
        </div>
        <div 
            class="btn self-flex tc-w"
            :class="totalQuantity?'btn-main':'btn-g6'"
            @click="onSubmit"
        >
            　　提　交　　
        </div>
    </div>
</template>

<script>
import StoreCart from './_storeCart';
import storeMixin from './storeMixin';

export default {
    name: 'StoreFooter',
    mixins: [storeMixin],
    components: { StoreCart },
    data() {
        return {};
    },
    computed: {
        distribution() {
            const { storeInfo, totalAmount, $globalVar } = this;
            const { startDeliverAmount, freeDeliverAmount } = storeInfo || {};
            const diffAmount = startDeliverAmount - totalAmount;
            const diffFree = freeDeliverAmount - totalAmount;
            if (!totalAmount) {
                return `${$globalVar.countryData.currency}${startDeliverAmount} 起送`;
            } else if (diffAmount > 0) {
                return `还差 ${$globalVar.countryData.currency}${diffAmount} 起送`;
            } else if (diffFree > 0) {
                return `还差 ${$globalVar.countryData.currency}${diffFree} 免费配送`;
            }
            return '免费配送';
        },
    },
    methods: {
        onSubmit() {
            const { totalQuantity, carts, $messageBox } = this;
            if (!totalQuantity) {
                $messageBox.tips('请先选购商品');
                return;
            }

            const data = carts.map(item => {
                return {
                    skuId: item.id,
                    quantity: item.quantity,
                };
            });
            console.log(JSON.stringify(data));
            this.$api.Buyer.Sp.ShopStoreOrder.cartConfirm({
                storeId: this.storeId,
                cartJson: JSON.stringify(data),
            }).then(json => {
                const res = json.data.data;
                console.log(res);
                // this.clearCarts();
                const $$storeCart = this.$refs['storeCart'];
                if ($$storeCart.popupShow) {
                    $$storeCart.done().then(() => {
                        this.$router.push(`/store/${this.storeId}/new/${res.uuid}`);
                    });
                } else {
                    this.$router.push(`/store/${this.storeId}/new/${res.uuid}`);
                }
            });
        },
    },
};
</script>

<style lang="scss">
</style>
