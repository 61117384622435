/**
 * Created by henian.xu on 2018/2/5.
 * 地址管理接口
 */

import { Rs } from '@/api/comm/Rs/Rs';

class Dic extends Rs {
    constructor() {
        super();
        this.baseUrl += '/dic';
    }

    /**
     * 获取字典值
     * @param dicName
     * @returns {AxiosPromise<any>}
     */
    dicEntry(dicName) {
        return super.get(`/dicEntry/${dicName}`);
    }
}

export default new Dic();
export { Dic };
