/**
 * Created by henian.xu on 2018/4/19.
 *
 */

import { Mb } from '@/api/buyer/Mb/Mb';

class VisitGoods extends Mb {
    constructor() {
        super();
        this.baseUrl += '/visitGoods';
    }

    /**
     * 足迹列表
     * @param visitDateBegin
     * @param visitDateEnd
     * @param currentPage
     * @param pageSize
     * @returns {AxiosPromise}
     */
    visitGoodsList({ visitDateBegin, visitDateEnd, currentPage, pageSize } = {}) {
        return super.get(`/visitGoodsList`, {
            visitDateBegin,
            visitDateEnd,
            currentPage,
            pageSize,
        });
    }

    /**
     * 删除足迹
     * @param ids
     * @returns {AxiosPromise}
     */
    delVisitGoods({ ids } = {}) {
        return super.post(`/delVisitGoods`, {
            ids,
        });
    }
}

export default new VisitGoods();
export { VisitGoods };
