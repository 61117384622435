/**
 * Created by henian.xu on 2018/2/5.
 * 地址管理接口
 */

import { Rs } from '@/api/comm/Rs/Rs';

class Region extends Rs {
    constructor() {
        super();
        this.baseUrl += '/region';
    }

    /**
     * 获取地址列表
     * @param pId
     * @param lv
     * @param maxLv
     * @param queryData {isRelatedShopStore}
     * @returns {AxiosPromise}
     */
    getChild({ pId, lv, maxLv, queryData } = {}) {
        return super.get(`/getChild`, {
            ...queryData,
            pId,
            lv,
            maxLv,
            _isRepeat: true,
        });
    }

    /**
     * 地址回选
     * @param id
     * @returns {AxiosPromise}
     */
    loadAllArea({ pId, id, queryData } = {}) {
        return super.get(`/loadAllArea`, {
            ...queryData,
            pId,
            id,
            _isRepeat: true,
        });
    }
}

export default new Region();
export { Region };
