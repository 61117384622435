<!-- Created by henian.xu on 2019/3/4. -->

<template>
    <Selector
        class="dic-selector"
        v-bind="__props"
        :multiple="multiple"
        :bitwise="bitwise"
        :data="data"
        v-model="model"
    />
</template>

<script>
import { Comm } from '@/api';

export default {
    name: 'DicSelector',
    data() {
        return {
            data: [],
        };
    },
    props: {
        value: {
            type: [Number, String, Array],
            default: 0,
            required: true,
        },
        label: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: '',
            required: true,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        bitwise: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '请选择',
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        __props() {
            const obj = { ...this.$props };
            delete obj.type;
            return obj;
        },
    },
    created() {
        Comm.Rs.Dic.dicEntry(this.type).then(json => {
            this.data = json.data.data || [];
        });
    },
};
</script>

<style lang="scss">
.dic-selector {
}
</style>
